import {
  ReviewIndicators,
  ShipNode,
  SmsDistributionCenter,
  SmsReturnPolicy,
  SmsSeller,
  SellerStatus,
} from 'types/Seller'
import SellerUser, { Role } from 'types/SellerUser'

export const SELLERS_IS_PENDING: string = 'SELLERS_IS_PENDING'
export const SELLERS_IS_PENDING_DONE: string = 'SELLERS_IS_PENDING_DONE'

export const FETCH_ALL_SELLERS: string = 'FETCH_ALL_SELLERS'
export const FETCH_ALL_SELLERS_DONE: string = 'FETCH_ALL_SELLERS_DONE'
export const FETCH_SELLER_CONTACTS: string = 'FETCH_SELLER_CONTACTS'
export const FETCH_SELLER_CONTACTS_DONE: string = 'FETCH_SELLER_CONTACTS_DONE'

export const SET_CURRENT_SELLER: string = 'SET_CURRENT_SELLER'
export const SET_CURRENT_SELLER_DONE: string = 'SET_CURRENT_SELLER_DONE'

export const CREATE_SELLER: string = 'CREATE_SELLER'
export const CREATE_SELLER_AND_ADMIN_CONTACT: string =
  'CREATE_SELLER_AND_ADMIN_CONTACT'

export const CREATE_SELLER_CONTACT: string = 'CREATE_SELLER_CONTACT'
export const CREATE_SELLER_CONTACT_DONE: string = 'CREATE_SELLER_CONTACT_DONE'

export const EDIT_SELLER: string = 'EDIT_SELLER'
export const EDIT_SELLER_DONE: string = 'EDIT_SELLER_DONE'
export const EDIT_REVIEW_INDICATOR: string = 'EDIT_REVIEW_INDICATOR'
export const EDIT_SELLER_STATUS: string = 'EDIT_SELLER_STATUS'
export const EDIT_SELLER_STATUS_DONE: string = 'EDIT_SELLER_STATUS_DONE'
export const EDIT_SELLER_CONTACT: string = 'EDIT_SELLER_CONTACT'
export const EDIT_SELLER_CONTACT_DONE: string = 'EDIT_SELLER_CONTACT_DONE'
export const CLEAR_SELLER_CONTACTS: string = 'CLEAR_SELLER_CONTACTS'
export const EDIT_SELLER_DISTRIBUTION_CENTER: string =
  'EDIT_SELLER_DISTRIBUTION_CENTER'
export const CREATE_SELLER_DISTRIBUTION_CENTER_AND_SHIP_NODE: string =
  'CREATE_SELLER_DISTRIBUTION_CENTER_AND_SHIP_NODE'
export const EDIT_SELLER_SHIP_NODE: string = 'EDIT_SELLER_SHIP_NODE'
export const EDIT_RETURN_POLICY: string = 'EDIT_RETURN_POLICY'
export const EDIT_INTERNAL_TAX_SETUP: string = 'EDIT_INTERNAL_TAX_SETUP'

export const DELETE_CONTACT_ENTITLEMENT: string = 'DELETE_CONTACT_ENTITLEMENT'
export const DELETE_CONTACT_ENTITLEMENT_DONE: string =
  'DELETE_CONTACT_ENTITLEMENT_DONE'
export const SELLER_LOGIN: string = 'SELLER_LOGIN'

export interface SellersIsPendingAction {
  readonly type: typeof SELLERS_IS_PENDING
}

export interface SellersIsPendingDoneAction {
  readonly type: typeof SELLERS_IS_PENDING_DONE
}

export interface FetchAllSellersAction {
  readonly type: typeof FETCH_ALL_SELLERS
}

export interface FetchAllSellersDoneAction {
  type: typeof FETCH_ALL_SELLERS_DONE
  sellers: SmsSeller[]
}

export interface SetCurrentSellerAction {
  readonly type: typeof SET_CURRENT_SELLER
  payload: string | null
}

export interface SetCurrentSellerDoneAction {
  readonly type: typeof SET_CURRENT_SELLER_DONE
  payload: SmsSeller | undefined
}

export interface EditSellerAction {
  readonly type: typeof EDIT_SELLER
  seller: SmsSeller
}

export interface EditReviewIndicatorAction {
  readonly type: typeof EDIT_REVIEW_INDICATOR
  payload: {
    seller: SmsSeller
    indicator: ReviewIndicators
    reviewed: boolean
  }
}

export interface EditSellerStatusAction {
  readonly type: typeof EDIT_SELLER_STATUS
  sellerId: string
  status: string
}

export interface EditSellerStatusDoneAction {
  readonly type: typeof EDIT_SELLER_STATUS_DONE
  payload: SellerStatus
}

export interface EditInternalTaxSetupAction {
  readonly type: typeof EDIT_INTERNAL_TAX_SETUP
  setupComplete: boolean
}

export interface FetchSellerContactsAction {
  readonly type: typeof FETCH_SELLER_CONTACTS
  sellerId: string
}

export interface FetchSellerContactsDoneAction {
  readonly type: typeof FETCH_SELLER_CONTACTS_DONE
  payload: SellerUser[]
}

export interface CreateSellerContactAction {
  readonly type: typeof CREATE_SELLER_CONTACT
  payload: {
    sellerId: string
    contact: SellerUser
    role: Role
    responsibility: number[] | undefined
    primaryRole?: string
    selectedChannelPartner?: string
    hasUpdatedUser?: boolean
  }
}

export interface CreateSellerContactDoneAction {
  readonly type: typeof CREATE_SELLER_CONTACT_DONE
}

export interface EditSellerContactAction {
  readonly type: typeof EDIT_SELLER_CONTACT
  payload: {
    contact: SellerUser | undefined
    userId: string
    role: Role | undefined
    responsibility: number[] | undefined
    primaryRole?: string
    selectedChannelPartner?: string
  }
}

export interface EditSellerContactDoneAction {
  readonly type: typeof EDIT_SELLER_CONTACT_DONE
  payload: {
    contacts: SellerUser[]
  }
}

export interface ClearSellerContactsAction {
  readonly type: typeof CLEAR_SELLER_CONTACTS
}

export interface DeleteContactEntitlementAction {
  readonly type: typeof DELETE_CONTACT_ENTITLEMENT
  payload: {
    contact: SellerUser
  }
}

export interface DeleteContactEntitlementDoneAction {
  readonly type: typeof DELETE_CONTACT_ENTITLEMENT_DONE
}

export interface CreateSellerDistributionCenterAndShipNodeAction {
  readonly type: typeof CREATE_SELLER_DISTRIBUTION_CENTER_AND_SHIP_NODE
  distributionCenter: Partial<SmsDistributionCenter>
  shipNode: ShipNode
}

export interface EditSellerDistributionCenterAction {
  readonly type: typeof EDIT_SELLER_DISTRIBUTION_CENTER
  distributionCenter: Partial<SmsDistributionCenter>
}

export interface EditSellerShipNodeAction {
  readonly type: typeof EDIT_SELLER_SHIP_NODE
  distributionCenterId: string
  shipNode: ShipNode
}

export interface EditReturnPolicyAction {
  readonly type: typeof EDIT_RETURN_POLICY
  returnPolicy: SmsReturnPolicy
}

export interface CreateSellerAction {
  readonly type: typeof CREATE_SELLER
  seller: Partial<SmsSeller>
}

export interface CreateSellerAndAdminContactAction {
  readonly type: typeof CREATE_SELLER_AND_ADMIN_CONTACT
  payload: {
    seller: Partial<SmsSeller>
    contact: SellerUser
  }
}

// seller functions

export function fetchAllSellers(): FetchAllSellersAction {
  return { type: FETCH_ALL_SELLERS }
}

export function fetchAllSellersDone(
  sellers: SmsSeller[],
): FetchAllSellersDoneAction {
  return { type: FETCH_ALL_SELLERS_DONE, sellers }
}

export function sellersIsPending(): SellersIsPendingAction {
  return {
    type: SELLERS_IS_PENDING,
  }
}

export function sellersIsPendingDone(): SellersIsPendingDoneAction {
  return {
    type: SELLERS_IS_PENDING_DONE,
  }
}

export function setCurrentSeller(
  sellerId: string | null,
): SetCurrentSellerAction {
  return {
    type: SET_CURRENT_SELLER,
    payload: sellerId,
  }
}

export function setCurrentSellerDone(
  seller: SmsSeller | undefined,
): SetCurrentSellerDoneAction {
  return {
    type: SET_CURRENT_SELLER_DONE,
    payload: seller,
  }
}

export function editSeller(seller: SmsSeller): EditSellerAction {
  return {
    type: EDIT_SELLER,
    seller,
  }
}

export function editSellerStatus(
  sellerId: string,
  status: string,
): EditSellerStatusAction {
  return {
    type: EDIT_SELLER_STATUS,
    sellerId,
    status,
  }
}

export function editSellerStatusDone(
  payload: SellerStatus,
): EditSellerStatusDoneAction {
  return {
    type: EDIT_SELLER_STATUS_DONE,
    payload,
  }
}

export function editReviewIndicator(
  seller: SmsSeller,
  indicator: ReviewIndicators,
  reviewed: boolean,
): EditReviewIndicatorAction {
  return {
    type: EDIT_REVIEW_INDICATOR,
    payload: {
      seller,
      indicator,
      reviewed,
    },
  }
}

export function fetchSellerContacts(
  sellerId: string,
): FetchSellerContactsAction {
  return {
    type: FETCH_SELLER_CONTACTS,
    sellerId,
  }
}

export function fetchSellerContactsDone(
  contacts: SellerUser[],
): FetchSellerContactsDoneAction {
  return {
    type: FETCH_SELLER_CONTACTS_DONE,
    payload: contacts,
  }
}

export function createSellerContact({
  sellerId,
  contact,
  role,
  responsibility,
  primaryRole,
  selectedChannelPartner,
  hasUpdatedUser,
}: {
  sellerId: string
  contact: SellerUser
  role: Role
  responsibility: number[] | undefined
  primaryRole?: string
  selectedChannelPartner?: string
  hasUpdatedUser?: boolean
}): CreateSellerContactAction {
  return {
    type: CREATE_SELLER_CONTACT,
    payload: {
      sellerId,
      contact,
      role,
      responsibility,
      primaryRole,
      selectedChannelPartner,
      hasUpdatedUser,
    },
  }
}

export function createSellerContactDone(): CreateSellerContactDoneAction {
  return {
    type: CREATE_SELLER_CONTACT_DONE,
  }
}

export function editSellerContact({
  contact,
  userId,
  role,
  responsibility,
  primaryRole,
  selectedChannelPartner,
}: {
  contact: SellerUser | undefined
  userId: string
  role: Role | undefined
  responsibility: number[] | undefined
  primaryRole?: string
  selectedChannelPartner?: string
}): EditSellerContactAction {
  return {
    type: EDIT_SELLER_CONTACT,
    payload: {
      contact,
      userId,
      role,
      responsibility,
      primaryRole,
      selectedChannelPartner,
    },
  }
}

export function editSellerContactDone(
  contacts: SellerUser[],
): EditSellerContactDoneAction {
  return {
    type: EDIT_SELLER_CONTACT_DONE,
    payload: {
      contacts,
    },
  }
}

export function clearSellerContacts(): ClearSellerContactsAction {
  return { type: CLEAR_SELLER_CONTACTS }
}

export function deleteContactEntitlement(
  contact: SellerUser,
): DeleteContactEntitlementAction {
  return {
    type: DELETE_CONTACT_ENTITLEMENT,
    payload: {
      contact,
    },
  }
}

export function deleteContactEntitlementDone(): DeleteContactEntitlementDoneAction {
  return {
    type: DELETE_CONTACT_ENTITLEMENT_DONE,
  }
}

export function createSellerDistributionCenterAndShipNode(
  distributionCenter: Partial<SmsDistributionCenter>,
  shipNode: ShipNode,
): CreateSellerDistributionCenterAndShipNodeAction {
  return {
    type: CREATE_SELLER_DISTRIBUTION_CENTER_AND_SHIP_NODE,
    distributionCenter,
    shipNode,
  }
}

export function editSellerDistributionCenter(
  distributionCenter: Partial<SmsDistributionCenter>,
): EditSellerDistributionCenterAction {
  return {
    type: EDIT_SELLER_DISTRIBUTION_CENTER,
    distributionCenter,
  }
}

export function editSellerShipNode(
  distributionCenterId: string,
  shipNode: ShipNode,
) {
  return {
    type: EDIT_SELLER_SHIP_NODE,
    distributionCenterId,
    shipNode,
  }
}

export function editReturnPolicy(returnPolicy: SmsReturnPolicy) {
  return {
    type: EDIT_RETURN_POLICY,
    returnPolicy,
  }
}

export function createSeller(seller: Partial<SmsSeller>): CreateSellerAction {
  return {
    type: CREATE_SELLER,
    seller,
  }
}

export function createSellerAndAdminContact(
  seller: Partial<SmsSeller>,
  contact: SellerUser,
): CreateSellerAndAdminContactAction {
  return {
    type: CREATE_SELLER_AND_ADMIN_CONTACT,
    payload: { seller, contact },
  }
}

export function editInternalTaxSetup(setupComplete: boolean) {
  return {
    type: EDIT_INTERNAL_TAX_SETUP,
    setupComplete,
  }
}

export type SellerActions =
  | SellersIsPendingAction
  | SellersIsPendingDoneAction
  | SetCurrentSellerAction
  | SetCurrentSellerDoneAction
  | FetchAllSellersAction
  | FetchAllSellersDoneAction
  | FetchSellerContactsAction
  | FetchSellerContactsDoneAction
  | CreateSellerContactAction
  | CreateSellerContactDoneAction
  | EditSellerAction
  | EditSellerStatusAction
  | EditSellerStatusDoneAction
  | EditSellerContactAction
  | EditSellerContactDoneAction
  | ClearSellerContactsAction
  | DeleteContactEntitlementAction
  | DeleteContactEntitlementDoneAction
  | CreateSellerAction
  | CreateSellerAndAdminContactAction
  | EditInternalTaxSetupAction
