import { FC, useMemo, useEffect, useState, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import {
  Placeholder,
  Grid,
  Anchor,
  Image,
} from '@enterprise-ui/canvas-ui-react'

import { useTGIDStore } from 'v2/store'
import { ProductType, TimeRemainingSortMethod } from 'v2/store/useTGIDStore'
import { getProductEnhancements, getProductsByProductId } from 'v2/services'
import CustomPagination from 'v2/components/common/CustomPagination'
import { currentSellerId } from 'store/selectors'
import { StyledTable } from '../../styles'
import DetailsDrawer from '../../DetailsDrawer'
import TimeRemaining from '../../TimeRemaining'

const VariationTable: FC = () => {
  const sellerId = useSelector(currentSellerId)
  const [isProductLoader, setIsProductLoader] = useState<boolean>(false)
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState<boolean>(false)

  const {
    selectedRows,
    updateSelectedRows,
    updateReviewItemList,
    itemType,
    reviewItemLists,
    updateItemDetails,
    productIds,
    updateSelectedItem,
    selectedItemTypeId,
    reviewDateRange,
    timeRemainingSort,
    updateTimeRemainingSort,
    resetState,
    itemListPagination,
    updateItemListPagination,
  } = useTGIDStore()

  const {
    data: itemsData,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: [
      'PRODUCT_ENHANCEMENTS',
      sellerId,
      itemListPagination.page,
      itemType,
      selectedItemTypeId,
      reviewDateRange,
      timeRemainingSort,
    ],
    queryFn: () => {
      if (sellerId) {
        resetState({ productIds: [] })
        return getProductEnhancements(sellerId, {
          page: itemListPagination.page,
          perPage: 10,
          relationshipType: itemType === ProductType.STANDALONE ? 'SA' : 'VC',
          itemTypeId: selectedItemTypeId ?? undefined,
          reviewDateRange,
          sort: timeRemainingSort,
        })
      }
      return null
    },
    refetchOnMount: true,
  })

  useEffect(() => {
    if (productIds.length) {
      setIsProductLoader(true)
      const promises = productIds.map((productId: any) => {
        return getProductsByProductId({ productId })
      })
      Promise.all(promises)
        .then((data) => {
          const mappedData = data.map(({ results }) => {
            const {
              id,
              primary_image,
              title,
              item_type_name,
              item_type_id,
              parent_tcin,
            } = results?.[0] ?? {}

            return {
              title,
              productId: id,
              image: primary_image,
              itemTypeName: item_type_name,
              itemTypeId: item_type_id,
              parentTcin: parent_tcin,
            }
          })

          updateItemDetails(mappedData)
        })
        .finally(() => setIsProductLoader(false))
    }
  }, [productIds, updateItemDetails])

  useEffect(() => {
    if (itemsData && isFetching === false) {
      updateItemListPagination({
        ...itemListPagination,
        total: Number(itemsData?.total),
      })

      const mappedData = itemsData?.data?.map(
        ({
          tcin,
          fields,
          id,
          review_end_time,
          seller_id,
          created,
          parent_id,
        }: any) => {
          return {
            tcin,
            fields,
            reviewCreateDate: created,
            reviewEndTime: review_end_time,
            productId: id,
            sellerId: seller_id,
            parentProductId: parent_id ?? null,
          }
        },
      )

      updateReviewItemList(mappedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsData, updateReviewItemList, isFetching, updateItemListPagination])

  const handleDrawerOpen = useCallback(
    (tcin: string) => {
      updateSelectedItem(tcin)
      setIsDetailsDrawerOpen(true)
    },
    [updateSelectedItem],
  )

  const handleClose = useCallback(() => {
    refetch()
    setIsDetailsDrawerOpen((prev) => !prev)
  }, [refetch])

  const handleRowSelection = useCallback(
    (rows: string[]) => {
      updateSelectedRows(rows)
    },
    [updateSelectedRows],
  )

  const columnDefs = useMemo(() => {
    const onSort = () => {
      if (timeRemainingSort === TimeRemainingSortMethod.ASC) {
        updateTimeRemainingSort(TimeRemainingSortMethod.DSC)
      }
      if (timeRemainingSort === TimeRemainingSortMethod.DSC) {
        updateTimeRemainingSort(TimeRemainingSortMethod.ASC)
      }
    }
    return [
      {
        field: 'tcin',
        headerName: `${itemType === ProductType.VARIANT_CHILD ? 'VC' : ''} TCIN`,
        isRowHeader: true,
        align: 'center',
      },
      {
        field: 'noOfAttributes',
        headerName: 'No. of Attributes',
        align: 'center',
      },
      {
        field: 'image',
        headerName: 'Image',
        align: 'center',
        width: 130,
      },
      {
        field: 'productTitle',
        headerName: 'Product Title',
        width: 600,
      },
      {
        field: 'itemType',
        headerName: 'Item Type',
        align: 'center',
      },
      {
        field: 'timeRemaining',
        headerName: 'Time Remaining',
        width: 200,
        sortable: true,
        onSort,
        align: 'center',
      },
    ]
  }, [itemType, timeRemainingSort, updateTimeRemainingSort])

  const tableColumnPlaceholder = () => (
    <Placeholder.Rect emphasized height="45px" />
  )

  const rowData = useMemo(() => {
    if (reviewItemLists) {
      return reviewItemLists.map(
        ({ tcin, fields, reviewEndTime, title, image, itemTypeName }) => {
          return {
            tcin: {
              cellDisplay: (
                <Anchor onClick={() => handleDrawerOpen(tcin)}>{tcin}</Anchor>
              ),
            },
            id: tcin,
            noOfAttributes: fields?.length,
            image: {
              cellDisplay: isProductLoader ? (
                tableColumnPlaceholder()
              ) : (
                <Image alt="Product image" src={image} />
              ),
            },
            productTitle: {
              cellDisplay: isProductLoader ? tableColumnPlaceholder() : title,
            },
            itemType: {
              cellDisplay: isProductLoader
                ? tableColumnPlaceholder()
                : itemTypeName,
            },
            timeRemaining: {
              cellDisplay: reviewEndTime ? (
                <TimeRemaining timeStamp={reviewEndTime} />
              ) : (
                'N/A'
              ),
            },
          }
        },
      )
    }
    return []
  }, [reviewItemLists, handleDrawerOpen, isProductLoader])

  if (isFetching) {
    return (
      <StyledTable
        data={{
          columnDefs,
          rowData: Array(10).fill({
            tcin: { cellDisplay: tableColumnPlaceholder() },
            noOfAttributes: { cellDisplay: tableColumnPlaceholder() },
            image: { cellDisplay: tableColumnPlaceholder() },
            productTitle: { cellDisplay: tableColumnPlaceholder() },
            itemType: { cellDisplay: tableColumnPlaceholder() },
            timeRemaining: { cellDisplay: tableColumnPlaceholder() },
          }),
        }}
      ></StyledTable>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <>
      {reviewItemLists?.length ? (
        <>
          <StyledTable
            data={{
              columnDefs,
              rowData,
            }}
            onRowSelect={handleRowSelection}
            rowSelection="multiple"
            selectedRows={selectedRows}
            roundCorner
          />
          <Grid.Container className="hc-pt-normal" justify="flex-end">
            <Grid.Item>
              <CustomPagination
                showDescription
                total={itemListPagination.total}
                perPage={10}
                currentPage={itemListPagination.page}
                onPageChange={(newPageNumber) =>
                  updateItemListPagination({
                    ...itemListPagination,
                    page: newPageNumber,
                  })
                }
              />
            </Grid.Item>
          </Grid.Container>
        </>
      ) : (
        <div className="hc-pa-expanded hc-ta-center">
          <p>No items are available for review</p>
        </div>
      )}

      {isDetailsDrawerOpen && (
        <DetailsDrawer
          isDetailsDrawerOpen={isDetailsDrawerOpen}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default VariationTable
