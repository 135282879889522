import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { currentSellerId } from 'store/selectors'
import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { getUnshippedOrdersAge } from 'v2/services/getUnshippedOrdersAge'
import { useOrdersDashboardStore } from 'v2/store'
import {
  canvasBackgroundColorInteractiveActive,
  canvasBackgroundColorInteractiveContrastWeakActive,
  canvasFontColorInteractive,
} from 'v2/styles/colors'
import { getCurrentDateWithStartAndEndTime } from 'v2/utils/date'
import { isArrayEmpty } from 'v2/utils/helper'

const RADIAN = Math.PI / 180

const UnshippedPastDueGraphByAge = () => {
  const sellerId = useSelector(currentSellerId)
  const { view, selectedSellerId } = useOrdersDashboardStore()

  const getData = async () => {
    const response = await getUnshippedOrdersAge({
      params: {
        date: getCurrentDateWithStartAndEndTime(),
        sellerId: selectedSellerId,
      },
      sellerId: view === 'INTERNAL' ? null : sellerId,
    })
    const data = [
      ...(response?.uptoOneDay > 0
        ? [
            {
              name: 'upto 1 Day',
              value: response?.uptoOneDay,
              color: canvasBackgroundColorInteractiveContrastWeakActive,
            },
          ]
        : []),
      ...(response?.moreThanTwoDays > 0
        ? [
            {
              name: '2 to 7 Days',
              value: response?.moreThanTwoDays,
              color: canvasFontColorInteractive,
            },
          ]
        : []),
      ...(response?.ordersAtRisk > 0
        ? [
            {
              name: '8 Days or More',
              value: response?.ordersAtRisk,
              color: canvasBackgroundColorInteractiveActive,
            },
          ]
        : []),
    ]
    return data
  }

  const { data, isFetching, isError } = useQuery({
    queryKey: ['UNSHIPPED_PAST_DUE_BY_AGE_GRAPH', selectedSellerId],
    queryFn: getData,
  })

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + 100
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    const count = data?.[index]?.value ?? 0

    return (
      <text
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        <tspan x={x} dy="0" fontSize={14}>
          {data?.[index].name}
        </tspan>
        <tspan
          x={x}
          dy="1em"
          fontSize={20}
          fill="var(--canvas-background-color--interactive)"
        >
          {`${count.toLocaleString()} order${count > 1 ? 's' : ''}`}
        </tspan>
      </text>
    )
  }

  if (isFetching) {
    return (
      <Placeholder className="hc-pv-xl">
        <Grid.Container justify="center" align="center">
          <Grid.Item xs={12}>
            <Placeholder.Rect emphasized height="270px" />
          </Grid.Item>
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <p className="hc-pa-expanded hc-ta-center hc-clr-error">
        There was an error fetching the data.
      </p>
    )
  }
  if (isArrayEmpty(data)) {
    return <p className="hc-ta-center hc-pa-lg">No data to display!</p>
  }

  return (
    <ResponsiveContainer width={'100%'} height={350}>
      <PieChart width={500} height={350}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={renderCustomizedLabel}
        >
          {data?.map((val, index) => (
            <Cell key={`cell-${index}`} fill={val?.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default UnshippedPastDueGraphByAge
