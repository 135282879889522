import { Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'

import PriceTrendGraph from './PriceTrendGraphs'
import PriceTrendTable from './PriceTrendTable'
import NonCompetitiveAndCompetitiveGraph from './NonCompetitiveAndCompetitiveGraph'
import Summary from './Summary'
import Filters from './PriceInsightsFilters'

const LandingPage = () => {
  return (
    <>
      <span>
        <EnterpriseIcon icon={InfoIcon} />
        <span className="hc-fs-xs hc-pl-min">
          Disclaimer: The insights available on this dashboard is purely
          directional and any change in price is solely upto your discretion.
        </span>
      </span>
      <Grid.Container className="hc-pt-2x hc-pb-xl">
        <Grid.Item xs={6}>
          <Summary />
        </Grid.Item>
        <Grid.Item xs={6}>
          <NonCompetitiveAndCompetitiveGraph />
        </Grid.Item>
      </Grid.Container>
      <Filters />
      <PriceTrendGraph />
      <PriceTrendTable />
    </>
  )
}

export default LandingPage
