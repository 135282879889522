export const datePattern = {
  minus: {
    yyyy_MM_dd: 'yyyy-MM-dd', // 2024-02-01
  },
  underscore: {
    yyyy_MM_dd_time: 'dd_MM_yyyy hh_mm a', // 01_02_2025 03_14 PM
  },
  slash: {
    MM_dd_yyyy: 'MM/dd/yyyy', // 02/14/2024
    MM_dd_yyyy_time: 'MM/dd/yyyy h:mm a', // 02/14/2024 3:14 PM
  },
  verbose: {
    MMM_do_yyyy: 'MMM do yyyy', //Aug 24th 2024
    MMMM_do_yyyy: 'MMMM do yyyy', //August 24th 2024
    MMM_do_yyyy_time: 'MMM do yyyy, h:mm a',
    MMMM_do_yyyy_time: 'MMMM do yyyy h:mm a',
    dd_MMM_YYY_time: "dd MMM, yyyy HH:mm:ss 'Hrs'", // 18 Aug, 2024 22:10:00 Hrs
  },
}
