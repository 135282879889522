import { Grid } from '@enterprise-ui/canvas-ui-react'

import HeaderTitle from 'components/common/HeaderTitle'
import { useEffect } from 'react'
import {
  OrderDetails,
  UnshippedPastDue,
  OrdersToBeShipped,
  BreakdownSummary,
  TotalShippedAndCancelledOrderCount,
  Filter,
} from 'v2/components/feature/OrdersDashboard'
import { useOrdersDashboardStore } from 'v2/store'

const OrdersDashboard = () => {
  const { view, updateView, updateSelectedSellerId } = useOrdersDashboardStore()

  useEffect(() => {
    updateSelectedSellerId('')
    updateView('EXTERNAL')
  }, [updateView, updateSelectedSellerId])

  if (view !== 'EXTERNAL') return <></>

  return (
    <>
      <HeaderTitle title="Orders" />
      <Grid.Item className="hc-mb-xl">
        <BreakdownSummary />
      </Grid.Item>
      <Grid.Container className="hc-mb-xl">
        <Grid.Item xs={6}>
          <UnshippedPastDue />
        </Grid.Item>
        <Grid.Item xs={6}>
          <OrdersToBeShipped />
        </Grid.Item>
      </Grid.Container>
      <Grid.Item xs={12} className="hc-mb-xl">
        <Filter />
      </Grid.Item>
      <TotalShippedAndCancelledOrderCount />
      <OrderDetails />
    </>
  )
}

export default OrdersDashboard
