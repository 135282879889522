import { FC, useMemo } from 'react'
import { differenceInHours } from 'date-fns'

import EnterpriseIcon, { TimerIcon } from '@enterprise-ui/icons'
import { StyledTime } from './styles'

interface TimeRemainingProps {
  timeStamp: string
  inDrawer?: boolean
}

const TimeRemaining: FC<TimeRemainingProps> = ({ timeStamp, inDrawer }) => {
  const timeRemaining = useMemo(() => {
    const currentDate = new Date()
    const timeStampInLocalTime = new Date(timeStamp)

    const hour = differenceInHours(timeStampInLocalTime, currentDate)

    return hour < 1 ? 1 : hour
  }, [timeStamp])

  return (
    <StyledTime time={timeRemaining}>
      <EnterpriseIcon className="hc-mr-dense" icon={TimerIcon} />
      {`${timeRemaining} hours ${inDrawer ? 'remaining' : ''}`}
    </StyledTime>
  )
}

export default TimeRemaining
