import { FC } from 'react'
import { Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { StyledBreakdownSummaryCard, StyledDivider } from '../styles'

export interface SummaryInterface {
  order_status: string
  count: number
  tooltip?: string
  isDisable?: boolean
}
interface MyProps {
  data?: SummaryInterface[]
}

const getOrdersCardTitle = (status: string): string => {
  const orderTitles: { [key: string]: string } = {
    SHIPPED: 'Shipped Orders',
    CANCELED: 'Cancelled Orders',
    RELEASED_FOR_SHIPMENT: 'Released Orders',
    PARTIALLY_SHIPPED: 'Partially Shipped',
    UNSHIPPED: 'Unshipped Orders',
    UNSHIPPED_PAST_DUE: 'Unshipped Past Due Date',
    FILL_RATE: 'Fill Rate',
  }
  return orderTitles[status] || ''
}

const getOrderStatusCounts = (
  count: number,
  status: string,
  isDisable: boolean = false,
): string => {
  if (isDisable) return 'N/A'
  if (status === 'FILL_RATE') return `${count}%`
  return count.toLocaleString()
}

const SummaryCards: FC<MyProps> = ({ data }) => {
  return (
    <>
      <Grid.Container align="left" justify="flex-start">
        {data?.map((val) => {
          return (
            <Grid.Item>
              <Tooltip
                content={val?.tooltip}
                location={val?.tooltip ? 'bottom' : 'none'}
              >
                <StyledBreakdownSummaryCard>
                  <p className="hc-ma-none hc-ph-normal hc-pv-dense font-color-default hc-fs-sm ">
                    {getOrdersCardTitle(val?.order_status)}
                  </p>
                  <p className="hc-ma-none hc-ph-normal font-color-default hc-fs-lg ">
                    <strong>
                      {getOrderStatusCounts(
                        val.count,
                        val.order_status,
                        val?.isDisable,
                      )}
                    </strong>
                  </p>
                  <StyledDivider className="hc-pt-min" width={8} />
                </StyledBreakdownSummaryCard>
              </Tooltip>
            </Grid.Item>
          )
        })}
      </Grid.Container>
    </>
  )
}

export default SummaryCards
