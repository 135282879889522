import axios from 'axios'

import apiConfig from 'config/apiConfig'

interface ProductVariationUpdateType {
  sellerId: string
  payload: any
}

export const postProductVariationUpdate = async ({
  sellerId,
  payload,
}: ProductVariationUpdateType) => {
  try {
    const response = await axios.post(
      `${apiConfig.sms}/sellers/${sellerId}/product_variation_update`,
      payload,
    )

    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
