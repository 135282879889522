import { create } from 'zustand'
import * as dateFns from 'date-fns'
import { OrderStatus } from 'v2/constant'
import { endOfDay, startOfDay } from 'v2/utils/date'

type View = 'INTERNAL' | 'EXTERNAL'

type TimeFrame = {
  startDate: string
  endDate: string
}

interface OrderStatusInterface {
  id: string
  label: string
  value: OrderStatus
}

enum InitialOrderStatus {
  RELEASED_FOR_SHIPMENT = 'RELEASED_FOR_SHIPMENT',
  ACKNOWLEDGED_BY_SELLER = 'ACKNOWLEDGED_BY_SELLER',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  SHIPPED = 'SHIPPED',
  CANCELED = 'CANCELED',
}
export enum OrderDetailsSortMethod {
  ORDER_ID_ASC = 'id(asc)',
  ORDER_ID_DSC = 'id(desc)',
  ORDER_DATE_ASC = 'order_date(asc)',
  ORDER_DATE_DSC = 'order_date(desc)',
  RSD_ASC = 'requested_shipment_date(asc)',
  RSD_DSC = 'requested_shipment_date(desc)',
  RDD_ASC = 'requested_delivery_date(asc)',
  RDD_DSC = 'requested_delivery_date(desc)',
  ORDER_STATUS_ASC = 'order_status(asc)',
  ORDER_STATUS_DSC = 'order_status(desc)',
}

const initialTimeFrame = { startDate: '', endDate: '' }
const initialOrderFilter: OrderStatusInterface[] = Object.keys(
  InitialOrderStatus,
).map((value) => ({
  id: OrderStatus[value as keyof typeof OrderStatus],
  label: OrderStatus[value as keyof typeof OrderStatus],
  value: OrderStatus[value as keyof typeof OrderStatus],
}))

export const convertDateToIntervalFormat = (start: Date, end: Date): string => {
  if (!dateFns.isValid(start) || !dateFns.isValid(end)) return '' // Return empty string or a fallback value
  return `${startOfDay(start, true)}/${endOfDay(end, true)}`
}

interface OrdersDashboardState {
  view: View
  sort: OrderDetailsSortMethod | null
  partnerName: string | null
  orderStatuses: OrderStatusInterface[]
  orderPlacedDate: TimeFrame | null
  deliverByDate: TimeFrame | null
  requestShipDate: TimeFrame | null
  orderPlacedDateInterval: string // We will be sending this to the API
  deliverByDateInterval: string // We will be sending this to the API
  requestShipDateInterval: string // We will be sending this to the API
  isOrdersAtRiskChecked: boolean
  ordersAtRiskCount: number
  fillRate: number | null
  selectedSellerId?: string
  totalOrderCount?: number
  updateSort: (sort: OrderDetailsSortMethod | null) => void
  updateView: (view: View) => void
  updatePartnerName: (partnerName: string) => void
  updateOrderStatuses: (orderStatus: OrderStatusInterface[]) => void
  updateOrderPlacedDate: (orderPlacedDate: TimeFrame) => void
  updateDeliverByDate: (deliverByDate: TimeFrame) => void
  updateRequestShipDate: (requestShipDate: TimeFrame) => void
  updateOrdersAtRiskCount: (ordersAtRiskCount: number) => void
  updateIsOrdersAtRiskChecked: (isOrdersAtRiskChecked: boolean) => void
  updateSelectedSellerId: (selectedSellerId: string) => void
  updateTotalOrderCount: (totalOrderCount: number) => void
  updateFillRate: (fillRate: number) => void
  clearFilters: () => void
}

const useOrdersDashboardStore = create<OrdersDashboardState>((set) => ({
  view: 'INTERNAL',
  sort: OrderDetailsSortMethod.RSD_DSC,
  partnerName: '',
  orderStatuses: initialOrderFilter,
  orderPlacedDate: null,
  deliverByDate: null,
  requestShipDate: null,
  ordersAtRiskCount: 0,
  fillRate: null,
  isOrdersAtRiskChecked: false,
  selectedSellerId: '',
  totalOrderCount: 0,
  deliverByDateInterval: '',
  orderPlacedDateInterval: '',
  requestShipDateInterval: '',
  updateSort: (sort) => set({ sort }),
  updateView: (view) => set({ view }),
  updatePartnerName: (partnerName) => set({ partnerName }),
  updateOrderStatuses: (orderStatuses) => set({ orderStatuses }),
  updateOrderPlacedDate: (orderPlacedDate) =>
    set(() => {
      return {
        orderPlacedDate,
        orderPlacedDateInterval: convertDateToIntervalFormat(
          new Date(orderPlacedDate.startDate),
          new Date(orderPlacedDate.endDate),
        ),
      }
    }),
  updateDeliverByDate: (deliverByDate) =>
    set(() => {
      return {
        deliverByDate,
        deliverByDateInterval: convertDateToIntervalFormat(
          new Date(deliverByDate.startDate),
          new Date(deliverByDate.endDate),
        ),
      }
    }),
  updateRequestShipDate: (requestShipDate) =>
    set(() => {
      return {
        requestShipDate,
        requestShipDateInterval: convertDateToIntervalFormat(
          new Date(requestShipDate.startDate),
          new Date(requestShipDate.endDate),
        ),
      }
    }),
  updateOrdersAtRiskCount(ordersAtRiskCount) {
    set({ ordersAtRiskCount })
  },
  updateIsOrdersAtRiskChecked: (isOrdersAtRiskChecked) =>
    set({ isOrdersAtRiskChecked }),
  updateSelectedSellerId: (selectedSellerId) => set({ selectedSellerId }),
  updateTotalOrderCount: (totalOrderCount) => set({ totalOrderCount }),
  updateFillRate: (fillRate) => set({ fillRate }),
  clearFilters: () => {
    set({
      orderStatuses: [],
      deliverByDate: initialTimeFrame,
      orderPlacedDate: initialTimeFrame,
      requestShipDate: initialTimeFrame,
      orderPlacedDateInterval: '',
      requestShipDateInterval: '',
      deliverByDateInterval: '',
    })
  },
}))

export default useOrdersDashboardStore
