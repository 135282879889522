import { create } from 'zustand'

import { getDateAddedByDays } from 'v2/utils/date'

export enum ProductType {
  STANDALONE = 'STANDALONE',
  VARIANT_CHILD = 'VARIANT_CHILD',
}

export enum TimeRange {
  LessThan24Hrs = 'Less than 24Hrs',
  Between24And48Hrs = '24 - 48Hrs',
  Between48And72Hrs = '48 - 72Hrs',
  All = 'All',
}

export enum TimeRemainingSortMethod {
  ASC = 'review_end_time.asc',
  DSC = 'review_end_time.dsc',
}

type ItemType = ProductType.STANDALONE | ProductType.VARIANT_CHILD

type TimeRangeType =
  | TimeRange.LessThan24Hrs
  | TimeRange.Between24And48Hrs
  | TimeRange.Between48And72Hrs
  | TimeRange.All

type TimeRemainingSortType =
  | TimeRemainingSortMethod.ASC
  | TimeRemainingSortMethod.DSC

export interface ReviewItemListsType {
  tcin: string
  fields: { name: string; value: string }[]
  reviewEndTime: string
  sellerId: string
  productId: string
  title: string
  image: string
  itemTypeName: string
  itemTypeId: number
  reviewCreateDate: string
  parentProductId: string
  parentTcin: string
}

interface ItemDetailsType {
  productId: string
  title: string
  image: string
  itemTypeName: string
  itemTypeId: number
  parentTcin: string
}

interface ItemListPaginationType {
  total: number
  page: number
}

export interface DetailsViewType {
  attribute: {
    name: any
    id: any
    type: any
    required: boolean
  }
  original: {
    name: string
    value: string
    isNotAvailable?: boolean
  }
  recommended: {
    name: string
    value: string | null
  }
}

interface TGIDStateProperties {
  itemType: ItemType
  selectedRows: string[]
  productIds: string[]
  timeRemaining: TimeRangeType
  reviewItemLists: ReviewItemListsType[]
  selectedItem: ReviewItemListsType
  itemDetails: ItemDetailsType[]
  selectedItemTypeId: string | null
  reviewDateRange: string | null
  detailsView: DetailsViewType[]
  detailsViewClone: DetailsViewType[]
  timeRemainingSort: TimeRemainingSortType
  itemListPagination: ItemListPaginationType
  selectedVCTcins: string[]
}
interface TGIDState extends TGIDStateProperties {
  updateDetailsView: (
    taxonomiesAttributesData: any,
    productAttributesData: any,
  ) => void
  resetDetailsView: () => void
  updateRecommendedValue: (value: string | null, index: number) => void
  updateItemType: (itemType: ItemType) => void
  updateSelectedRows: (selectedRows: string[]) => void
  updateTimeRemaining: (timeRemaining: TimeRangeType) => void
  updateReviewItemList: (reviewItemLists: ReviewItemListsType[]) => void
  updateItemDetails: (itemDetails: ItemDetailsType[]) => void
  updateSelectedItem: (tcin: string) => void
  updateSelectedItemTypeId: (selectedItemTypeId: string | null) => void
  updateTimeRemainingSort: (timeRemainingSort: TimeRemainingSortType) => void
  updateToNextItem: () => void
  updateItemListPagination: (itemListPagination: ItemListPaginationType) => void
  resetState: (input: Partial<TGIDStateProperties>) => void
  updateSelectedVCTcins: (selectedVCTcins: string[]) => void
}

const getRange = (timeRemaining: TimeRange) => {
  const currentDate = new Date()
  const getRangeString = (startDays: number, endDays: number) => {
    return `${getDateAddedByDays(currentDate, startDays).toISOString()}/${getDateAddedByDays(currentDate, endDays).toISOString()}`
  }
  switch (timeRemaining) {
    case TimeRange.LessThan24Hrs:
      return getRangeString(0, 1)
    case TimeRange.Between24And48Hrs:
      return getRangeString(1, 2)
    case TimeRange.Between48And72Hrs:
      return getRangeString(2, 3)
    default:
      return null
  }
}

const useTGIDStore = create<TGIDState>((set) => ({
  itemType: ProductType.STANDALONE,
  timeRemaining: TimeRange.All,
  selectedRows: [],
  reviewItemLists: [],
  productIds: [],
  itemDetails: [],
  selectedItem: {} as ReviewItemListsType,
  selectedItemTypeId: null,
  reviewDateRange: null,
  detailsView: [],
  detailsViewClone: [],
  timeRemainingSort: TimeRemainingSortMethod.ASC,
  itemListPagination: { total: 0, page: 1 },
  selectedVCTcins: [],
  updateRecommendedValue: (value: string | null, index: number) =>
    set((state) => {
      return {
        detailsView: state.detailsView.map((ele, i) => {
          if (i === index) {
            return {
              ...ele,
              recommended: {
                ...ele.recommended,
                value,
              },
            }
          }
          return ele
        }),
      }
    }),
  updateDetailsView: (taxonomiesAttributesData, productAttributesData) =>
    set((state) => {
      const detailsView = state.selectedItem.fields.map((field) => {
        const taxonomy =
          taxonomiesAttributesData.find(
            (ele: any) => ele.mapped_property === field.name,
          ) ?? {}
        const product = productAttributesData.find(
          (ele: any) => ele.name === field.name,
        ) ?? { isNotAvailable: true }

        return {
          attribute: {
            name: taxonomy.name,
            id: taxonomy.id,
            type: taxonomy.type,
            required: taxonomy.required,
          },
          original: {
            ...product,
          },
          recommended: {
            ...field,
          },
        }
      })
      return {
        detailsView,
        detailsViewClone: detailsView,
      }
    }),
  resetDetailsView: () =>
    set((state) => {
      return {
        detailsView: state.detailsViewClone,
      }
    }),
  updateItemType: (itemType: ItemType) =>
    set(() => ({ itemType, selectedRows: [] })),
  updateTimeRemaining: (timeRemaining: TimeRangeType) =>
    set(() => {
      return {
        timeRemaining,
        reviewDateRange: getRange(timeRemaining),
        productIds: [],
        selectedRows: [],
      }
    }),
  updateSelectedRows: (selectedRows: string[]) => set({ selectedRows }),
  updateSelectedItem: (tcin: string) =>
    set((state) => {
      return {
        selectedItem: state.reviewItemLists.find((item) => item.tcin === tcin),
      }
    }),
  updateReviewItemList: (reviewItemLists: ReviewItemListsType[]) =>
    set(() => {
      return {
        reviewItemLists,
        productIds: reviewItemLists.map((item) => item.productId),
      }
    }),
  updateItemDetails: (itemDetails: ItemDetailsType[]) =>
    set((state) => {
      return {
        itemDetails,
        reviewItemLists: state.reviewItemLists.map((item) => {
          const itemDetail = itemDetails.find(
            (detail) => detail.productId === item.productId,
          )
          return {
            ...itemDetail,
            ...item,
          }
        }),
      }
    }),
  updateSelectedItemTypeId: (selectedItemTypeId: string | null) =>
    set({ selectedItemTypeId, productIds: [] }),
  updateTimeRemainingSort: (timeRemainingSort: TimeRemainingSortType) =>
    set({ timeRemainingSort }),
  updateToNextItem: () =>
    set((state) => {
      const currentIndex = state.reviewItemLists?.findIndex(
        (reviewItem: ReviewItemListsType) =>
          reviewItem?.tcin === state.selectedItem?.tcin,
      )
      return {
        selectedItem: state.reviewItemLists[currentIndex + 1],
      }
    }),
  updateItemListPagination: (itemListPagination: ItemListPaginationType) =>
    set({ itemListPagination }),
  resetState: (input: Partial<TGIDStateProperties>) =>
    set((state) => ({
      ...state,
      ...input,
    })),
  updateSelectedVCTcins: (selectedVCTcins: string[]) =>
    set({ selectedVCTcins }),
}))

export default useTGIDStore
