import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { OrderStatus } from 'types/Orders'
import { buildURLQueryParams } from 'v2/utils/url'

/**
 * The API gets the order status count for last 18 months
 */

interface Params {
  orderStatus: OrderStatus[]
  orderDate?: string
  requestedShipmentDate?: string
  requestedDeliveryDate?: string
  sellerId?: string
  sort?: string
  page?: number
  perPage?: number
}

export const getOrderStatusCounts = async ({
  sellerId,
  params,
}: {
  params: Params
  sellerId?: string | null
}) => {
  const {
    orderStatus,
    orderDate,
    requestedDeliveryDate,
    requestedShipmentDate,
    sort = 'order_date(desc)',
    page = 1,
    perPage = 5,
  } = params

  const queryParams = buildURLQueryParams({
    page,
    per_page: perPage,
    sort: sort,
    order_date: orderDate,
    requested_shipment_date: requestedShipmentDate,
    requested_delivery_date: requestedDeliveryDate,
    order_status: `${orderStatus}`,
    seller_id: params?.sellerId,
  })

  const url = sellerId
    ? `${apiConfig.sellerOrders}/sellers/${sellerId}/order_status_counts?${queryParams}`
    : `${apiConfig.sellerOrders}/order_status_counts?${queryParams}`

  const response = await axios.get(url)
  return response?.data
}
