import { SyntheticEvent, useState } from 'react'

import { OrderTab } from 'components/Orders/OrderDetails'

import { trackCustomEvent } from 'services/fireflyInsights'
import { getMarketplaceProducts } from 'services/items'
import { RoutePath } from 'services/NavigationHelper'
import { getOrderList } from 'services/orders'
import { Direction, PagingParams } from 'services/pageableHelper'
import {
  searchProductReturns,
  searchSellerProductReturns,
} from 'services/productReturns'
import { getSeller, searchSellers } from 'services/seller'
import { SEARCH_TIMEOUT, timeoutPromise } from 'services/timeoutHelper'
import { getCases } from 'services/cases'

import {
  isCase,
  isMarketplaceProduct,
  isOrder,
  isReturn,
  isSmsSeller,
  isShipNodeID,
} from 'types/Guards'
import { FireflyEvent } from 'types/FireflyInsights'
import { MarketplaceProduct } from 'types/Item'
import { Order, Return } from 'types/Orders'
import { SmsDistributionCenter, SmsSeller } from 'types/Seller'
import { Case } from 'types/Case'

import Typeahead from '.'
import { useNavigate } from 'react-router-dom'
import { FlagName, flag } from 'flag'

export interface Props {
  placeholder: string
  partners?: boolean
  items?: boolean
  orders?: boolean
  returns?: boolean
  cases?: boolean
  sellerId?: string
  shipNode?: boolean
  noResultsMessage?: string
}

interface SearchQuery {
  q: string
  seller_id?: string
}

export const getPath = (
  value:
    | SmsSeller
    | MarketplaceProduct
    | Order
    | Return
    | Case
    | SmsDistributionCenter,
  inputValue?: string,
) => {
  if (isSmsSeller(value)) {
    return `/${value.id}${RoutePath.DASHBOARD}`
  } else if (isOrder(value)) {
    return `/${value.seller_id}${RoutePath.ORDERS}/${value.id}`
  } else if (isMarketplaceProduct(value)) {
    return `/${value.seller_id}${RoutePath.ALL_ITEMS}/${value.id}`
  } else if (isReturn(value)) {
    return `/${value.seller_id}${RoutePath.ORDERS}/${value.order_id}?tab=${OrderTab.RETURNS}`
  } else if (isCase(value)) {
    return `/${value.seller_id}${RoutePath.OPEN_CASES}/${value.case_id}`
  } else if (isShipNodeID(value)) {
    const shipNodeTabIndex =
      value.distribution_centers?.findIndex(
        (center: SmsDistributionCenter) =>
          center?.ship_nodes?.length &&
          inputValue &&
          center?.ship_nodes?.[0]?.vmm_id
            ?.toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
      ) ?? 0
    const selectedIndex = shipNodeTabIndex >= 0 ? shipNodeTabIndex : 0

    return flag(FlagName.SHIP_NODE_SEARCH)
      ? `/${value.id}${RoutePath.SHIPPING_INFO}?tab=${selectedIndex}`
      : `/${value.id}${RoutePath.SHIPPING_INFO}`
  } else {
    return ''
  }
}

export const getOptionLabel = (
  value:
    | SmsSeller
    | MarketplaceProduct
    | Order
    | Return
    | Case
    | SmsDistributionCenter,
) => {
  if (isSmsSeller(value)) {
    return value.display_name || value.legal_business_name
  } else if (isMarketplaceProduct(value)) {
    return value.tcin || value.id
  } else if (isOrder(value)) {
    return value.id
  } else if (isReturn(value)) {
    return value.return_order_number
  } else if (isCase(value)) {
    return value.case_number
  } else if (isShipNodeID(value)) {
    return value.display_name
  } else {
    return ''
  }
}

export const paging: PagingParams = {
  page: 0,
  perPage: 20,
  direction: Direction.ASC,
}

const timeoutOptions = {
  timeoutResponse: { data: [], total: 0 },
  rejectOnTimeout: false,
}

export const findSellers = async ({
  q,
  vmmId,
  sellerId,
}: {
  q?: string
  vmmId?: string
  sellerId?: string
}): Promise<SmsSeller[]> => {
  if (sellerId && sellerId.length === 24 && sellerId.indexOf('-') === -1) {
    const seller = await getSeller(sellerId, [{ status: 404 }])
    return seller ? [seller] : []
  }

  const params: { q?: string; vmm_id?: string } = {}

  if (q && q.length > 0) {
    params.q = q
  }
  if (vmmId && vmmId.length > 0 && vmmId.length < 10 && /^\d+$/.test(vmmId)) {
    params.vmm_id = vmmId
  }

  if (!params.q && !params.vmm_id) {
    return Promise.resolve([])
  }

  try {
    const { data } = await timeoutPromise(
      SEARCH_TIMEOUT,
      searchSellers(
        { ...paging, orderBy: 'display_name' },
        {
          ...params,
          fields: ['legal_business_name', 'display_name', 'id'],
        },
      ),
      timeoutOptions,
    )

    return data
  } catch (e) {
    return []
  }
}

export const findProducts = async (
  searchQuery: SearchQuery,
): Promise<MarketplaceProduct[]> => {
  if (!searchQuery) {
    return Promise.resolve([])
  }
  try {
    const { data } = await timeoutPromise(
      SEARCH_TIMEOUT,
      getMarketplaceProducts(searchQuery, paging, true),
      timeoutOptions,
    )

    return data
  } catch (e) {
    return []
  }
}

export const findOrders = async (
  searchQuery: SearchQuery,
): Promise<Order[]> => {
  if (!searchQuery.q || searchQuery.q.length < 10) {
    return Promise.resolve([])
  }

  try {
    const { data } = await timeoutPromise(
      SEARCH_TIMEOUT,
      getOrderList(paging, searchQuery, true),
      timeoutOptions,
    )

    return data
  } catch (e) {
    return []
  }
}

export const findReturns = async (
  searchQuery: SearchQuery,
): Promise<Return[]> => {
  if (!searchQuery.q || searchQuery.q.length < 8) {
    return Promise.resolve([])
  }

  try {
    const { data } = await timeoutPromise(
      SEARCH_TIMEOUT,
      searchQuery.seller_id
        ? searchSellerProductReturns(paging, searchQuery)
        : searchProductReturns(paging, searchQuery),
      timeoutOptions,
    )

    return data
  } catch (e) {
    return []
  }
}

export const findCase = async (searchQuery: SearchQuery): Promise<Case[]> => {
  if (!searchQuery.q || searchQuery.q.length < 8) {
    return Promise.resolve([])
  }

  try {
    const { data } = await getCases({
      case_number: searchQuery.q,
      page: 0,
      perPage: 1,
    })

    return data
  } catch (e) {
    return []
  }
}

export const findShipNode = async (searchQuery: SearchQuery): Promise<any> => {
  if (!searchQuery.q) {
    return Promise.resolve([])
  }

  try {
    const { data } = await searchSellers(
      {
        page: 0,
        perPage: 1,
      },
      {
        ship_node_id: searchQuery.q,
        fields: ['distribution_centers', 'id', 'display_name'],
      },
    )

    return data
  } catch (e) {
    return []
  }
}

const OmniTypeahead = ({
  placeholder,
  partners,
  items,
  orders,
  returns,
  cases,
  sellerId,
  shipNode,
  noResultsMessage,
}: Props) => {
  const navigate = useNavigate()

  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<SmsSeller | MarketplaceProduct | Order>>(null)
  const [options, setOptions] = useState<
    SmsSeller[] | MarketplaceProduct[] | Order[]
  >([])
  const [loading, setLoading] = useState(false)

  const searchAll = async (query: string): Promise<any> => {
    let allResults: any = []
    const trimmedQuery = query.trim()

    const [
      sellerNameResults,
      sellerVmmResults,
      sellerIdResults,
      productResults,
      orderResults,
      returnResults,
      caseResults,
      shipNodeResults,
    ] = await Promise.all([
      partners ? findSellers({ q: trimmedQuery }) : [],
      partners ? findSellers({ vmmId: trimmedQuery }) : [],
      partners ? findSellers({ sellerId: trimmedQuery }) : [],
      items ? findProducts({ q: trimmedQuery, seller_id: sellerId }) : [],
      orders ? findOrders({ q: trimmedQuery, seller_id: sellerId }) : [],
      returns ? findReturns({ q: trimmedQuery, seller_id: sellerId }) : [],
      cases ? findCase({ q: trimmedQuery }) : [],
      shipNode ? findShipNode({ q: trimmedQuery }) : [],
    ])

    const allSellerResults = [
      ...sellerNameResults,
      ...sellerVmmResults,
      ...sellerIdResults,
    ]

    if (allSellerResults.length > 0) {
      const resultsWithLabel = allSellerResults.map((result) => ({
        ...result,
        label: 'Partners',
      }))
      allResults = [...allResults, ...resultsWithLabel]
    }
    if (productResults.length > 0) {
      const resultsWithLabel = productResults.map((result) => ({
        ...result,
        label: 'Items',
      }))
      allResults = [...allResults, ...resultsWithLabel]
    }
    if (orderResults.length > 0) {
      const resultsWithLabel = orderResults.map((result) => ({
        ...result,
        label: 'Orders',
      }))
      allResults = [...allResults, ...resultsWithLabel]
    }
    if (returnResults.length > 0) {
      const resultsWithLabel = returnResults.map((result) => ({
        ...result,
        label: 'Returns',
      }))
      allResults = [...allResults, ...resultsWithLabel]
    }
    if (caseResults.length > 0) {
      const resultsWithLabel = caseResults.map((result) => ({
        ...result,
        label: 'Case',
      }))
      allResults = [...allResults, ...resultsWithLabel]
    }
    if (shipNodeResults.length > 0) {
      const resultsWithLabel = shipNodeResults.map((result: any) => ({
        ...result,
        label: 'Ship Node',
      }))
      allResults = [...allResults, ...resultsWithLabel]
    }

    return allResults
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: SmsSeller | MarketplaceProduct | Order,
  ) => {
    setOptions([])
    if (value) {
      if (isSmsSeller(value)) {
        trackCustomEvent(FireflyEvent.OMNI_SEARCH, 'search_category', 'partner')
      } else if (isMarketplaceProduct(value)) {
        trackCustomEvent(FireflyEvent.OMNI_SEARCH, 'search_category', 'item')
      } else if (isOrder(value)) {
        trackCustomEvent(FireflyEvent.OMNI_SEARCH, 'search_category', 'order')
      } else if (isReturn(value)) {
        trackCustomEvent(FireflyEvent.OMNI_SEARCH, 'search_category', 'return')
      }
      navigate(getPath(value, inputValue))
    }
  }

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if ((selectedValue && reason === 'input') || enteredValue === '') {
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    if (reason !== 'reset') {
      setInputValue(enteredValue)
    } else {
      setInputValue('')
      return
    }

    setInputValue(enteredValue)
    setLoading(true)
    const data = await searchAll(enteredValue)
    setLoading(false)
    setOptions(data)
  }

  const getRenderedOption = (option: any) => {
    if ('display_name' in option) {
      return option.display_name
    } else if ('legal_business_name' in option) {
      return option.legal_business_name
    } else if ('tcin' in option) {
      if ('return_order_number' in option) {
        return option.return_order_number
      } else {
        return option.tcin
      }
    } else if ('order_number' in option) {
      return option.id
    } else if ('case_number' in option) {
      return option.case_number
    } else if ('ship_nodes' in option) {
      return option.distribution_centers[0].ship_nodes[0].vmm_id
    }
  }

  return (
    <Typeahead
      aria-label={placeholder}
      placeholder={placeholder}
      label={placeholder}
      value={selectedValue}
      options={options}
      loading={loading}
      noOptionsText={noResultsMessage}
      onInputChange={handleInputChange}
      onChange={handleSelectedOptionChange}
      isOptionEqualToValue={(
        option: SmsSeller | MarketplaceProduct | Order,
        value: Nullable<SmsSeller | MarketplaceProduct | Order>,
      ) => option.id === value?.id}
      filterOptions={(options: any) => options}
      inputValue={inputValue}
      groupBy={(option: any) => option.label}
      getOptionLabel={getOptionLabel as any}
      disableClearable
      renderOption={(
        props: any,
        option: SmsSeller | MarketplaceProduct | Order,
      ) => {
        return (
          <li {...props} key={option.id}>
            {getRenderedOption(option)}
          </li>
        )
      }}
    />
  )
}

export default OmniTypeahead
