import axios from 'axios'
import apiConfig from 'config/apiConfig'
import SellerUser, { Role } from 'types/SellerUser'

interface Payload {
  contact: SellerUser
  role_ids?: Role[]
  functional_responsibilities?: number[] | undefined
  primary_seller_id?: string
}

interface Params {
  sellerId: string
  payload: Payload
}

export const postSellerUser = async ({
  sellerId,
  payload,
}: Params): Promise<any> => {
  try {
    const response = await axios.post(
      `${apiConfig.sellerUsers}/sellers/${sellerId}/seller_users`,
      payload,
    )
    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
