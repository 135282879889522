import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'
interface Params {
  requested_shipment_date: string
  sellerId?: string
}

/**
 * gets orders to be shipped count
 *
 * @param {{
 *   sellerId?: string
 *   params: Params
 * }} {
 *   sellerId,
 *   params,
 * }
 * @return {*}
 */
export const getOrdersToBeShipped = async ({
  sellerId,
  params,
}: {
  sellerId?: string | null
  params: Params
}) => {
  const queryParams = buildURLQueryParams({
    requested_shipment_date: params?.requested_shipment_date,
    seller_id: params?.sellerId,
  })
  const url = sellerId
    ? `${apiConfig.sellerOrders}/sellers/${sellerId}/orders_to_be_shipped?${queryParams}`
    : `${apiConfig.sellerOrders}/orders_to_be_shipped?${queryParams}`
  const response = await axios.get(url)
  return response?.data ?? []
}
