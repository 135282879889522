import React from 'react'

import startCase from 'lodash/fp/startCase'

import Typography from '@mui/material/Typography'

import styled from '@emotion/styled'

import {
  transformDataForLineGraph,
  getTimePeriod,
  getVendorFilter,
} from 'services/biReporting'
import { formatDate, DATE_PICKER_FORMAT } from 'services/dateService'

import formatCurrency from 'services/formatCurrency'
import { fetchCardDataThruSecurityGateway } from 'services/securityGateway'

import { SmsSeller } from 'types/Seller'

import RangeSelectors, { defaultRange } from './RangeSelectors'
import SalesMetricsGraphTable from './SalesMetricsGraphTable'
import SalesMetricsGraph from './SalesMetricsGraph'

const StyledTotal = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(2),
}))

const StyledContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(4),
}))

export type ValueFormats =
  | 'number'
  | 'currency'
  | 'percentage'
  | 'percentNoCalc'

interface Props {
  graphName: string
  cardId: string
  seller?: SmsSeller
  aggregateType?: 'total' | 'average'
  valueFormat?: ValueFormats
  displayType?: 'graph' | 'table'
}

export interface Range {
  from: Date
  to: Date
}

export type DataType = Record<string, string | number | Date>

export const makeDateRange = (from: Date, to: Date) =>
  `${formatDate(from, DATE_PICKER_FORMAT)}/${formatDate(to, DATE_PICKER_FORMAT)}`

export const formatValue = (value: number, format: ValueFormats) => {
  if (format === 'currency') {
    return formatCurrency(value)
  } else if (format === 'percentage') {
    return `${(value * 100).toFixed(2)}%`
  } else if (format === 'percentNoCalc') {
    return `${value.toFixed(2)}%`
  } else {
    return value
  }
}

export const SalesMetricsCardContent = ({
  graphName,
  cardId,
  seller,
  valueFormat = 'number',
  aggregateType,
  displayType = 'graph',
}: Props) => {
  const [rawData, setRawData] = React.useState<DataType[]>()
  const [graphData, setGraphData] = React.useState<DataType[]>()
  const [range, setRange] = React.useState<Range>(defaultRange)
  const [compareInterval, setCompareInterval] = React.useState<string>('')
  const [aggregate, setAggregate] = React.useState<number | undefined>()
  const [compareAggregate, setCompareAggregate] = React.useState<
    number | undefined
  >()

  React.useEffect(() => {
    if (!seller?.id || !seller?.vmm_id) return

    const vendorFilter = getVendorFilter(seller.vmm_id)

    const timePeriod = getTimePeriod({
      interval: makeDateRange(range.from, range.to),
      type: 'absolute',
      compareIntervalValues: compareInterval ? [compareInterval] : undefined,
    })

    fetchCardDataThruSecurityGateway(
      cardId,
      seller.id,
      vendorFilter,
      timePeriod,
    ).then((result) => {
      setRawData(transformDataForLineGraph(result, !!timePeriod.compare_by))
    })
  }, [seller, range, compareInterval, cardId])

  React.useEffect(() => {
    if (!rawData) return

    const rangeData: DataType[] = []
    let total = 0.0
    let compareTotal: number | undefined
    let count = 0
    let compareCount = 0

    rawData.forEach((element) => {
      if (element.date >= range.from && element.date <= range.to) {
        total = total + (element.base as number)
        count = count + 1
        if (element.compare) {
          compareTotal = (compareTotal ?? 0.0) + (element.compare as number)
          compareCount = compareCount + 1
        }

        const newData: DataType = {
          ...element,
          date: formatDate(element.date, 'MM-dd'),
        }

        if (element.compareDate) {
          newData.compareDate = formatDate(element.compareDate, 'MM-dd')
        }
        rangeData.push(newData)
      }
    })

    if (aggregateType === 'total') {
      setAggregate(total)
      setCompareAggregate(compareTotal)
    } else if (aggregateType === 'average') {
      setAggregate(total / count)
      setCompareAggregate(
        compareTotal ? compareTotal / compareCount : undefined,
      )
    }

    setGraphData(rangeData)
  }, [rawData, range, aggregateType])

  let textColor
  if (aggregate === compareAggregate) textColor = 'grey'
  else if (
    aggregate !== undefined &&
    compareAggregate !== undefined &&
    aggregate < compareAggregate
  )
    textColor = 'red'
  else if (
    aggregate !== undefined &&
    compareAggregate !== undefined &&
    aggregate > compareAggregate
  )
    textColor = 'green'

  let DisplayComponent = SalesMetricsGraph
  if (displayType === 'table') {
    DisplayComponent = SalesMetricsGraphTable
  }

  return (
    <>
      <StyledContent>
        {aggregateType && (
          <>
            <StyledTotal variant="h6">
              {`${startCase(aggregateType)}: ${formatValue(
                aggregate ?? 0.0,
                valueFormat,
              )}`}
            </StyledTotal>
            {aggregate !== undefined &&
              compareAggregate !== undefined &&
              compareInterval !== '' && (
                <Typography variant="caption" color={textColor}>
                  {formatValue(
                    (aggregate - compareAggregate) / aggregate,
                    'percentage',
                  )}
                </Typography>
              )}
          </>
        )}
        <RangeSelectors
          graphName={graphName}
          defaultRange={defaultRange}
          range={range}
          onChangeRange={(range) => setRange(range)}
          disableDatesBefore={
            seller?.created ? new Date(seller?.created) : undefined
          }
          compareInterval={compareInterval}
          onChangeCompareInterval={(interval) => setCompareInterval(interval)}
          compareAggregate={
            compareAggregate
              ? formatValue(compareAggregate, valueFormat)
              : compareAggregate
          }
        />
      </StyledContent>
      <div>
        <DisplayComponent
          graphData={graphData}
          isCompare={compareInterval !== ''}
          valueFormat={valueFormat}
        />
      </div>
    </>
  )
}

export default SalesMetricsCardContent
