import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { currentSellerId } from 'store/selectors'
import { useOrdersDashboardStore } from 'v2/store'

import { OrderStatus } from 'types/Orders'
import { roundToTwoDecimals } from 'v2/utils/helper'
import { getOrderStatusCounts } from 'v2/services/getOrderStatusCount'
import SummaryCards from '../BreakdownSummary/SummaryCards'

const TotalShippedAndCancelledOrderCount = () => {
  const sellerId = useSelector(currentSellerId)

  const {
    view,
    fillRate,
    selectedSellerId,
    totalOrderCount,
    deliverByDateInterval,
    isOrdersAtRiskChecked,
    requestShipDateInterval,
    orderPlacedDateInterval,
    updateFillRate,
  } = useOrdersDashboardStore()

  const getBreakdownSummaryData = async () => {
    const resp = await getOrderStatusCounts({
      params: {
        sellerId: selectedSellerId,
        orderDate: orderPlacedDateInterval,
        orderStatus: [OrderStatus.SHIPPED, OrderStatus.CANCELED],
        requestedShipmentDate: requestShipDateInterval,
        requestedDeliveryDate: deliverByDateInterval,
      },
      sellerId: view === 'INTERNAL' ? null : sellerId,
    })
    return resp
  }

  // api call
  const { data, isFetching, isError } = useQuery({
    queryKey: [
      'TOTAL_SHIPPED_CANCEL_ORDER_COUNT',
      requestShipDateInterval,
      deliverByDateInterval,
      orderPlacedDateInterval,
      selectedSellerId,
    ],
    queryFn: getBreakdownSummaryData,
  })
  const getFormattedData = useMemo(() => {
    if (!data) return []

    const toolTipData = {
      [OrderStatus.SHIPPED]: {
        tooltip: '',
        count: 0,
        isDisable: isOrdersAtRiskChecked,
      },
      [OrderStatus.CANCELED]: {
        tooltip: 'Count of order IDs with at least one Return',
        count: 0,
        isDisable: isOrdersAtRiskChecked,
      },
    }

    data.forEach((value: any) => {
      const status = value.order_status
      if (status === OrderStatus.SHIPPED || status === OrderStatus.CANCELED)
        toolTipData[status as keyof typeof toolTipData].count = value.count ?? 0
    })

    const orderedData = Object.keys(toolTipData).map((key) => {
      const ele = toolTipData[key as keyof typeof toolTipData]
      // set fill rate
      if (key === 'SHIPPED' && fillRate === null) {
        if (ele?.count && totalOrderCount && totalOrderCount > 0) {
          const fillRate = roundToTwoDecimals(
            (ele?.count / totalOrderCount) * 100,
          )
          updateFillRate(fillRate!)
        }
      }
      return {
        order_status: key,
        count: ele?.count,
        tooltip: ele.tooltip,
        isDisable: ele?.isDisable,
      }
    })

    return orderedData
  }, [data, totalOrderCount, fillRate, isOrdersAtRiskChecked, updateFillRate])

  if (isError) {
    return (
      <p className="hc-ta-left hc-pv-dense hc-clr-error">
        There was an error fetching the data
      </p>
    )
  }

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="flex-start">
          {Array.from({ length: 2 }).map((_, index) => (
            <Grid.Item key={index} xs={2} className="hc-pl-none">
              <Placeholder.Rect height="60px" emphasized />
            </Grid.Item>
          ))}
        </Grid.Container>
      </Placeholder>
    )
  }
  return <SummaryCards data={getFormattedData} />
}

export default TotalShippedAndCancelledOrderCount
