import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'

interface Params {
  date: string
  sellerId?: string
}

/**
 * The API gets the unshipped orders data by filter age
 *
 * @param {{
 *   params: Params
 *   sellerId?: string
 * }} {
 *   sellerId,
 *   params,
 * }
 * @return {*}
 */
export const getUnshippedOrdersAge = async ({
  sellerId,
  params,
}: {
  params: Params
  sellerId?: string | null
}) => {
  const queryParams = buildURLQueryParams({
    date: params?.date,
    seller_id: params?.sellerId,
  })

  const url = sellerId
    ? `${apiConfig.sellerOrders}/sellers/${sellerId}/unshipped_orders_age?${queryParams}`
    : `${apiConfig.sellerOrders}/unshipped_orders_age?${queryParams}`
  const response = await axios.get(url)
  return response?.data ?? []
}
