import styled from '@emotion/styled'
import { Card, Table, Grid, Divider } from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'

export const StyledCardContainer = styled(Card)`
  &.C-Card {
    border-radius: 5px;
    padding-top: 10px;
  }
`

export const StyledReturnRateCardContainer = styled(Card)`
  &.C-Card {
    min-height: 430px;
  }
  .C-Input--type-label {
    font-size: 16px;
    font-weight: bold;
  }
`

export const StyledCompareReturnRateCardGridContainer = styled(Grid.Container)`
  &.C-GridContainer {
    height: 330px;
  }
`

export const StyledFillterContainer = styled(Card)`
  &.C-Card {
    border-radius: 0;
  }
  .C-Heading {
    font-weight: 600;
    text-transform: uppercase;
  }
  .C-Input--type-label {
    font-size: 16px;
    font-weight: bold;
  }
`

export const StyledTable = styled(Table)`
  &.C-TableAdvanced {
    th {
      font-weight: bold;
    }
  }
`

export const StyledItemTypeGridItem = styled(Grid.Item)`
  .C-Input--type-info {
    display: none;
  }
`

export const StyledDatePicker = styled(DatePicker)`
  .C-Card {
    &.C-DatePicker__Card {
      width: max-content;
      left: unset;
      right: 0;
      top: 55px;
    }
  }
  .C-GridContainer {
    flex-direction: row;
  }
`
export const StyledHelpArticleGridContainer = styled(Grid.Container)`
  &.C-GridContainer {
    height: calc(100vh - 110px);
    overflow: auto;
  }
  .C-Heading {
    text-transform: none;
  }
`

export const StyledItemTypeTableGridContainer = styled(Grid.Container)`
  min-height: 350px;
`
export const StyledItemTypeTableGridItem = styled(Grid.Item)`
  & .C-TableAdvancedContainer {
    max-height: 320px;
  }
`

export const StyledReturnReason = styled.p`
  width: 150px;
`

export const StyledDivider = styled(Divider)`
  z-index: var(--canvas-z-index--active) !important;
`
