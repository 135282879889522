import { Grid, Heading, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { useOrdersDashboardStore } from 'v2/store'

import OrderDetailsTable from './OrderDetailsTable'
import ReportDownload, { ReportType } from '../ReportDownload'
import { StyledCardWidth } from '../styles'

const OrderDetails = () => {
  const { totalOrderCount } = useOrdersDashboardStore()
  const isDisable = (totalOrderCount ?? 0) > 40000

  return (
    <Grid.Container direction="column" className="hc-pv-lg">
      <Grid.Item>
        <p className="hc-fs-md hc-txt-uppercase">
          <strong>Record Count : {totalOrderCount?.toLocaleString()}</strong>
        </p>
      </Grid.Item>

      <StyledCardWidth className="hc-pa-normal">
        <Grid.Container align="center" justify="space-between">
          <Grid.Item>
            <Heading className="hc-clr-grey02" size={5}>
              ORDER DETAILS
            </Heading>
          </Grid.Item>
          <Grid.Item>
            <Tooltip
              location={isDisable ? 'bottom' : 'none'}
              content={
                'When record count is above 40,000, downloading is disabled as the table size is too large.'
              }
            >
              <ReportDownload
                isDisable={isDisable}
                orderDashboardReportType={[ReportType.ORDER_SUMMARY]}
                fileName="order-details"
              />
            </Tooltip>
          </Grid.Item>
        </Grid.Container>

        <OrderDetailsTable />
      </StyledCardWidth>
    </Grid.Container>
  )
}

export default OrderDetails
