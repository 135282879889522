import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { currentSellerId } from 'store/selectors'
import { getConsents } from 'v2/services/getConsents'

export type ConsentItemTypedOptionType = {
  id: string
  value: string
  label: string
}

const useConsentItemTypeAutoComplete = () => {
  const [consentItemTypeOptions, setConsentItemTypeOptions] = useState<
    ConsentItemTypedOptionType[]
  >([])
  const [selectedConsentItemTypeOption, setSelectedConsentItemTypeOption] =
    useState<ConsentItemTypedOptionType | null>(null)

  const sellerId = useSelector(currentSellerId)

  useEffect(() => {
    const mapData = () => {
      getConsents(sellerId ?? '').then((res) => {
        const mapToAutoCompleteOptions = res
          .map((option: any) => {
            if (option.item_type_id && option.item_type_name) {
              return {
                id: option?.item_type_id,
                value: option?.item_type_id,
                label: option?.item_type_name,
              }
            }
            return null
          })
          .filter(Boolean)

        setConsentItemTypeOptions(
          mapToAutoCompleteOptions as ConsentItemTypedOptionType[],
        )
      })
    }
    mapData()
  }, [setConsentItemTypeOptions, sellerId])

  const onConsentItemTypeUpdate = (
    _id: string,
    value: ConsentItemTypedOptionType | null,
  ) => {
    setSelectedConsentItemTypeOption(value)
  }

  return {
    selectedConsentItemTypeOption,
    consentItemTypeOptions,
    onConsentItemTypeUpdate,
  }
}

export default useConsentItemTypeAutoComplete
