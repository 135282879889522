import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  Anchor,
  Drawer,
  Grid,
  Heading,
  Placeholder,
  Button,
} from '@enterprise-ui/canvas-ui-react'

import { isArrayEmpty } from 'v2/utils/helper'
import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { sellerExecuteCardQuery } from 'v2/query'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { StyledHelpArticleGridContainer } from '../../styles'
import { faqQuestionAndAnswer, getReasonsList, IQueAndLink } from './helper'
import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'

const headingText = 'Partner Help Articles'

const HelpArticle = () => {
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const sellerId = useSelector(currentSellerId) ?? ''
  const [articles, setArticles] = useState<IQueAndLink[]>([])
  const [isHelpArticleOpen, setIsHelpArticleOpen] = useState(false)

  const { data, isFetching, isError } = useQuery({
    queryFn: () =>
      sellerExecuteCardQuery({
        cardId: GREEN_FIELD_CARD_ID.SELLER_HELP_ARTICLE,
        sellerId,
        interval: 'last segment',
        vmmId,
      }),
    enabled: isHelpArticleOpen,
  })

  useEffect(() => {
    setArticles(getReasonsList(data))
  }, [data])

  const handleHelpArticle = () => {
    setIsHelpArticleOpen(true)

    logFireflyEvent(
      FireflyEvent.RETURN_INSIGHTS_HELP_ARTICLES_BUTTON_CLICK,
      'help_article_button_click',
    )
  }

  const logFireflyEvent = (event: FireflyEvent, key?: string, data?: any) => {
    trackCustomEvent(event, key ?? event.toString(), data ?? '')
  }

  const getContent = () => {
    if (isFetching) {
      return (
        <Placeholder>
          <Grid.Container justify="center" spacing="dense" direction="column">
            {Array.from({ length: 5 }).map((_, index) => (
              <Grid.Item key={index} xs={12}>
                <Placeholder.Rect height="30px" />
              </Grid.Item>
            ))}
          </Grid.Container>
        </Placeholder>
      )
    }

    if (isError) {
      return (
        <div
          data-testid="error-state-text"
          className="hc-pa-expanded hc-ta-center"
        >
          <p>There was an error fetching the data</p>
        </div>
      )
    }

    if (isArrayEmpty(articles)) {
      return (
        <div
          data-testid="empty-state-text"
          className="hc-pa-expanded hc-ta-center"
        >
          <p>No articles available!</p>
        </div>
      )
    }
    const updateEventOnFirefly = (value: string) => {
      logFireflyEvent(
        FireflyEvent.RETURN_INSIGHTS_HELP_ARTICLES_CLICK,
        'help_articles_click',
        value,
      )
    }

    return (
      <StyledHelpArticleGridContainer
        data-testid="articles-container"
        justify="space-between"
      >
        <Grid.Item className="hc-pb-none">
          {articles?.map(
            (ele: { question: string; link: string }, idx: number) => (
              <div>
                <Anchor
                  className="hc-fs-sm hc-pv-dense"
                  key={idx}
                  href={ele?.link}
                  target="_blank"
                  onClick={() => updateEventOnFirefly(ele?.question)}
                >
                  {ele?.question}
                </Anchor>
              </div>
            ),
          )}
        </Grid.Item>
        <Grid.Item xs={12}>
          <Heading className="hc-pb-normal" size={4}>
            FAQs
          </Heading>
          {faqQuestionAndAnswer.map((value, idx) => (
            <div className="hc-pb-sm">
              <Heading size={6}>
                {idx + 1}. {value?.question}
              </Heading>
              <p className="hc-fs-xs hc-ws-pre-wrap">
                {value?.answer}
                {value.linkText && (
                  <Anchor target="_blank" href={value.link}>
                    {value.linkText}
                  </Anchor>
                )}
              </p>
            </div>
          ))}
        </Grid.Item>
      </StyledHelpArticleGridContainer>
    )
  }

  return (
    <>
      <Button size="expanded" type="submit" onClick={handleHelpArticle}>
        Help & FAQs
      </Button>

      <Drawer
        xs={3}
        isVisible={isHelpArticleOpen}
        headingText={headingText}
        onRequestClose={() => setIsHelpArticleOpen(false)}
        spacing="expanded"
      >
        {getContent()}
      </Drawer>
    </>
  )
}

export default HelpArticle
