import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'

interface Params {
  page?: number
  relationshipType?: 'SA' | 'VC'
  perPage?: number
  itemTypeId?: string
  reviewDateRange?: string | null
  sort?: string
  parentProductId?: string
}

export const getProductEnhancements = async (
  sellerId: string,
  params: Params,
) => {
  try {
    const queryparams = buildURLQueryParams({
      page: params.page,
      relationship_type: params.relationshipType,
      per_page: params.perPage,
      item_type_id: params.itemTypeId,
      review_end: params.reviewDateRange,
      sort: params.sort,
      parent_id: params.parentProductId,
    })

    const response = await axios.get(
      `${apiConfig.sms}/sellers/${sellerId}/product_enhancements?${queryparams}`,
    )

    return { data: response.data, total: response.headers['x-total-count'] }
  } catch (err) {
    return Promise.reject(err)
  }
}
