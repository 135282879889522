import apiConfig, {
  ENVIRONMENT_PRODUCTION,
  EXTERNAL_PROD_HOSTNAME,
  EXTERNAL_STAGE_HOSTNAME,
} from 'config/apiConfig'

export interface IQueAndLink {
  question: string
  link: string
}

export interface IHelpArticleQueAndLink {
  [key: string]: IQueAndLink
}

const preQuestion = `How to reduce returns for`
let envUrl
if (apiConfig.environment === ENVIRONMENT_PRODUCTION) {
  envUrl = `https://${EXTERNAL_PROD_HOSTNAME}`
} else {
  envUrl = `https://${EXTERNAL_STAGE_HOSTNAME}`
}
const howToUseDashboardArticleLink = `${envUrl}/article/000187193`

const helpArticleQueAndLink: IHelpArticleQueAndLink = {
  'APPAREL/ACCESS': {
    question: `${preQuestion} Apparel and Accessories?`,
    link: `${envUrl}/article/000153354`,
  },
  'ESSENTIALS & BEAUTY': {
    question: `${preQuestion} Essentials & Beautry?`,
    link: ` ${envUrl}/article/000104875`,
  },
  'FOOD/BEVERAGE': {
    question: `${preQuestion} Food/Beverage?`,
    link: `${envUrl}/article/000180240`,
  },
  HARDLINES: {
    question: `${preQuestion} Hardlines?`,
    link: `${envUrl}/article/000180241`,
  },
  HOME: {
    question: `${preQuestion} Home?`,
    link: `${envUrl}/article/000148869`,
  },
}

export const faqQuestionAndAnswer = [
  {
    question: 'How is Return Rate calculated?',
    answer:
      'Return Rate is calculated based on the order timeframe and it tells you what proportion of your orders (for the given time-period) had guest returns against it. Basing return rate on order timeframe helps you factor in seasonality and promotional events that influence guest returns.',
  },
  {
    question: 'What is the benchmark based on?',
    answer:
      'Benchmarks are derived at the item type level taking into consideration industry standards and marketplace trends.',
  },
  {
    question: "Why can't I view return rates for the last 30 days?",
    answer:
      'Since the return rate is calculated against the guest order date, the recent rate for the last 30 days will not provide a clear picture of the trends. On average, it takes 30 days for 85-90% of returns to materialize.',
  },
  {
    question:
      '“Changed Mind” is one of the top return reasons. What can I do to reduce returns?',
    answer:
      ' Guests pick “Changed Mind" often when there is a combination of different reasons that influences them to return the product. Detailed and accurate product content can help guests make informed purchase decisions, reducing the likelihood of a return. Refer to the articles on how to reduce returns for the different categories of item types.',
  },
  {
    question: 'How do I view return rates for all item types?',
    answer:
      'Step 1: Ensure there is no Group filter selected.\nStep 2: Select the order timeframe for which you want to see returns against.\nStep 3: Click the download icon in the “Top Item Types” section.',
  },
  {
    question: 'Is there a limit to the download output?',
    answer:
      ' The downloaded file has a limit of 20,000 rows. It is recommended to download reports in batches utilizing group and item type filters.',
  },
  {
    question:
      'Where can I find information on return order numbers and related tracking information?',
    answer:
      'That information can still be found on the Returns page on the Portal. For more information on researching returns, visit ',
    link: `${envUrl}/article/000087101`,
    linkText: 'this help center article',
  },
]

export const getReasonsList = (response: any): IQueAndLink[] => {
  let data: IQueAndLink[] = [
    {
      question: 'How do I use this dashboard?',
      link: howToUseDashboardArticleLink,
    },
  ]

  const articlesSpecificToPartner =
    response?.[0]?.BUs?.split(',')?.reduce(
      (list: IQueAndLink[], bu: string) => {
        if (helpArticleQueAndLink[bu]) {
          list.push(helpArticleQueAndLink[bu])
        }
        return list
      },
      [],
    ) ?? []
  return [...data, ...articlesSpecificToPartner]
}
