import axios from 'axios'
import apiConfig from 'config/apiConfig'
import SellerUser from 'types/SellerUser'

interface Params {
  contact: SellerUser
}

export const updateSmsSellerUser = async ({ contact }: Params) => {
  try {
    const response = await axios.put(
      `${apiConfig.sellerUsers}/seller_users/${contact.id}`,
      contact,
    )

    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
