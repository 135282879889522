import { useState, useCallback } from 'react'
import { Button, Grid, Modal, useToaster } from '@enterprise-ui/canvas-ui-react'
import { useSelector } from 'react-redux'
import { currentSellerId } from 'store/selectors'
import { updateBulkProduct } from 'v2/services/updateBulkProduct'
import { useTGIDStore } from 'v2/store'
import { StyledSubmitButton } from '../../styles'
import { useQueryClient } from '@tanstack/react-query'

export enum ReviewStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const BulkSubmission = () => {
  const queryClient = useQueryClient()
  const makeToast = useToaster()
  const sellerId = useSelector(currentSellerId) ?? ''
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false)
  const { selectedRows, reviewItemLists, updateSelectedRows } = useTGIDStore()
  const [reviewStatus, setReviewStatus] = useState('')

  const onContinueClick = useCallback(async () => {
    try {
      const selectedProductIds = selectedRows?.map(
        (row) =>
          reviewItemLists.find((item) => item.tcin === row)?.productId ?? '',
      )

      const response = await updateBulkProduct({
        sellerId,
        reviewStatus,
        productIds: selectedProductIds,
      })
      const typeOfSubmit =
        reviewStatus === ReviewStatus.APPROVED ? 'Recommended' : 'Original'
      if (response.successful_update_count === selectedRows.length) {
        makeToast({
          type: 'success',
          heading: 'Submitted Successfully',
          message: `${typeOfSubmit} values for ${selectedRows.length} tcin(s) submitted successfully.`,
        })
      } else {
        makeToast({
          type: 'error',
          heading: 'Error',
          message: `${typeOfSubmit} values for ${response.successful_update_count} tcin(s) submitted successfully.`,
        })
      }
      queryClient.refetchQueries({ queryKey: ['PRODUCT_ENHANCEMENTS'] })
    } catch (error) {
      makeToast({
        type: 'error',
        heading: 'Error',
        message: 'Error in submission, please try again.',
      })
    } finally {
      updateSelectedRows([])
      setIsOpenSubmitModal(false)
    }
  }, [
    selectedRows,
    sellerId,
    reviewStatus,
    queryClient,
    reviewItemLists,
    makeToast,
    updateSelectedRows,
  ])

  const onSubmitClick = useCallback(
    (status: string) => {
      if (selectedRows.length > 0) {
        setReviewStatus(status)
        setIsOpenSubmitModal(true)
      } else {
        setIsOpenErrorModal(true)
      }
    },
    [selectedRows.length],
  )

  return (
    <>
      <Grid.Item className="hc-pb-none hc-mr-expanded">
        <Grid.Container>
          <Grid.Item>
            <Button
              type="secondary"
              onClick={() => onSubmitClick(ReviewStatus.REJECTED)}
            >
              Submit original values{' '}
              {selectedRows.length > 0 && `(${selectedRows.length})`}
            </Button>
          </Grid.Item>
          <Grid.Item>
            <StyledSubmitButton
              type="secondary"
              onClick={() => onSubmitClick(ReviewStatus.APPROVED)}
            >
              Submit recommended values{' '}
              {selectedRows.length > 0 && `(${selectedRows.length})`}
            </StyledSubmitButton>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Modal
        isVisible={isOpenErrorModal}
        size="dense"
        headingText="Error"
        onRefuse={() => setIsOpenErrorModal(false)}
      >
        <div className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>
              <p>
                No TCIN are selected to proceed. Kindly select the TCIN and then
                proceed.
              </p>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button onClick={() => setIsOpenErrorModal(false)} type="primary">
                Okay
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
      <Modal
        isVisible={isOpenSubmitModal}
        size="dense"
        headingText="Do you wish to continue?"
        onRefuse={() => setIsOpenSubmitModal(false)}
      >
        <div className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>
              <p>
                {reviewStatus === 'APPROVED' ? 'Recommended' : 'Original'}{' '}
                values will be applied to all the TCIN attributes you have
                selected.
              </p>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container
            direction="row-reverse"
            spacing="dense"
            className="hc-pt-md"
          >
            <Grid.Item>
              <Button onClick={onContinueClick} type="primary">
                Continue
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                onClick={() => setIsOpenSubmitModal(false)}
                type="secondary"
              >
                Cancel
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}
