import { useRef } from 'react'
import { Button, Grid, Heading, Chip } from '@enterprise-ui/canvas-ui-react'
import CopyToClipboard from 'react-copy-to-clipboard'

import { getPromotionStatus, saveFile } from 'v2/utils/helper'
import { MerchandiseHierarchy, Promotion } from 'v2/query/promotionByIdQuery'
import { StyledButtons, StyledCard } from './styles'
import Link from 'components/common/Link'
import { DIVISION_EMAIL } from 'v2/constant/divisionEmail'
import tcinPromotionTemplate from '../../../assets/sheets/TCINPromotionTemplate.xlsx'

interface Props {
  promotion?: Promotion
}

const PromotionParticipation = ({ promotion }: Props) => {
  const downloadLink = useRef<any>(null)

  const onTemplateDownload = () => {
    saveFile(tcinPromotionTemplate, 'TCINPromotionTemplate.xlsx')
  }

  const mappedEmails = (merchHierarchy?: MerchandiseHierarchy[]): any => {
    if (!merchHierarchy) return []
    const firstDivisionName = merchHierarchy?.[0]?.division_name

    return firstDivisionName && DIVISION_EMAIL[firstDivisionName.toUpperCase()]
      ? DIVISION_EMAIL[firstDivisionName.toUpperCase()]
      : ''
  }

  const emailsFromDivisions = mappedEmails(promotion?.merchandiseHierarchies)

  const sendEmail = () => {
    const mailtoLink = `mailto:${emailsFromDivisions?.[0]}?cc=${emailsFromDivisions?.[1]} `

    window.open(mailtoLink, '_blank')
  }

  const status = getPromotionStatus(
    promotion?.startDate as string,
    promotion?.endDate as string,
  )

  return (
    <div className="hc-mt-expanded">
      <Grid.Container className=" hc-ma-none " justify="space-between">
        <Grid.Item className="hc-pb-none hc-pl-none">
          <Heading size={3} className="normal-weight ">
            Promotion Participation
          </Heading>
        </Grid.Item>

        <Grid.Item className="hc-pb-none hc-pr-none hc-mr-expanded">
          <Chip
            className="hc-mr-none"
            color={status === 'UPCOMING' ? 'alert' : 'default'}
          >
            <strong>
              {status === 'UPCOMING'
                ? 'Participation Open'
                : 'Participation Closed'}
            </strong>
          </Chip>
        </Grid.Item>
      </Grid.Container>

      <p className="hc-pt-expanded border-top">
        To register TCINs for a promotion, sellers must complete the
        participation template available on this page and submit it to the
        Target Team Members listed here. Once your request is reviewed, you will
        receive a confirmation from Target. For best practices on promotions,
        please refer to the{' '}
        <Link to={`/article/000120805 `} target="_blank">
          Promotions and Events Checklist
        </Link>{' '}
        article in the Help Center.
      </p>
      <Grid.Container
        justifyContent="space-between"
        data-testid="content-services"
      >
        <Grid.Item xs={6} sm={6} md={6} lg={6}>
          <StyledCard className="hc-pa-normal hc-bg-grey06">
            <Heading size={4} className="normal-weight border-bottom">
              Download Template
            </Heading>

            <p className="hc-clr-grey01 hc-mt-normal">
              In order to participate in this promotion, your request must be
              approved by TargetPlus. As a part of this process, you must submit
              the list of items you would like Target to consider including in
              this promotion.
            </p>
            <Button
              disabled={status !== 'UPCOMING'}
              onClick={onTemplateDownload}
              type="secondary"
            >
              DOWNLOAD TEMPLATE
            </Button>
          </StyledCard>
        </Grid.Item>
        <Grid.Item xs={6} sm={6} md={6} lg={6}>
          <StyledCard className="hc-pa-normal hc-bg-grey06">
            <Heading size={4} className="normal-weight border-bottom">
              Submit Template
            </Heading>

            <p className=" hc-clr-grey01 hc-mt-normal hc-mb-none">
              The completed template needs to be emailed to the following Target
              team members:
            </p>

            <div className="hc-mb-normal hc-clr-grey01">
              {emailsFromDivisions && emailsFromDivisions?.join(', ')}
            </div>

            <StyledButtons>
              <CopyToClipboard
                text={emailsFromDivisions && emailsFromDivisions?.join()}
              >
                <Button
                  className="hc-mr-expanded"
                  disabled={status !== 'UPCOMING'}
                  type="secondary"
                  variant="contained"
                >
                  COPY EMAIL TO CLIPBOARD
                </Button>
              </CopyToClipboard>

              <Button
                disabled={status !== 'UPCOMING'}
                type="primary"
                onClick={() => sendEmail()}
              >
                SEND OVER EMAIL
              </Button>
            </StyledButtons>
          </StyledCard>
        </Grid.Item>
      </Grid.Container>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a ref={downloadLink}>{''}</a>
    </div>
  )
}

export default PromotionParticipation
