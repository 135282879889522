import styled from '@emotion/styled'
import { Badge, Popover } from '@enterprise-ui/canvas-ui-react'

export const StyledPopover = styled(Popover)`
  &.C-Popover {
    display: block;
  }
  &.C-Popover__content.\--size-normal {
    width: 400px;
  }
`

export const StyledBadge = styled(Badge)`
  &.C-Badge:before {
    background: var(--canvas-background-color--card);
    color: var(--canvas-global-color--target);
  }
`

export const StyledUnreadNotification = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--canvas-global-color--target);
`
