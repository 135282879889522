import { useMemo, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Input, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'

import StoreState from 'types/state'
import { useOrdersDashboardStore } from 'v2/store'
import {
  getEnumerationValues,
  isRoleExternalUserSelector,
} from 'store/selectors'
import { getDateSubtractedByDays } from 'v2/utils/date'

import { EnumerationName } from 'types/Enumeration'
import { OrderStatus, orderStatusLabels } from 'v2/constant'
import { StyledCard, StyledDatePicker } from '../styles'

import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'

const ordersAtRiskStatuses = [
  {
    id: OrderStatus.ACKNOWLEDGED_BY_SELLER,
    value: OrderStatus.ACKNOWLEDGED_BY_SELLER,
    label: OrderStatus.ACKNOWLEDGED_BY_SELLER,
  },
  {
    id: OrderStatus.PARTIALLY_SHIPPED,
    value: OrderStatus.PARTIALLY_SHIPPED,
    label: OrderStatus.PARTIALLY_SHIPPED,
  },
]

const Filter = () => {
  const {
    deliverByDate,
    orderStatuses,
    orderPlacedDate,
    requestShipDate,
    isOrdersAtRiskChecked,
    clearFilters,
    updateDeliverByDate,
    updateOrderStatuses,
    updateRequestShipDate,
    updateOrderPlacedDate,
    updateIsOrdersAtRiskChecked,
  } = useOrdersDashboardStore()

  const statuses = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.ORDER_STATUS),
  )

  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const isOrdersAtRiskTriggered = useRef(false)
  const isInitialRender = useRef(true)

  useEffect(() => {
    isInitialRender.current = false
  }, [])

  const updateFilters = () => {
    const setOrdersAtRiskFilters = () => {
      updateOrderStatuses(ordersAtRiskStatuses)

      const today = new Date()
      const nineDaysFromNow = getDateSubtractedByDays(today, 9)
      const seventeenDaysFromNow = getDateSubtractedByDays(today, 17)
      updateRequestShipDate({
        startDate: seventeenDaysFromNow.toDateString(),
        endDate: nineDaysFromNow.toDateString(),
      })
    }

    clearFilters()
    setOrdersAtRiskFilters()
  }

  const orderStatusesOptions = useMemo(() => {
    return statuses
      .map((stat) => ({
        id: stat,
        label: orderStatusLabels[stat as keyof typeof OrderStatus],
        value: stat,
      }))
      .filter(
        (status) =>
          status.value !== OrderStatus.PENDING &&
          status.value !== OrderStatus.REFUNDED,
      )
  }, [statuses])

  const handleOrderStatusSelection = (value: any) => {
    if (isOrdersAtRiskTriggered.current) {
      isOrdersAtRiskTriggered.current = false
      return
    }

    updateOrderStatuses(value)

    if (isInitialRender.current) {
      return
    }

    if (isExternalUser) {
      const formattedOrderStatuses = value.length
        ? value.map((status: any) => status.value).join(', ')
        : 'null'

      const eventValue = `Order_Status: ${formattedOrderStatuses}`

      logFireflyEvent(
        FireflyEvent.ORDERS_DASHBOARD_STATUS_SELECTION,
        'order-status-selection',
        eventValue,
      )
    }
  }

  const handleOrdersAtRisk = (value: boolean) => {
    isOrdersAtRiskTriggered.current = true

    updateIsOrdersAtRiskChecked(value)

    if (value) {
      updateFilters()
    } else {
      clearFilters()
    }

    if (isExternalUser) {
      logFireflyEvent(
        FireflyEvent.ORDERS_DASHBOARD_ORDERS_AT_RISK_CHECKBOX_SELECTION,
        'orders-at-risk-checkbox-selection',
        value.toString(),
      )
    }

    setTimeout(() => {
      isOrdersAtRiskTriggered.current = false
    }, 100)
  }

  const logFireflyEvent = (
    event: FireflyEvent,
    key?: string,
    value?: string,
  ) => {
    trackCustomEvent(event, key ?? event.toString(), value ?? '')
  }

  return (
    <StyledCard className="hc-bg-grey07 hc-ph-normal">
      <Grid.Container direction="column" justify="space-between">
        <Grid.Item>
          <Grid.Container align="top" justify="space-between">
            <Grid.Item>
              <p className="hc-fs-md">Showing Details For :</p>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>
          <Grid.Container align="baseline" justify="space-between">
            <Grid.Item xs={3}>
              <Autocomplete
                multiselect
                label="Order Status"
                value={orderStatuses}
                placeholder="Select Order Status"
                options={orderStatusesOptions}
                onUpdate={(_, value) => handleOrderStatusSelection(value)}
                disabled={isOrdersAtRiskChecked}
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <StyledDatePicker
                id="orders-dashboard-order-placed-date-picker"
                type="gregorian-range"
                label="Order Placed Date"
                placeholder="Select Date"
                onUpdate={(_: string, value: any) => {
                  updateOrderPlacedDate(value)
                }}
                value={orderPlacedDate}
                noValidate
                disabled={isOrdersAtRiskChecked}
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <StyledDatePicker
                id="orders-dashboard-deliver-date-picker"
                type="gregorian-range"
                label="Request Ship Date"
                placeholder="Select Date"
                onUpdate={(_: string, value: any) => {
                  updateRequestShipDate(value)
                }}
                noValidate
                value={requestShipDate}
                disabled={isOrdersAtRiskChecked}
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <StyledDatePicker
                id="orders-dashboard-date-picker"
                type="gregorian-range"
                label="Deliver By"
                placeholder="Select Date"
                onUpdate={(_: string, value: any) => {
                  updateDeliverByDate(value)
                }}
                value={deliverByDate}
                noValidate
                disabled={isOrdersAtRiskChecked}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item className="hc-pt-none">
          <Grid.Container align="center">
            <Grid.Item>
              <Input.Checkbox
                className="hc-fs-xs"
                id="demo_00"
                label="Only show Orders at Risk"
                checked={isOrdersAtRiskChecked}
                onChange={(event: any) =>
                  handleOrdersAtRisk(event.target.checked)
                }
              />
            </Grid.Item>
            <Grid.Item className="hc-fs-min hc-clr-grey02">
              <Tooltip
                tabIndex="0"
                location="bottom"
                content="(This is a standalone filter. On selection of this checkbox, all other filters will be disabled.)"
              >
                <EnterpriseIcon icon={InfoIcon} />
              </Tooltip>
            </Grid.Item>

            {/* </div> */}
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </StyledCard>
  )
}

export default Filter
