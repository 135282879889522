import {
  isOneOfUserRoles,
  isUserRoleExternal,
  isUserRoleProductAdmin,
} from './authorization'
import { approvedToListStatuses } from './seller'

import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPS,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_ITEM_MANAGER,
  USER_ROLE_ITEM_APPROVER,
  USER_ROLE_PROGRAM_MANAGER,
  USER_ROLE_COMMUNICATIONS,
} from './roles'

import { Source, SellerStatus } from 'types/Seller'
import { flag, FlagName } from 'flag'
import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'

export const hasErrorConfigPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasHomePage = (memberOf: string[]) => !isUserRoleExternal(memberOf)

export const hasItemUploadPage = (
  memberOf: string[],
  source?: string,
  status?: SellerStatus,
) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_OPS,
    USER_ROLE_APP_SMS_ADMIN,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_ITEM_APPROVER,
  ]) &&
  (source === Source.DIRECT_FROM_SELLER || source === Source.COMMERCEHUB) &&
  status &&
  [...approvedToListStatuses, SellerStatus.PARTNER_SETUP].includes(status)

export const hasSalesMetricsPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf) ||
  isOneOfUserRoles(memberOf, [USER_ROLE_APP_SMS_ADMIN])

export const hasFeedbackPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN]) || isUserRoleExternal(memberOf)

export const hasReturnDisputeCasesPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasPromotions = () => {
  return flag(FlagName.PROMO_VISIBILITY)
}

export const hasItemProcessingPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_ITEM_APPROVER,
  ])

export const hasItemVersionPage = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasKafkaTopicsSearchPage = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasUtilitiesNav = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
    USER_ROLE_ITEM_MANAGER,
    USER_ROLE_COMMUNICATIONS,
    USER_ROLE_OPS,
  ])

export const hasBarcodeReleasePage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
    USER_ROLE_ITEM_MANAGER,
  ])

export const hasUnlistItemsPageAccess = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [
    USER_ROLE_OPS,
    USER_ROLE_ADMIN,
    USER_ROLE_PROGRAM_MANAGER,
  ])

export const hasPhoneEditPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasAlertManagementPage = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_COMMUNICATIONS])

export const hasTimeline = (memberOf: string[]) =>
  isUserRoleProductAdmin(memberOf)

export const hasReturnShippingRatesNav = (memberOf: string[]) =>
  isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN, USER_ROLE_OPS])

export const hasHolidayClosuresPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasOnboardingPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasShippingMarkupPage = (memberOf: string[]) =>
  flag(FlagName.BUY_SHIPPING_MARKUP) && !isUserRoleExternal(memberOf)

export const hasReturnsResearchPage = (memberOf: string[]) =>
  !isUserRoleExternal(memberOf)

export const hasReturnDashboardInternalAccess = () =>
  flag(FlagName.RETURN_INSIGHT_DASHBOARD_INTERNAL)

export const hasReturnDashboardExternalAccess = () =>
  flag(FlagName.RETURN_INSIGHT_DASHBOARD_EXTERNAL)

export const hasOrderDashboardInternalAccess = () =>
  flag(FlagName.ORDER_DASHBOARD_INTERNAL)

export const hasOrderDashboardExternalAccess = () =>
  flag(FlagName.ORDER_DASHBOARD_EXTERNAL)

export const hasPriceInsightsDashboardAccess = () =>
  flag(FlagName.PRICE_INSIGHTS)

export const hasTgidConsentPageAccess = (
  memberOf: string[],
  sellerId: string,
) => {
  if (
    apiConfig.environment === ENVIRONMENT_PRODUCTION &&
    isUserRoleExternal(memberOf) &&
    sellerId
  ) {
    // Partners for Pilot Launch
    const sellerIdsList = [
      '62e3a76e26003164ed0e4a9a', // Mark & Day
      '668c1ce1c2fdee133e3b723b', // StudioLX
      '64515851df7a01011bb47b9e', // Garvee
      '63a34f4c3804b0212e74f941', // NUTRICHEF or Deal Buys LLC
      '66d6f85cbb37b71e508d2f09', // Smart Weigh, Playbees or BetterBasics usa llc
      '6673d4d37dc8f911420e034b', // Sagebrook Home
    ]

    return flag(FlagName.TGID_CONSENT) && sellerIdsList.includes(sellerId)
  } else {
    return flag(FlagName.TGID_CONSENT)
  }
}

export const hasNotificationSettingsPageAccess = (memberOf: string[]) =>
  isUserRoleExternal(memberOf) && flag(FlagName.NOTIFICATION_SETTINGS)

export const hasTgidReviewPageAccess = (memberOf: string[]) =>
  flag(FlagName.TGID_REVIEW) && isUserRoleExternal(memberOf)
