import { FC, useMemo, useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { Placeholder, Grid } from '@enterprise-ui/canvas-ui-react'

import { TopItemTypes } from 'v2/types/topItemType'
import { isArrayEmpty, roundToTwoDecimals, toTitleCase } from 'v2/utils/helper'
import { currentSellerId, currentSellerVmmId } from 'store/selectors'
import { useReturnInsightsStore } from 'v2/store'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { sellerExecuteCardQuery } from 'v2/query'
import { StyledTable } from '../styles'
import ReturnReason from '../ReturnReason'

const TopItemTypeTable: FC = () => {
  const sellerId = useSelector(currentSellerId) ?? ''
  const vmmId = useSelector(currentSellerVmmId) ?? ''
  const [rowData, setRowData] = useState<TopItemTypes[]>()
  const {
    divisionId,
    categoryId,
    view,
    interval,
    selectedVmmId,
    updateItemTypeIds,
    updateTopItemTypeOptions,
  } = useReturnInsightsStore()
  const cardId = GREEN_FIELD_CARD_ID.TOP_ITEM_TYPE

  const { data, isError, isFetching } = useQuery(
    [
      'TOP_ITEM_TYPE',
      { divisionId, categoryId, interval, sellerId, vmmId, selectedVmmId },
    ],
    () =>
      sellerExecuteCardQuery({
        divisionId,
        categoryId,
        interval,
        sellerId,
        cardId,
        vmmId: selectedVmmId || vmmId,
      }),
  )

  useEffect(() => {
    if (data) {
      const mappedData: TopItemTypes[] = data.map(
        (item: any): TopItemTypes => ({
          itemName: toTitleCase(item.item_type ?? ''),
          itemTypeId: item.item_type_id,
          returnRate:
            typeof item.return_rate === 'number'
              ? `${roundToTwoDecimals(item.return_rate)}%`
              : '',
          benchmark:
            typeof item.benchmark === 'number'
              ? `${roundToTwoDecimals(item.benchmark)}%`
              : '',
          returnGmv:
            typeof item.return_gmv === 'number'
              ? `$${roundToTwoDecimals(item.return_gmv)?.toLocaleString()}`
              : '',
          returnReasons: {
            cellDisplay: (
              <ReturnReason
                returnReasons={item.top_three_return_reasons ?? []}
              />
            ),
          },
        }),
      )

      setRowData(mappedData)
      if (view === 'INTERNAL') {
        if (selectedVmmId) {
          updateItemTypeIds([])
          updateTopItemTypeOptions([])
        } else {
          if (!isArrayEmpty(mappedData)) {
            const firstItemType = mappedData?.[0] ?? {}
            updateItemTypeIds([firstItemType.itemTypeId])
            updateTopItemTypeOptions([
              {
                label: firstItemType.itemName,
                value: firstItemType.itemTypeId,
                id: firstItemType.itemTypeId,
              },
            ])
          } else {
            updateItemTypeIds([])
          }
        }
      }
    }
  }, [data, updateItemTypeIds, updateTopItemTypeOptions, view, selectedVmmId])

  const columnDefs = useMemo(
    () => [
      {
        field: 'itemName',
        headerName: 'Item Type',
        width: 180,
        isRowHeader: true,
      },
      {
        field: 'returnRate',
        headerName: 'Return Rate',
        width: 150,
      },
      {
        field: 'benchmark',
        headerName: (
          <span
            tabIndex={0}
            role="tooltip"
            aria-label="Threshold that defines the acceptable return rate limit for a particular item type"
          >
            Benchmark
          </span>
        ),
        width: 190,
        tooltipText:
          'Threshold that defines the acceptable return rate limit for a particular item type',
      },
      {
        field: 'returnGmv',
        headerName: 'Return GMV',
        width: 180,
      },
      {
        field: 'returnReasons',
        headerName: 'Return Reasons',
        tooltipText: 'Please hover to see the top three reasons',
        width: 150,
      },
    ],
    [],
  )

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="space-between" spacing="dense">
          {Array.from({ length: 28 }).map((_, index) => (
            <Grid.Item key={index} xs={3}>
              <Placeholder.Rect height="30px" />
            </Grid.Item>
          ))}
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <>
      {rowData?.length ? (
        <StyledTable
          height={330}
          alternateRowColor
          id="top-sku-type-table"
          cellPadding="dense"
          data={{
            columnDefs,
            rowData,
          }}
        />
      ) : (
        <div className="hc-pa-expanded hc-ta-center">
          <p>No results found!</p>
        </div>
      )}
    </>
  )
}

export default TopItemTypeTable
