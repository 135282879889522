import styled from '@emotion/styled'
import {
  Card,
  Chip,
  Divider,
  Grid,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { OrderStatus } from 'v2/constant'

export const StyledBreakdownSummaryCard = styled(Card)`
  width: 220px;
  border: 0.5px solid #0037a6;
  height: 80px;
  border-radius: 4px;
`
export const StyledTable = styled(Table)`
  &.C-TableAdvanced {
    th {
      font-weight: bold;
    }
  }
`

export const StyledDivider = styled(Divider)`
  &.C-Divider.\--horizontal .C-Divider__line {
    border-radius: 0 0 8px 8px;
    background-color: #d1ddf5;
  }
  &.C-Divider {
    z-index: unset;
  }
`

export const StyledGridContainerFilter = styled(Grid.Container)`
  background-color: #f7f7f7;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
`
export const StyledCardForGraphContainer = styled(Card)`
  &.C-Card {
    min-height: 430px;
  }
  .C-Input--type-label {
    font-size: 16px;
    font-weight: bold;
`
export const StyledCard = styled(Card)`
  &.C-Card {
    border-radius: 0;
  }
  .C-Heading {
    font-weight: 600;
    text-transform: uppercase;
  }
`

export const StyledCardWidth = styled(Card)`
  &.C-Card {
    width: 100%;
  }
`

export const StyledCardContainer = styled(Card)`
  &.C-Card {
    background-color: var(--canvas-font-color--interactive-contrast);
    border-radius: 5px;
    padding-top: 10px;
  }
`

export const StyledChip = styled(Chip)<{
  backgroundColor: string
  textColor: string
}>`
  &.C-Chip {
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ textColor }) => textColor};
    border: none;
  }
`

export const StyledDatePicker = styled(DatePicker)`
  .C-Card {
    &.C-DatePicker__Card {
      width: max-content;
      left: unset;
      right: 0;
      top: 55px;
    }
  }
  .C-GridContainer {
    flex-direction: row;
  }
`

export const chipMap: Record<
  string,
  { backgroundColor: string; textColor: string }
> = {
  [OrderStatus.SHIPPED]: {
    backgroundColor: 'var(--canvas-background-color--success)',
    textColor: 'var(--canvas-font-color--contrast-weak)',
  },
  [OrderStatus.PARTIALLY_SHIPPED]: {
    backgroundColor: 'var(--canvas-background-color--alert)',
    textColor: 'var(--canvas-font-color--default)',
  },
  [OrderStatus.RELEASED_FOR_SHIPMENT]: {
    backgroundColor: 'var(--canvas-font-color--interactive)',
    textColor: 'var(--canvas-font-color--contrast-weak)',
  },
  [OrderStatus.ACKNOWLEDGED_BY_SELLER]: {
    backgroundColor: 'var(--canvas-background-color--red)',
    textColor: 'var(--canvas-font-color--contrast-weak)',
  },
  [OrderStatus.CANCELED]: {
    backgroundColor: 'var(--canvas-font-color--secondary)',
    textColor: 'var(--canvas-font-color--contrast-weak)',
  },
}
