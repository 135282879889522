import { useEffect, useMemo, useState } from 'react'
import { Grid, Pagination } from '@enterprise-ui/canvas-ui-react'

export interface PaginationData {
  total: number
  page: number
  perPage: number
  totalPages?: number
}

interface CustomPaginationProps {
  total: number
  onPageChange: (newPage: number) => void
  perPage?: number
  currentPage?: number
  showDescription?: boolean
}

const CustomPagination = ({
  total,
  perPage = 10,
  currentPage = 1,
  showDescription = false,
  onPageChange,
}: CustomPaginationProps) => {
  const [page, setPage] = useState(currentPage)
  const [itemsCount, setItemsCount] = useState<{
    first: number
    second: number
  }>()

  useEffect(() => {
    if (total) {
      setItemsCount({
        first: 1,
        second: perPage > total ? total : perPage,
      })
    }
  }, [total, perPage])

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  const getTotalPages = useMemo(() => {
    return Math.ceil(total / perPage)
  }, [total, perPage])

  const getCountToBeUpdated = (value: number) => {
    const first = value * perPage - (perPage - 1)
    const second = value * perPage > total ? total : value * perPage

    return { first, second }
  }

  const updatePage = (value: number) => {
    setPage(value)
    onPageChange(value)
    setItemsCount(getCountToBeUpdated(value))
  }

  return (
    <Grid.Container align="center">
      {showDescription && (
        <Grid.Item>
          <p className="hc-fs-xs hc-clr-grey01 hc-ma-none">
            Showing {itemsCount?.first}-{itemsCount?.second} of {total} items
          </p>
        </Grid.Item>
      )}
      <Grid.Item>
        <Pagination
          totalPages={getTotalPages}
          currentPage={page}
          onRequestLast={() => {
            updatePage(getTotalPages)
          }}
          onRequestNext={() => {
            updatePage(page + 1)
          }}
          onRequestPrev={() => {
            updatePage(page - 1)
          }}
          onRequestFirst={() => {
            updatePage(1)
          }}
        />
      </Grid.Item>
    </Grid.Container>
  )
}

export default CustomPagination
