import { useSelector } from 'react-redux'
import apiConfig from 'config/apiConfig'
import { format } from 'date-fns'
import { Anchor, Button, Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { datePattern } from 'v2/constant/date'
import { useOrdersDashboardStore } from 'v2/store'

import ShipmentHistory from '../Filter/ShipmentHistory'
import ReportDownload, { ReportType } from '../ReportDownload'
import { isRoleExternalUserSelector } from 'store/selectors'

import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'

const CtaButtons = () => {
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const { totalOrderCount } = useOrdersDashboardStore()

  const domain = isExternalUser
    ? apiConfig.externalHostname
    : apiConfig.internalHostname
  const ordersHelpArticle = `${domain}/article/000087087`

  const today = format(new Date(), datePattern.underscore.yyyy_MM_dd_time)
  const isDisable = (totalOrderCount ?? 0) > 40000

  const handleHelpArticleClick = () => {
    if (isExternalUser) {
      logFireflyEvent(
        FireflyEvent.ORDERS_DASHBOARD_HELP_ARTICLES_BUTTON_CLICK,
        'help_article_button_click',
      )
    }
  }

  const logFireflyEvent = (event: FireflyEvent, key?: string, data?: any) => {
    trackCustomEvent(event, key ?? event.toString(), data ?? '')
  }

  return (
    <Grid.Container justify="space-between" align="center">
      <Grid.Item className="hc-pv-none ">
        <Anchor tabindex="-1" href={ordersHelpArticle} target="_blank">
          <Button type="primary" onClick={handleHelpArticleClick}>
            Help Article
          </Button>
        </Anchor>
      </Grid.Item>
      <Grid.Item className="hc-pv-none">
        <ShipmentHistory />
      </Grid.Item>

      <Grid.Item>
        <Tooltip
          location={isDisable ? 'bottom' : 'none'}
          content={
            'When record count is above 40,000, downloading is disabled as the table size is too large.'
          }
        >
          <ReportDownload
            buttonType={'ghost'}
            buttonTitle="Download All"
            isDisable={isDisable}
            orderDashboardReportType={[
              ReportType.UNSHIPPED_ORDERS_PAST_DUE_COUNT,
              ReportType.CURRENT_WEEK_SHIPPING_COUNT,
              ReportType.ORDER_SUMMARY,
            ]}
            fileName={`orders ${today}`}
            ariaLabel="Download all reports for Orders Dashboard"
          />
        </Tooltip>
      </Grid.Item>
    </Grid.Container>
  )
}

export default CtaButtons
