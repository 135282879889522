import { CSSProperties } from 'react'
import AsyncSelect from 'react-select/async'
import EnterpriseIcon, { ErrorFilledIcon } from '@enterprise-ui/icons'

import { getAttributeValuesById } from 'v2/services'

interface Option {
  label: string
  value: string
  id: string
}

const useAsyncAttributeValueSelect = () => {
  const fetchOptions = async (
    inputValue: string,
    attributeId: string,
  ): Promise<Option[]> => {
    if (!inputValue) {
      return []
    }

    const data = await getAttributeValuesById({
      attributeId,
      name: inputValue,
    })

    return data.map(({ id, name }: any) => ({
      id,
      value: name,
      label: name,
    }))
  }

  const handleInputChange = (newValue: string): string => {
    const value = newValue.replace(/\W/g, '') // remove any non-alphanumeric characters from the string
    return value
  }

  const renderComponent = ({
    placeholder = 'Search value...',
    attributeId,
    key,
    selectedOption,
    handleChange,
    erroMessage,
  }: {
    attributeId: string
    erroMessage?: string
    key: string
    selectedOption: any
    handleChange: (newValue: any) => void
    placeholder?: string
  }) => (
    <>
      <AsyncSelect
        key={key}
        cacheOptions
        loadOptions={(inputValue: string) =>
          fetchOptions(inputValue, attributeId)
        }
        onChange={handleChange}
        value={selectedOption}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        isClearable
        styles={{
          control: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            lineHeight: 1,
            borderRadius: 8,
            height: 40,
            fontSize: 'var(--canvas-font-size--sm)',
            borderColor: 'var(--canvas-border-color--input)',
          }),
          indicatorSeparator: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            display: 'none',
          }),
          clearIndicator: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            color: 'var(--canvas-icon-color--default)',
          }),
          dropdownIndicator: (baseStyles: CSSProperties) => ({
            ...baseStyles,
            color: 'var(--canvas-icon-color--default)',
          }),
        }}
      />
      {erroMessage && (
        <p className="hc-fs-xs hc-clr-error">
          <EnterpriseIcon size="inline" icon={ErrorFilledIcon} /> {erroMessage}
        </p>
      )}
    </>
  )

  return { renderComponent }
}

export default useAsyncAttributeValueSelect
