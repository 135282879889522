import { Location } from 'history'
import { OrderStatus } from 'types/Orders'

export enum RoutePath {
  // internal nav
  HOME = '/',
  AUTH_LOGIN = '/auth/login',
  BARCODE_RELEASE = '/barcode-release',
  UNLIST_ITEMS = '/unlist-items',
  NOT_AUTHORIZED = '/not-authorized',
  SELLERS = '/sellers',
  SOURCES = '/sources',
  INTERNAL_RETURN_DISPUTE_CASES = '/internal-return-dispute-cases',
  ALL_RETURN_INSIGHTS = '/all-return-insights',
  REVIEW_QUEUE = '/review-queue',
  ITEMS_PROCESSING = '/items-processing',
  ERROR_CODES = '/error-codes',
  ALL_ORDERS = '/all-orders',
  ALL_RETURNS = '/all-returns',
  PROMOTIONS = '/promotions',
  CALENDAR = '/calendar',
  GLOBAL_TERMS = '/global-terms',
  ITEM_VERSIONS = '/item-versions',
  SEARCH_TOPICS = '/search-topics',
  STRIPE_ACCOUNT_SUCCESS = '/stripe_account_success',
  SHIPPING_METHODS = '/shipping-methods',
  SHIPPING_MARKUP = '/buy-shipping-markup',
  RETURN_SHIPPING_RATES = '/return-shipping-rates',
  BASE_REFERRAL_FEES = '/base-referral-fees',
  PROTECTED_BRANDS = '/protected-brands',
  INTERNAL_REPORTS = '/internal-reports',
  DATA_MAPPING = '/data-mapping',
  GLOBAL_QUOTAS = '/global-quotas',
  INFORM_ACT_VERIFICATION = '/inform-act-verification',
  PHONE_EDIT = '/phone-edit',
  TIMELINE_UTIL = '/timeline',
  ALERT_MANAGEMENT = '/alert-management',
  HOLIDAY_CLOSURES = '/holiday-closures',
  ONBOARDING = '/onboarding',
  RETURNS_RESEARCH = '/returns-research',

  // seller navs
  DASHBOARD = '/dashboard',
  ORDERS = '/orders',
  RETURNED_ORDERS = '/returned-orders',
  ITEM_LISTING_SETTINGS = '/item-listing-settings',
  ITEM_UPLOAD = '/item-upload',
  REFERRAL_FEES = '/referral-fees',
  ALL_ITEMS = '/all-items',
  ATTRIBUTES_AND_VALUES = '/attributes-and-values',
  PARTNER_SERVICES = '/partner-services',
  PAYOUTS_AND_ACCOUNT = '/payouts-and-account',
  BASIC_INFO = '/basic-info',
  SHIPPING_INFO = '/shipping-info',
  RETURN_DISPUTE_CASES = '/return-dispute-cases',
  RETURN_POLICIES = '/return-policies',
  FORMS_AND_AGREEMENTS = '/forms-and-agreements',
  USER_ACCESS = '/user-access',
  API_TOKENS = '/api-tokens',
  PERFORMANCE = '/performance',
  SALES_METRICS = '/sales-metrics',
  RATINGS_AND_REVIEWS = '/ratings-and-reviews',
  ITEM_INSIGHTS = '/item-insights',
  RETURN_INSIGHTS = '/return-insights',
  REPORTS = '/reports',
  OPEN_CASES = '/open-cases',
  ERROR_CONFIGURATION = '/error-configuration',
  EXTERNAL_CALENDAR = '/external-calendar',
  EXTERNAL_PROMOTIONS = '/external-promotions',
  TGID_CONSENT = '/tgid-consent',
  NOTIFICATION_SETTINGS = '/notification-settings',
  TGID = '/tgid',
  PRICE_INSIGHTS = '/price-insights',

  //seller navs with relative paths
  SELLER_PROMOTION_DETAILS_RELATIVE = '/promotion-details',

  // global navs to use for absolute paths
  USER_PROFILE = '/user-profile',
  HELP = '/help',
  KNOWLEDGE_ARTICLE = '/article',
  KNOWLEDGE_SEARCH = '/search',
  KNOWLEDGE_CATEGORY = '/category',
  INFORM_ACT = '/inform-act',

  // global navs to use for relative paths
  USER_PROFILE_RELATIVE = 'user-profile',
  HELP_RELATIVE = 'help',
  KNOWLEDGE_ARTICLE_RELATIVE = 'article',
  KNOWLEDGE_SEARCH_RELATIVE = 'search',
  KNOWLEDGE_CATEGORY_RELATIVE = 'category',
  INFORM_ACT_RELATIVE = 'inform-act',
}

export enum NavGroup {
  NONE,
  BUSINESS,
  GLOBAL_TERMS,
  ITEMS,
  ITEM_REVIEW,
  PARTNER,
  PERFORMANCE,
  RETURNS,
  PLANNING,
  EXTERNAL_PLANNING,
  SUPPORT,
  UTILITIES,
  PROMOTION_DETIALS,
}

// seller nav groups
export const partnerGroup: RoutePath[] = [
  RoutePath.USER_ACCESS,
  RoutePath.API_TOKENS,
  RoutePath.ITEM_LISTING_SETTINGS,
  RoutePath.ERROR_CONFIGURATION,
  RoutePath.NOTIFICATION_SETTINGS,
  RoutePath.TGID,
]

export const businessGroup: RoutePath[] = [
  RoutePath.BASIC_INFO,
  RoutePath.TGID_CONSENT,
  RoutePath.SHIPPING_INFO,
  RoutePath.RETURN_POLICIES,
  RoutePath.FORMS_AND_AGREEMENTS,
  RoutePath.REFERRAL_FEES,
]

export const supportGroup: RoutePath[] = [
  RoutePath.OPEN_CASES,
  RoutePath.RETURN_DISPUTE_CASES,
]

export const performanceGroup: RoutePath[] = [
  RoutePath.PERFORMANCE,
  RoutePath.SALES_METRICS,
  RoutePath.RATINGS_AND_REVIEWS,
  RoutePath.ITEM_INSIGHTS,
  RoutePath.RETURN_INSIGHTS,
]
export const itemsGroup: RoutePath[] = [
  RoutePath.ALL_ITEMS,
  RoutePath.ATTRIBUTES_AND_VALUES,
]

export const externalPlanningGroup: RoutePath[] = [
  RoutePath.EXTERNAL_PROMOTIONS,
  RoutePath.EXTERNAL_CALENDAR,
]
export const promotionNavPaths: RoutePath[] = [
  RoutePath.SELLER_PROMOTION_DETAILS_RELATIVE,
]

export const sellerNavPaths: RoutePath[] = [
  RoutePath.DASHBOARD,
  RoutePath.ITEM_UPLOAD,
  RoutePath.ORDERS,
  RoutePath.RETURNED_ORDERS,
  RoutePath.REPORTS,
  RoutePath.PAYOUTS_AND_ACCOUNT,
  RoutePath.PARTNER_SERVICES,
]
  .concat(businessGroup)
  .concat(itemsGroup)
  .concat(partnerGroup)
  .concat(supportGroup)
  .concat(performanceGroup)
  .concat(externalPlanningGroup)
  .concat(promotionNavPaths)

// internal nav groups
export const itemReviewGroup: RoutePath[] = [
  RoutePath.DATA_MAPPING,
  RoutePath.ITEMS_PROCESSING,
  RoutePath.ERROR_CODES,
]

export const returnsGroup: RoutePath[] = [
  RoutePath.ALL_RETURNS,
  RoutePath.RETURNS_RESEARCH,
  RoutePath.INTERNAL_RETURN_DISPUTE_CASES,
]

export const planningGroup: RoutePath[] = [
  RoutePath.PROMOTIONS,
  RoutePath.CALENDAR,
]

export const globalTermsGroup: RoutePath[] = [
  RoutePath.SHIPPING_METHODS,
  RoutePath.SHIPPING_MARKUP,
  RoutePath.RETURN_SHIPPING_RATES,
  RoutePath.BASE_REFERRAL_FEES,
  RoutePath.PROTECTED_BRANDS,
  RoutePath.GLOBAL_QUOTAS,
  RoutePath.INFORM_ACT_VERIFICATION,
  RoutePath.HOLIDAY_CLOSURES,
]

export const utilitiesTermsGroup: RoutePath[] = [
  RoutePath.BARCODE_RELEASE,
  RoutePath.ITEM_VERSIONS,
  RoutePath.SEARCH_TOPICS,
  RoutePath.PHONE_EDIT,
  RoutePath.TIMELINE_UTIL,
  RoutePath.ALERT_MANAGEMENT,
]

export const defaultOrderFilterQuery = {
  order_status: [
    OrderStatus.ACKNOWLEDGED_BY_SELLER,
    OrderStatus.PARTIALLY_SHIPPED,
  ],
}

export function getSelectedPath(location: Location): RoutePath {
  const { pathname } = location
  const foundPath = Object.values(RoutePath).find((path) => {
    if (path !== RoutePath.HOME) {
      if (pathname.indexOf(path) > -1) {
        return path
      }
    }
    return false
  })
  return (foundPath as RoutePath) || RoutePath.HOME
}
