import { Routes, Route } from 'react-router-dom'

import { OrdersDashboard as OrdersDashboardExternal } from 'v2/pages/external'
import { OrdersDashboard as OrdersDashboardInternal } from 'v2/pages/internal'
import OrderDetails from 'components/Orders/OrderDetails'

export const Orders = ({
  isExternalView = false,
}: {
  isExternalView?: boolean
}) => {
  return (
    <Routes>
      <Route
        index
        element={
          isExternalView ? (
            <OrdersDashboardExternal />
          ) : (
            <OrdersDashboardInternal />
          )
        }
      />
      <Route path=":orderId" element={<OrderDetails />} />
    </Routes>
  )
}

export default Orders
