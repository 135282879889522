import { FC } from 'react'
import { Divider, Grid, Heading } from '@enterprise-ui/canvas-ui-react'

import Filters from './Filters'

const LandingPageHeader: FC = () => {
  return (
    <>
      <Heading size={4}>Review Your TGID Recommendations</Heading>
      <p style={{ lineHeight: '30px' }} className="hc-fs-sm">
        As part of the Target Generated Item Data (TGID) service, we utilize
        GenAI to enhance product content by generating and/or refining item
        data. To ensure accuracy and optimization, please review the
        AI-generated recommendations for your listings within the designated
        timeframe.
        <ul>
          <li>
            <strong>Review Period:</strong> You have 72 hours to assess the
            recommended updates.
          </li>
          <li>
            <strong>Action Required:</strong> You may either apply the
            recommended changes or retain the original values.
          </li>
          <li>
            <strong>Automatic Updates:</strong> If no action is taken within the
            review period, the recommended changes will be automatically applied
            to your listings.
          </li>
        </ul>
        This process helps maintain accurate, high-quality product information,
        improving visibility and performance. Please review your recommendations
        at your earliest convenience.
      </p>
      <Divider className="hc-mb-expanded" />
      <Grid.Container>
        <Grid.Item xs={12}>
          <Filters />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default LandingPageHeader
