import { useState } from 'react'
import EnterpriseIcon, { ShipIcon } from '@enterprise-ui/icons'
import { Button, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { getDateSubtractedByMonths } from 'v2/utils/date'
import { DowloadReportModal } from 'v2/components/common'
import { useOrdersDashboardStore } from 'v2/store'
import { useSelector } from 'react-redux'
import { isRoleExternalUserSelector } from 'store/selectors'
import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'

const ShipmentHistory = () => {
  const { view } = useOrdersDashboardStore()
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isDownloadReportModalOpen, setIsReportDownloadModalOpen] =
    useState(false)
  const [reportPayload, setReportPayload] = useState<any>(null)

  const openShipmentModal = () => {
    setIsConfirmationModalOpen(true)
  }

  const onDownloadClick = () => {
    // close the confirmation modal
    setIsConfirmationModalOpen(false)
    const today = new Date()
    // api call
    const payload = {
      type:
        view === 'INTERNAL' ? 'SHIPMENT_HISTORY_INTERNAL' : 'SHIPMENT_HISTORY',
      format: 'EXCEL',
      start_date: new Date(getDateSubtractedByMonths(today, 18)).toISOString(),
      end_date: today.toISOString(),
      parameters: {
        include_metadata: true,
      },
    }
    setReportPayload(payload)
    setIsReportDownloadModalOpen(true)
    if (isExternalUser) {
      logFireflyEvent(
        FireflyEvent.ORDERS_DASHBOARD_DOWNLOAD_SHIPMENT_HISTORY_CLICK,
        'shipment-history-download-click',
      )
    }
  }
  const logFireflyEvent = (
    event: FireflyEvent,
    key?: string,
    value?: string,
  ) => {
    trackCustomEvent(event, key ?? event.toString(), value ?? '')
  }

  return (
    <>
      {isConfirmationModalOpen && (
        <Modal
          isVisible={isConfirmationModalOpen}
          onRefuse={() => setIsConfirmationModalOpen(false)}
          headingText={'Download Shipment History'}
          size="dense"
        >
          <div className="hc-pa-normal">
            <Grid.Container direction="row-reverse" spacing="dense">
              <Grid.Item xs>
                <p>
                  Shipment history report contains count of order IDs shipped
                  per day over past 18 months.
                </p>
                <p>Do you wish to download the data?</p>
              </Grid.Item>
            </Grid.Container>
            <Grid.Container direction="row-reverse" spacing="dense">
              <Grid.Item>
                <Button
                  onClick={onDownloadClick}
                  type="primary"
                  aria-label="Download shipment history report"
                >
                  Download
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  onClick={() => setIsConfirmationModalOpen(false)}
                  type="secondary"
                >
                  Cancel
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
      <Button
        type="secondary"
        onClick={openShipmentModal}
        aria-label="Shipment History"
      >
        <EnterpriseIcon className="hc-pr-min" icon={ShipIcon} />
        Shipment History
      </Button>

      <DowloadReportModal
        isModalOpen={isDownloadReportModalOpen}
        setIsModalOpen={setIsReportDownloadModalOpen}
        reportRequestPayload={reportPayload}
        fileName={'order-shipment-history'}
      />
    </>
  )
}

export default ShipmentHistory
