import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { Role } from 'types/SellerUser'

interface Params {
  sellerId?: string
  userId: string
  role?: Role
  responsibility?: number[]
  primaryRole?: string
  selectedChannelPartner?: string
}

export const updateSellerRoleResponsibility = async ({
  sellerId,
  userId,
  role,
  responsibility,
  primaryRole,
  selectedChannelPartner,
}: Params) => {
  const data = {
    role_ids: [role],
    functional_responsibilities: responsibility,
    ...(primaryRole !== '' && {
      primary_seller_id:
        primaryRole === 'CP' ? selectedChannelPartner : primaryRole,
    }),
  }
  try {
    const response = await axios.put(
      `${apiConfig.sellerUsers}/sellers/${sellerId}/seller_users/${userId}`,
      data,
    )

    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
