import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'

interface Params {
  page?: number
  perPage?: number
  attributeId?: string
  name?: string
}

export const getAttributeValuesById = async (params: Params) => {
  const { name, perPage = 25, attributeId, page = 1 } = params

  const queryParams = buildURLQueryParams({
    name,
    page,
    attribute_id: attributeId,
    per_page: perPage,
  })

  try {
    const { data } = await axios.get(
      `${apiConfig.nexus}/attribute_values?${queryParams}`,
    )

    return data
  } catch (error) {
    return Promise.reject([])
  }
}
