import axios from 'axios'
import apiConfig from 'config/apiConfig'

interface Params {
  sellerId: string
  reviewStatus: string
  productIds: string[]
}

export const updateBulkProduct = async (params: Params) => {
  try {
    const payload = {
      review_status: params.reviewStatus,
      product_ids: params.productIds,
    }

    const response = await axios.put(
      `${apiConfig.sms}/sellers/${params.sellerId}/product_enhancements`,
      payload,
    )

    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
