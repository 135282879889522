import styled from '@emotion/styled'
import {
  Button,
  Chip,
  Drawer,
  Grid,
  Table,
} from '@enterprise-ui/canvas-ui-react'

export const StyledClearAllButton = styled(Button)`
  &.C-Button {
    height: 100%;
  }
  &.C-Button.\--type-ghost:hover {
    background: transparent;
    text-decoration: underline;
  }
`

export const StyledHourChip = styled(Chip)`
  &.C-Chip__children {
    font-size: 14px;
  }
  &.C-Chip.\--clickable {
    border-color: ${(props: { is24Hours: boolean }) =>
      props.is24Hours
        ? 'var(--canvas-border-color--error)'
        : 'var(--canvas-border-color--alert)'};
    color: ${(props: { is24Hours: boolean }) =>
      props.is24Hours
        ? 'var(--canvas-border-color--error)'
        : 'var(--canvas-border-color--alert)'};
  }
`

export const StyledTime = styled(Chip)<{ time: number }>`
  border-radius: var(--canvas-border-radius--min);

  ${({ time }) => {
    let color, background

    if (time <= 24) {
      color = 'var(--canvas-border-color--error)'
      background = 'var(--canvas-background-color--error-contrast-weak)'
    } else if (time <= 48) {
      color = 'var(--canvas-border-color--alert)'
      background = 'var(--canvas-background-color--highlight)'
    } else {
      color = 'var(--canvas-border-color--input)'
      background = 'transparent'
    }

    return `
      &.C-Chip {
        color: ${color};
        border: none;
	      background: ${background};
      }`
  }}
`

export const StyledText = styled.p`
  border-radius: var(--canvas-border-radius--min);
  height: 32px;
`

export const StyledTabs = styled(Grid.Item)`
  .C-Divider.\--horizontal {
    display: none;
  }
`

export const StyledSubmitButton = styled(Button)`
  &.C-Button.\--type-secondary {
    color: var(--canvas-icon-color--success);
    border-color: var(--canvas-icon-color--success);
  }

  &.C-Button.\--type-secondary:hover {
    color: var(--canvas-font-color--contrast-weak);
    border-color: var(--canvas-icon-color--success);
    background: var(--canvas-icon-color--success);
  }
`

export const StyledTable = styled(Table)`
  .C-TableAdvanced__headerCell {
    font-weight: bold;
  }
  .C-TableAdvanced__bodyCell.--showGrid:not(:last-child) {
    border-right: none;
    font-size: var(--canvas-font-size--sm);
  }
`

export const StyledDrawer = styled(Drawer)`
  &.C-Drawer {
    overflow: scroll;
  }
`
