import axios from 'axios'

import apiConfig from 'config/apiConfig'
import { PagingParams, getPageable } from './pageableHelper'
import { Promotion, PromotionsReport, UpdatedPromotion } from 'types/Promotion'
import { CollectionResponse } from 'types/Response'
import { Report } from 'types/Report'
import { saveFile } from './files'
import { url } from 'v2/utils'

type PromotionsSearchParams = {
  divisionId?: string
  pyramidId?: string
  promotionId?: string
  promotionStatus?: string
  effectiveDate?: string
  sellerId?: string
  upstreamStatus?: string
  reviewMilestone?: string
  offerChannel?: string
}

interface ReportType {
  fileInput: File | null
}

export const getPromotions = async (
  pagingParams: PagingParams,
  searchParams: PromotionsSearchParams,
): Promise<CollectionResponse<UpdatedPromotion>> => {
  const pageable = pagingParams ? getPageable(pagingParams) : {}

  const division = searchParams.divisionId
    ? parseInt(searchParams.divisionId)
    : undefined

  const pyramid = searchParams.pyramidId
    ? parseInt(searchParams.pyramidId)
    : undefined

  let config = {
    params: {
      ...pageable,
    },
  }

  if (division) {
    Object.assign(config.params, { division_id: division })
  }

  if (pyramid) {
    Object.assign(config.params, { pyramid_id: pyramid })
  }

  if (searchParams.promotionId) {
    Object.assign(config.params, { promotion_id: searchParams.promotionId })
  }

  if (searchParams.promotionStatus) {
    Object.assign(config.params, { status: searchParams.promotionStatus })
  }

  if (searchParams.effectiveDate) {
    Object.assign(config.params, { effective_date: searchParams.effectiveDate })
  }

  if (searchParams.upstreamStatus) {
    Object.assign(config.params, {
      upstream_status: searchParams.upstreamStatus,
    })
  }

  if (searchParams.reviewMilestone) {
    Object.assign(config.params, {
      review_milestone: searchParams.reviewMilestone,
    })
  }

  if (searchParams.offerChannel) {
    Object.assign(config.params, {
      offer_channel: searchParams.offerChannel,
    })
  }

  try {
    let res
    if (searchParams.sellerId) {
      const { seller_id: _sellerId, ...newParams } = config.params as {
        seller_id: string
      }
      const newConfig = {
        ...config,
        params: newParams,
      }

      res = await axios.get(
        `${apiConfig.sellerPromotions}/sellers/${searchParams.sellerId}/promotions`,
        newConfig,
      )
    } else {
      res = await axios.get(`${apiConfig.sellerPromotions}/promotions`, config)
    }

    return {
      data: res.data,
      total: parseInt(res.headers['x-total-count'] ?? '0', 10),
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getPromotionById = async (
  promotionId: string,
): Promise<Promotion[]> => {
  let config = {
    params: {
      promotion_id: promotionId,
    },
  }

  try {
    const res = axios
      .get(`${apiConfig.sellerPromotions}/promotions`, config)
      .then((res) => {
        return res.data
      })

    return res
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updatePromotion = async (
  promotion: UpdatedPromotion,
): Promise<Promotion> => {
  try {
    const res = axios
      .put(
        `${apiConfig.sellerPromotions}/promotions/${promotion.id}`,
        promotion,
      )
      .then((res) => {
        return res.data
      })
    return res
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deletePromotion = async (
  promotion: Promotion,
): Promise<Promotion> => {
  try {
    const res = axios
      .delete(`${apiConfig.sellerPromotions}/promotions/${promotion.id}`)
      .then((res) => res.data)
    return res
  } catch (error) {
    return Promise.reject(error)
  }
}
export const addPromotion = async (payload: any) => {
  try {
    const res = axios
      .post(`${apiConfig.sellerPromotions}/promotions`, payload)
      .then((res) => res.data)
    return res
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function submitReport(request: ReportType): Promise<Report> {
  const url = `${apiConfig.sellerPromotions}/promotions/bulk`

  const postData = new FormData()

  if (request.fileInput) {
    postData.append(
      'attachment',
      new Blob([request.fileInput]),
      request.fileInput.name,
    )
  }

  try {
    const { data } = await axios.post(url, postData)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function pollReport(
  bulkRequestId: string,
): Promise<PromotionsReport> {
  const url = `${apiConfig.sellerPromotions}/promotions/bulk/${bulkRequestId}`

  try {
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function downloadReport(downloadUrl: string, queryParams?: any) {
  try {
    const { data } = await axios.get(downloadUrl, {
      headers: {
        Accept: 'application/octet-stream',
      },
      responseType: 'blob',
      params: queryParams,
    })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function saveReportAsFile({
  report,
}: {
  report: PromotionsReport
}) {
  if (report) {
    const data = await downloadReport(
      `${apiConfig.sellerPromotions}/promotions/bulk/${report.bulk_request_id}/result`,
    )
    saveFile({
      data,
      name: report.file_name,
      type: 'application/octet-stream;charset=utf-8',
    })
  }
}

export const getPromotionsBulkRequests = async (
  pagingParams: PagingParams,
): Promise<CollectionResponse<PromotionsReport[]>> => {
  const pageable = pagingParams ? getPageable(pagingParams) : {}

  let config = {
    params: {
      ...pageable,
    },
  }

  try {
    const res = await axios.get(
      `${apiConfig.sellerPromotions}/promotions/bulk`,
      config,
    )

    const total = res.headers['x-total-count']
      ? parseInt(res.headers['x-total-count'], 10)
      : 0

    return {
      total,
      data: res.data,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const downloadPromotions = async (
  pagingParams: PagingParams,
  searchParams: PromotionsSearchParams,
) => {
  const pageable = getPageable(pagingParams)

  const division = searchParams.divisionId
    ? parseInt(searchParams.divisionId)
    : undefined

  const pyramid = searchParams.pyramidId
    ? parseInt(searchParams.pyramidId)
    : undefined

  const queryParams = url.buildURLQueryParams({
    division_id: division,
    pyramid_id: pyramid,
    promotion_id: searchParams.promotionId,
    status: searchParams.promotionStatus,
    effective_date: searchParams.effectiveDate,
    upstream_status: searchParams.upstreamStatus,
    review_milestone: searchParams.reviewMilestone,
    offer_channel: searchParams.offerChannel,
    page: pageable.page,
    per_page: pageable.per_page,
    sort: pageable.sort,
  })

  let downloadUrl

  if (searchParams.sellerId) {
    downloadUrl = `${apiConfig.sellerPromotions}/sellers/${searchParams.sellerId}/promotions/download?${queryParams}`
  } else {
    downloadUrl = `${apiConfig.sellerPromotions}/promotions/download?${queryParams}`
  }

  try {
    const { data } = await axios.get(downloadUrl, {
      headers: {
        Accept: 'application/octet-stream',
      },
      responseType: 'blob',
    })

    saveFile({
      data,
      name: 'Promotions.xlsx',
      type: 'application/octet-stream;charset=utf-8',
    })
  } catch (error) {
    return Promise.reject(error)
  }
}
