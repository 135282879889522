import { OrderStatus } from './orderStatus'

export const orderStatusLabels = {
  [OrderStatus.PENDING]: 'Pending',
  [OrderStatus.RELEASED_FOR_SHIPMENT]: 'Released',
  [OrderStatus.ACKNOWLEDGED_BY_SELLER]: 'Unshipped',
  [OrderStatus.PARTIALLY_SHIPPED]: 'Partially Shipped',
  [OrderStatus.SHIPPED]: 'Shipped',
  [OrderStatus.CANCELED]: 'Cancelled',
  [OrderStatus.REFUNDED]: 'Refunded',
}
