import { useState, useEffect, memo } from 'react'
import { Heading } from '@enterprise-ui/canvas-ui-react'
import TableSpacer from 'components/common/TableSpacer'
import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'

import useInterval from 'hooks/useInterval'
import { PromotionsReport } from 'types/Promotion'
import GenerationBar from './GenerationBar'
import DownloadReportButton from './DownloadReportButton'
import { getPromotionsBulkRequests, pollReport } from 'services/promotions'
import { ReportStatus } from 'types/Report'
import {
  getEnhancedTablePageableProps,
  TableState,
  useSearchParams,
} from 'components/common/FilterBar/useSearchParams'
import { Direction } from 'services/pageableHelper'
import { showNotification } from 'store/notification/reducer'
import { use3pPromotionsStore } from 'v2/store'
import { StyledMarginAdjuster } from '../styles'

const initialSearchParams: TableState = {
  perPage: 5,
  page: 0,
  direction: Direction.DESC,
  orderBy: 'last_modified',
}

export const ReportsTable = () => {
  const [reports, setReports] = useState<PromotionsReport[]>([])
  const [inProgressReport, setInProgressReport] = useState<PromotionsReport>()
  const [isLoading, setIsLoading] = useState(false)
  const { isNewFileUpload, updateIsNewFileUpload } = use3pPromotionsStore()
  const [total, setTotal] = useState<number>()

  const [searchParams, searchParamActions] =
    useSearchParams<TableState>(initialSearchParams)

  const fieldList: EnhancedTableFieldType<PromotionsReport>[] = [
    {
      key: 'type',
      heading: 'File Name',
      minWidth: 20,
      formatCell: (report: PromotionsReport) => {
        return <p className="hc-mb-none">{report.file_name}</p>
      },
    },
    {
      key: 'created_by',
      heading: 'Last modified by (internal)',
      minWidth: 40,
    },
    {
      key: 'last_modified',
      heading: 'Last modified',
      minWidth: 40,
      formatCell: (report: PromotionsReport) => {
        return <GenerationBar report={report} />
      },
      hasSort: true,
    },
    {
      key: 'download_url',
      heading: 'Action',
      minWidth: 20,
      formatCell: (report: PromotionsReport) => {
        return (
          <DownloadReportButton
            text="Download"
            report={report}
            isDisabled={report.status === ReportStatus.PROCESSING}
            title="Download Report"
            hasDateRange={false}
          />
        )
      },
    },
  ]

  useInterval(
    () => {
      if (!inProgressReport) return

      pollReport(inProgressReport.bulk_request_id).then(async (report) => {
        if (report.status === ReportStatus.PROCESSING) {
          return
        }

        setInProgressReport(undefined)
      })
    },
    inProgressReport ? 5000 : null,
  )

  useEffect(() => {
    if (!inProgressReport) {
      setIsLoading(true)
      getPromotionsBulkRequests({
        page: searchParams.page,
        perPage: searchParams.perPage,
        direction: searchParams.direction,
        orderBy: searchParams.orderBy,
      })
        .then((response: any) => {
          setIsLoading(false)
          setReports(response.data)
          setTotal(response.total)
        })
        .catch((error) => {
          showNotification({
            isShown: true,
            message: error?.response?.data
              ? error?.response?.data?.errors[0]
              : error?.messaage,
            severity: 'error',
            autoClose: true,
          })
        })
    }
  }, [inProgressReport, searchParams])

  useEffect(() => {
    if (isNewFileUpload) {
      setIsLoading(true)
      getPromotionsBulkRequests({
        page: searchParams.page,
        perPage: searchParams.perPage,
        direction: searchParams.direction,
        orderBy: searchParams.orderBy,
      })
        .then((response: any) => {
          setIsLoading(false)
          setReports(response.data)
          updateIsNewFileUpload(false)
          const inProgress = response.data.find(
            (report: PromotionsReport) =>
              report.status === ReportStatus.PENDING ||
              report.status === ReportStatus.PROCESSING,
          )
          setInProgressReport(inProgress)
          setTotal(response.total)
        })
        .catch((error) => {
          showNotification({
            isShown: true,
            message: error?.response?.data
              ? error?.response?.data?.errors[0]
              : error?.messaage,
            severity: 'error',
            autoClose: true,
          })
        })
        .finally(() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewFileUpload, searchParams])

  return (
    <StyledMarginAdjuster>
      <Heading
        size={4}
        className="hc-fs-xl hc-mt-expanded normal-weight border-bottom"
      >
        3P Promotions Bulk Upload History
      </Heading>
      <p className="hc-mb-none hc-mt-expanded hc-clr-grey01">
        This section allows the download of summary files for promotions created
        using bulk 3P promotions utility in the marketplace portal. Large file
        uploads may need upto an hour for processing and the files generated
        will be available for a period of 6 months (180 days) from the date of
        upload.
      </p>

      <TableSpacer>
        <EnhancedTable
          fieldList={fieldList}
          data={reports}
          isLoading={isLoading}
          isUpdatedTable={true}
          total={total}
          {...getEnhancedTablePageableProps(searchParams, searchParamActions)}
        />
      </TableSpacer>
    </StyledMarginAdjuster>
  )
}

export default memo(ReportsTable)
