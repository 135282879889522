import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'

import { OrderData } from 'v2/types/orderData'
import { OrderStatus } from 'v2/constant'

interface SellerOrderParams {
  page: number
  perPage: number
  orderStatus: OrderStatus[]
  orderDate?: string
  requestedShipmentDate?: string
  requestedDeliveryDate?: string
  sort?: string
}

export const getSellerOrders = async (
  params: SellerOrderParams,
  sellerId: string,
): Promise<{ data: OrderData[]; total?: string }> => {
  const {
    page,
    sort,
    perPage,
    orderDate,
    orderStatus,
    requestedDeliveryDate,
    requestedShipmentDate,
  } = params

  const queryParams = buildURLQueryParams({
    sort: sort,
    order_status: `${orderStatus}`,
    order_date: orderDate,
    requested_shipment_date: requestedShipmentDate,
    requested_delivery_date: requestedDeliveryDate,
    page: page,
    per_page: perPage,
  })

  const url = `${apiConfig.sellerOrders}/sellers/${sellerId}/orders?${queryParams}`
  const response = await axios.get(url)

  return {
    data: response?.data ?? [],
    total: response?.headers?.['x-total-count'],
  }
}
