import { FC, useState } from 'react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { Dropdown, Button } from '@enterprise-ui/canvas-ui-react'

import { DowloadReportModal } from 'v2/components/common'
import { useReturnInsightsStore } from 'v2/store'
import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'
import { useSelector } from 'react-redux'
import { isRoleExternalUserSelector } from 'store/selectors'

const Download: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reportPayload, setReportPayload] = useState<any>(null)
  const [optionType, setOptionType] = useState('')
  const {
    divisionId,
    categoryId,
    itemTypeIds,
    timeFrame,
    view,
    selectedSellerId,
    interval,
    returnRateGraphView,
  } = useReturnInsightsStore()
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  const onDownloadOptionClick = (isCurrentView: boolean) => () => {
    const payload = {
      type:
        view === 'INTERNAL'
          ? 'RETURN_PERFORMANCE_INTERNAL'
          : 'RETURN_PERFORMANCE',
      format: 'EXCEL',
      start_date: new Date(timeFrame.startDate).toISOString(),
      end_date: new Date(timeFrame.endDate).toISOString(),
      ...(selectedSellerId && { seller_id: selectedSellerId }),
      parameters: {
        include_metadata: true,
        is_current_view: isCurrentView,
        return_performance_report_types:
          view === 'INTERNAL'
            ? [
                returnRateGraphView,
                'TOP_ITEM_TYPES',
                'TOP_SKU_TYPES_VC_VIEW',
                'PARTNER_LEVEL_RETURN_RATE',
                'CATEGORY_RETURN_RATE',
              ]
            : [returnRateGraphView, 'TOP_ITEM_TYPES', 'TOP_SKU_TYPES_VC_VIEW'],
        ...(divisionId && { division_id: divisionId }),
        ...(categoryId && { category_id: categoryId }),
        ...(itemTypeIds.length && { item_type_id: itemTypeIds }),
      },
    }
    setReportPayload(payload)
    setOptionType(isCurrentView ? 'CURRENT_VIEW' : 'ALL_DATA')
    setIsModalOpen(true)
    if (isExternalUser) {
      logFireflyEvent(
        FireflyEvent.RETURN_INSIGHTS_DOWNLOAD_OVERALL_CLICK,
        'return_insight_download_report',
        `${
          isCurrentView ? 'CURRENT_VIEW' : 'ALL_DATA'
        }, ${categoryId} ,${divisionId}, [${itemTypeIds}], ${interval}`,
      )
    }
  }
  const logFireflyEvent = (event: FireflyEvent, key?: string, data?: any) => {
    trackCustomEvent(event, key ?? event.toString(), data ?? '')
  }
  return (
    <>
      <Dropdown location="bottom-right" size="dense">
        <Button size="expanded" type="ghost">
          DOWNLOAD
          <EnterpriseIcon icon={CaretDownIcon} size="md" />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem onClick={onDownloadOptionClick(true)}>
            For the current view
          </Dropdown.MenuItem>
          <Dropdown.MenuItem onClick={onDownloadOptionClick(false)}>
            For all data
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
      <DowloadReportModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reportRequestPayload={reportPayload}
        fileName={
          optionType === 'CURRENT_VIEW'
            ? 'return-insights-current-view'
            : 'return-insights-all-data'
        }
      />
    </>
  )
}

export default Download
