import { useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  ButtonProps,
  Divider,
  Grid,
  Heading,
} from '@enterprise-ui/canvas-ui-react'

import { useOrdersDashboardStore } from 'v2/store'
import ReportDownload, { ReportType } from '../ReportDownload'
import UnshippedPastDueGraphByPartner from './UnshippedPastDueGraphByPartner'
import UnshippedPastDueGraphByAge from './UnshippedPastDueGraphByAge'
import { StyledCardForGraphContainer } from '../styles'

export enum UnshippedPastDueFilter {
  PARTNER,
  AGE,
}

const UnshippedPastDue = () => {
  const { view } = useOrdersDashboardStore()
  const [selectedFilter, setSelectedFilter] =
    useState<UnshippedPastDueFilter | null>(null)

  useEffect(() => {
    setSelectedFilter(
      view === 'INTERNAL'
        ? UnshippedPastDueFilter.PARTNER
        : UnshippedPastDueFilter.AGE,
    )
  }, [view])

  const getBtnType = (type: UnshippedPastDueFilter): ButtonProps['type'] => {
    if (selectedFilter === type) return 'primary'
    return 'secondary'
  }
  return (
    <StyledCardForGraphContainer className="hc-pa-normal">
      <Grid.Container justify="space-between" align="top">
        <Grid.Item className="hc-pl-md hc-pr-md">
          <Heading className="hc-fs-md">Unshipped Past Due</Heading>
          <p className="font-color-secondary hc-fs-xs">
            Count of order IDs with items crossing Requested Ship Date
          </p>
        </Grid.Item>

        <Grid.Item>
          <Grid.Container direction="row" align="top" justify="flex-end">
            {view === 'INTERNAL' && (
              <Grid.Item>
                <ButtonGroup>
                  <Button
                    aria-label="Unshipped past due by partner"
                    onClick={() =>
                      setSelectedFilter(UnshippedPastDueFilter.PARTNER)
                    }
                    className="hc-fs-xs"
                    type={getBtnType(UnshippedPastDueFilter.PARTNER)}
                  >
                    Partners
                  </Button>
                  <Button
                    aria-label="Unshipped past due by age"
                    onClick={() =>
                      setSelectedFilter(UnshippedPastDueFilter.AGE)
                    }
                    className="hc-fs-xs"
                    type={getBtnType(UnshippedPastDueFilter.AGE)}
                  >
                    Age
                  </Button>
                </ButtonGroup>
              </Grid.Item>
            )}

            <Grid.Item>
              <ReportDownload
                orderDashboardReportType={[
                  ReportType.UNSHIPPED_ORDERS_PAST_DUE_COUNT,
                ]}
                fileName="unshipped-order-count-rate"
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>

      <Divider className="hc-mt-xs" />
      <Grid.Container justify="center">
        {selectedFilter === UnshippedPastDueFilter.PARTNER ? (
          <Grid.Item xs={12}>
            <UnshippedPastDueGraphByPartner />
          </Grid.Item>
        ) : (
          <Grid.Item xs={10}>
            <UnshippedPastDueGraphByAge />
          </Grid.Item>
        )}
      </Grid.Container>
    </StyledCardForGraphContainer>
  )
}

export default UnshippedPastDue
