import { useSelector } from 'react-redux'
import has from 'lodash/fp/has'

import Tooltip from '@mui/material/Tooltip'
import SyncIcon from '@mui/icons-material/Sync'

import EnhancedTable, {
  EnhancedTableFieldType,
} from 'components/common/EnhancedTable'
import { TableActionItem } from 'components/common/EnhancedTable/EnhancedTableActionButtonCell'
import TableSpacer from 'components/common/TableSpacer'

import { PHONE_TYPE_MOBILE, PHONE_TYPE_OFFICE } from 'constants/phones'

import { getResponsibilityLabel } from 'services/functionalResponsibilities'

import { isOneOfUserRoles } from 'services/authorization'
import {
  USER_ROLE_ADMIN,
  USER_ROLE_APP_SMS_ADMIN,
  USER_ROLE_OPS,
} from 'services/roles'

import { getMemberOf } from 'store/selectors'

import SellerUser, { PhoneNumber } from 'types/SellerUser'
import useTable from 'components/common/EnhancedTable/useTable'
import { getTableRows } from 'services/pageableHelper'
import { FlagName, flag } from 'flag'

const getAllResponsibilities = (user: SellerUser) => {
  const functionalResponsibilities = isIDMIntegration
    ? user.functional_responsibilities
    : user.vmm_metadata?.[0]?.functional_responsibilities

  const responsibilities = functionalResponsibilities?.map(
    (responsiblityCode) => getResponsibilityLabel(responsiblityCode),
  )

  if (responsibilities && responsibilities.length) {
    return responsibilities?.join(', ')
  } else {
    return 'None Provided'
  }
}

const fetchPhoneNumber = (numbers: PhoneNumber[] = [], type: string) => {
  const number = numbers.find((number) => number.type === type)

  if (!number) return 'None Provided'

  const formattedNumber = `+${number.country_code} ${number.number}`
  const extension = number.extension_number
    ? `Ext: ${number.extension_number}`
    : ''
  return `${formattedNumber} ${extension}`.trim()
}

const isIDMIntegration = flag(FlagName.SMS_IDM_INTEGRATION)

const fieldList: EnhancedTableFieldType<SellerUser>[] = [
  {
    key: '', // VMM sync column
    heading: '',
    formatCell: ({ vmm_metadata, idm_sync_status }) => {
      const isSyncing = isIDMIntegration
        ? idm_sync_status === 'IN_PROGRESS'
        : !has('seller_user_vmm_id', vmm_metadata?.[0])
      return isSyncing ? (
        <Tooltip
          data-testid="syncing-icon"
          title="This account is in the process of syncing. It cannot be logged into at this time."
        >
          <SyncIcon />
        </Tooltip>
      ) : null
    },
  },
  {
    key: 'name',
    formatCell: (item) => `${item.first_name} ${item.last_name}`,
  },
  {
    key: 'email',
  },
  {
    key: 'role',
    heading: 'Portal Access',
    formatCell: ({ entitlements, role_types }) =>
      (isIDMIntegration ? role_types?.[0] : entitlements?.[0].role) ??
      'None (Contact)',
  },
  {
    key: 'office',
    heading: 'Office Number',
    formatCell: ({ phone_numbers }) =>
      fetchPhoneNumber(phone_numbers, PHONE_TYPE_OFFICE),
  },
  {
    key: 'mobile',
    heading: 'Mobile Number for Two-Factor Authentication',
    formatCell: ({ phone_numbers }) =>
      fetchPhoneNumber(phone_numbers, PHONE_TYPE_MOBILE),
  },
  {
    key: 'functional_responsibilities',
    heading: 'Responsibilities',
    formatCell: (item) => getAllResponsibilities(item),
  },
]

export interface Props {
  users: SellerUser[]
  isPending: boolean
  isDefunct: boolean
  onDelete: (user: SellerUser) => void
  onEdit: (user: SellerUser) => void
}

export const UserTable = ({
  users,
  isPending,
  isDefunct,
  onDelete,
  onEdit,
}: Props) => {
  const memberOf = useSelector(getMemberOf)
  const { table } = useTable({ perPage: 100, page: 0 })
  const rows = getTableRows(users, table.state.page, table.state.perPage)

  const handleDelete = (element: SellerUser) => () => {
    onDelete(element)
  }

  const handleEdit = (element: SellerUser) => () => {
    onEdit(element)
  }

  const showActions =
    isDefunct === false &&
    isOneOfUserRoles(memberOf, [
      USER_ROLE_APP_SMS_ADMIN,
      USER_ROLE_OPS,
      USER_ROLE_ADMIN,
    ])

  const tableActions = [
    {
      callback: handleEdit,
      label: 'Manage Details',
      icon: <div />,
      labelCallback: (item) => `Manage Details for ${item?.first_name}`,
      hideAction: (element: SellerUser) => {
        return element.idm_sync_status === 'IN_PROGRESS'
      },
    },
    {
      callback: handleDelete,
      label: 'Remove from Partner',
      icon: <div />,
    },
  ] as TableActionItem[]

  return (
    <TableSpacer>
      <EnhancedTable
        fieldList={fieldList}
        data={rows}
        total={users.length}
        isLoading={isPending}
        actions={showActions ? tableActions : []}
        page={table.state.page}
        rowsPerPage={table.state.perPage}
        onChangePage={table.actions.changePage}
        onChangeRowsPerPage={table?.actions.changePerPage}
      />
    </TableSpacer>
  )
}

export default UserTable
