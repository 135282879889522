import { useState, useMemo } from 'react'
import {
  Button,
  Divider,
  Grid,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilesIcon } from '@enterprise-ui/icons'
import { useDispatch } from 'react-redux'

import { ProductType, ReviewItemListsType } from 'v2/store/useTGIDStore'
import { useTGIDStore } from 'v2/store'
import Header from './Header'
import { BulkChildSubmission } from './BulkChildSubmission'
import DetailsTable from './DetailsTable'
import { getProductEnhancements, getProductsByProductId } from 'v2/services'
import { putSellerProduct } from 'v2/services/putSellerProduct'
import { getSellerProduct } from 'v2/services/getSellerProduct'
import { hideBanner } from 'store/notification/reducer'
import { StyledDrawer, StyledText } from '../styles'
import { postProductVariationUpdate } from 'v2/services/postProductVariationUpdate'

export interface DataType {
  primary_image_url: string
  tcin: string
  itemType: string
  reviewCreateDate: string
  hoursRemaining: string
}

interface Props {
  isDetailsDrawerOpen: boolean
  handleClose: () => void
}

export const DetailsDrawer = ({ isDetailsDrawerOpen, handleClose }: Props) => {
  const {
    itemType,
    resetDetailsView,
    updateToNextItem,
    itemListPagination,
    reviewItemLists,
    selectedItem,
    selectedItemTypeId,
    reviewDateRange,
    timeRemainingSort,
    updateReviewItemList,
    updateItemDetails,
    updateSelectedItem,
    detailsView,
    selectedVCTcins,
    updateSelectedVCTcins,
  } = useTGIDStore()
  const makeToast = useToaster()
  const dispatch = useDispatch()

  const [paginationPageCount, setPaginationPageCount] = useState<number>(
    itemListPagination.page,
  )
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isRecommendedDisabled, setIsRecommendedDisabled] =
    useState<boolean>(true)

  const handleReset = () => {
    setIsRecommendedDisabled(true)
    resetDetailsView()
  }
  const hasSubmitDisabled = () => {
    return detailsView.some(
      (item) => item.attribute.required && !item.recommended.value,
    )
  }

  const loadNextItem = async (isSubmit: boolean) => {
    if (itemType === ProductType.VARIANT_CHILD) {
      if (selectedVCTcins.length === 1) {
        handleClose()
      } else {
        const filteredTcins = selectedVCTcins.filter(
          (tcin: string) => tcin !== selectedItem.tcin,
        )
        updateSelectedVCTcins(filteredTcins)
        updateSelectedItem(filteredTcins[0])
      }
    } else {
      const currentIndex = reviewItemLists?.findIndex(
        (reviewItem: ReviewItemListsType) =>
          reviewItem?.tcin === selectedItem?.tcin,
      )

      if (currentIndex === reviewItemLists.length - 1) {
        try {
          isSubmit ? setIsSubmitting(true) : setIsFetching(true)

          const itemsData = await getProductEnhancements(
            selectedItem.sellerId,
            {
              page: paginationPageCount + 1,
              perPage: 10,
              relationshipType: 'SA',
              itemTypeId: selectedItemTypeId ?? undefined,
              reviewDateRange: reviewDateRange,
              sort: timeRemainingSort,
            },
          )

          if (itemsData.data) {
            const mappedData = itemsData?.data?.map(
              ({
                tcin,
                fields,
                id,
                review_end_time,
                seller_id,
                created,
              }: any) => {
                return {
                  tcin,
                  fields,
                  reviewCreateDate: created,
                  reviewEndTime: review_end_time,
                  productId: id,
                  sellerId: seller_id,
                }
              },
            )

            updateReviewItemList(mappedData)

            const productIds = mappedData.map(
              (item: { productId: any }) => item.productId,
            )

            if (productIds.length) {
              const promises = productIds.map((productId: any) => {
                return getProductsByProductId({ productId })
              })
              Promise.all(promises)
                .then((data) => {
                  const mappedItemData = data.map(({ results }) => {
                    const {
                      id,
                      primary_image,
                      title,
                      item_type_name,
                      item_type_id,
                      parent_tcin,
                    } = results?.[0] ?? {}

                    return {
                      title,
                      productId: id,
                      image: primary_image,
                      itemTypeName: item_type_name,
                      itemTypeId: item_type_id,
                      parentTcin: parent_tcin,
                    }
                  })
                  updateItemDetails(mappedItemData)
                  updateSelectedItem(mappedData[0]?.tcin)
                  setPaginationPageCount(paginationPageCount + 1)
                })
                .finally(() => {
                  isSubmit ? setIsSubmitting(false) : setIsFetching(false)
                })
            }
          }
        } catch {
          handleClose()
          makeToast({
            type: 'error',
            message: 'An error occurred. Please try again.',
          })
        }
      } else updateToNextItem()
    }
  }

  const isLastItem = useMemo(() => {
    // If the total number of items is less than the current page count * 10, then it is the last item
    if (paginationPageCount * 10 < itemListPagination.total) {
      return false
    }
    return selectedItem.tcin === reviewItemLists?.at(-1)?.tcin
  }, [
    itemListPagination.total,
    paginationPageCount,
    reviewItemLists,
    selectedItem.tcin,
  ])

  const handleFieldsUpdate = (fields: any) => {
    const updatedFields = detailsView.reduce((acc, data) => {
      if (
        data.attribute.required ||
        (data.recommended.value && data.original.value)
      ) {
        const foundIndex = acc.findIndex(
          (val: any) => val.name === data.recommended.name,
        )
        acc[foundIndex] = data.recommended
        return acc
      } else {
        return data.recommended.value ? [...acc, data.recommended] : acc
      }
    }, fields)

    return updatedFields
  }

  const handleStandaloneSubmit = async (isSubmit: boolean) => {
    const itemDetails = await getSellerProduct(selectedItem.sellerId, {
      expand: 'fields',
      tcin: selectedItem.tcin,
    })

    const { fields, ...rest } = itemDetails?.[0] ?? {}

    const payload = [
      {
        ...rest,
        fields: handleFieldsUpdate(fields),
      },
    ]

    putSellerProduct({
      sellerId: selectedItem.sellerId,
      productId: selectedItem.productId,
      payload,
    })
      .then((response) => {
        if (response) {
          setIsSubmitting(false)
          makeToast({
            type: 'success',
            heading: 'Submit Successfully',
            message: 'Item submitted successfully.',
          })

          isLastItem ? handleClose() : loadNextItem(isSubmit)
        }
      })
      .catch((error) => {
        setIsSubmitting(false)
        dispatch(hideBanner())
        makeToast({
          type: 'error',
          message: error?.response?.data
            ? error?.response.data?.errors[0]
            : error?.messaage,
        })
        return
      })
  }

  const handleVCSubmit = async (isSubmit: boolean) => {
    const itemDetails = await getSellerProduct(selectedItem.sellerId, {
      expand: 'fields',
      tcin: [selectedItem.parentTcin, selectedItem.tcin].join(','),
    })
    const [
      parentProductDetails,
      { fields, external_id, relationship_type } = {},
    ] = itemDetails ?? []

    const payload = {
      children: [
        {
          external_id,
          fields: handleFieldsUpdate(fields),
          relationship_type,
        },
      ],
      parent: {
        external_id: parentProductDetails?.external_id,
        fields: parentProductDetails?.fields,
        relationship_type: parentProductDetails?.relationship_type,
      },
    }
    postProductVariationUpdate({
      sellerId: selectedItem.sellerId,
      payload,
    })
      .then((response) => {
        if (response) {
          setIsSubmitting(false)
          makeToast({
            type: 'success',
            heading: 'Submit Successfully',
            message: 'Item submitted successfully.',
          })

          loadNextItem(isSubmit)
        }
      })
      .catch((error) => {
        setIsSubmitting(false)
        dispatch(hideBanner())
        makeToast({
          type: 'error',
          message: error?.response?.data
            ? error?.response.data?.errors[0]
            : error?.messaage,
        })
        return
      })
  }

  const handleNextItem = async (isSubmit: boolean) => {
    if (isSubmit) {
      setIsSubmitting(true)
      if (itemType === ProductType.STANDALONE) {
        handleStandaloneSubmit(isSubmit)
      } else {
        handleVCSubmit(isSubmit)
      }
    } else {
      loadNextItem(isSubmit)
    }
  }

  const isVC = itemType === ProductType.VARIANT_CHILD

  return (
    <StyledDrawer
      isVisible={isDetailsDrawerOpen}
      onRequestClose={handleClose}
      headingText="Review Target's recommendation changes and edit your item attributes as
      required"
      xs={10}
      spacing="dense"
    >
      <div className="hc-pa-sm">
        <Header />
        {isVC && (
          <>
            <BulkChildSubmission handleClose={handleClose} />
            <Divider />
          </>
        )}
        <Grid.Container justify="space-between" className="hc-mv-xs">
          <Grid.Item xs={9}>
            <Grid.Container>
              <Grid.Item className="hc-ta-center">
                <StyledText className="hc-fs-sm hc-ph-dense hc-pt-min hc-clr-error background-color-error-contrast-weak">
                  Fields marked with '*' are required
                </StyledText>
              </Grid.Item>
              <Grid.Item>
                <StyledText className="hc-fs-sm hc-ph-dense hc-pt-min background-color-interactive-contrast-weak-hover">
                  <EnterpriseIcon color="darkBlue" size="sm" icon={FilesIcon} />{' '}
                  Copy original value to replace generated values
                </StyledText>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item>
            <Button
              type="primary"
              onClick={handleReset}
              disabled={isRecommendedDisabled}
            >
              Reset to recommended values
            </Button>
          </Grid.Item>
        </Grid.Container>
        <DetailsTable setIsRecommendedDisabled={setIsRecommendedDisabled} />
      </div>
      <Grid.Container className="hc-pr-sm hc-pt-sm" justify="flex-end">
        <Grid.Item>
          <Button
            onClick={() => handleNextItem(false)}
            disabled={isLastItem}
            type="secondary"
            isLoading={isFetching}
          >
            {isVC ? 'Skip VC' : 'Skip TCIN'}
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            type="primary"
            disabled={hasSubmitDisabled()}
            onClick={() => handleNextItem(true)}
            isLoading={isSubmitting}
          >
            {isLastItem
              ? `Submit ${isVC ? 'VC' : ''}`
              : `Submit & Next ${isVC ? 'VC' : ''}`}
          </Button>
        </Grid.Item>
      </Grid.Container>
    </StyledDrawer>
  )
}

export default DetailsDrawer
