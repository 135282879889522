// Make sure you add new events to the firefly events confluence page: https://confluence.target.com/display/TPLUSPROD/Firefly+Events
export enum FireflyEvent {
  ADDRESS_VERIFICATION_FAIL = 'ADDRESS_VERIFICATION_FAIL',
  COMPARE_RANGE_SELECTED = 'COMPARE_RANGE_SELECTED',
  DATE_RANGE_SELECTED = 'DATE_RANGE_SELECTED',
  DOCEBO_BUTTON = 'DOCEBO_BUTTON',
  EVENTS_CALENDAR = 'EVENTS_CALENDAR',
  EVENTS_CALENDAR_DETAIL = 'EVENTS_CALENDAR_DETAIL',
  EVENTS_LIST = 'EVENTS_LIST',
  FILTER_APPLIED = 'FILTER_APPLIED',
  HELP_ARTICLE_SEARCH_CLICK = 'HELP_ARTICLE_SEARCH_CLICK',
  HELP_ARTCILE_CATEGORY_CLICK = 'HELP_ARTCILE_CATEGORY_CLICK',
  HELP_ARTICLE_VISIT = 'HELP_ARTICLE_VISIT',
  HELP_BUTTON = 'HELP_BUTTON',
  HELP_SEARCH = 'HELP_SEARCH',
  HELP_WAS_THIS_ARTICLE_HELPFUL = 'HELP_WAS_THIS_ARTICLE_HELPFUL',
  ITEM_FETCHED = 'ITEM_FETCHED',
  ITEM_REJECTED = 'ITEM_REJECTED',
  ITEM_APPROVED_WITH_ERRORS = 'ITEM_APPROVED_WITH_ERRORS',
  ITEM_APPROVED_WITHOUT_ERRORS = 'ITEM_APPROVED_WITHOUT_ERRORS',
  ITEM_INSIGHT = 'ITEM_INSIGHT',
  KB_ARTICLE_CASE_SUBMIT = 'KB_ARTICLE_CASE_SUBMIT',
  ODR_DETAIL_VIEW = 'ODR_DETAIL_VIEW',
  ODR_USER_ACTION = 'ODR_USER_ACTION',
  ODR_HOMEPAGE_NAV = 'ODR_HOMEPAGE_NAV',
  ODR_OVERRIDE_FAIL = 'ODR_OVERRIDE_FAIL',
  OMNI_SEARCH = 'OMNI_SEARCH',
  PAGE_LOAD = 'pageload',
  PAGE_SEARCH = 'PAGE_SEARCH',
  PARTNER_SERVICE_ABOUT = 'PARTNER_SERVICE_ABOUT',
  PARTNER_SERVICE_CONTACT = 'PARTNER_SERVICE_CONTACT',
  PARTNER_SERVICE_LINK = 'PARTNER_SERVICE_LINK',
  PROMOTIONS_HELPCENTER = 'PROMOTIONS_HELPCENTER',
  PROMOTIONS_PAGEVIEWS = 'PROMOTIONS_PAGEVIEWS',
  PROMOTIONS_PARTNER_DOWNLOAD = 'PROMOTIONS_PARTNER_DOWNLOAD',
  PROMOTIONS_SELLER_PAGE = 'PROMO EVENT - EXTRA',
  RELEASE_BARCODE_SEARCH = 'RELEASE_BARCODE_SEARCH',
  RELEASE_BARCODE_SUSPEND = 'RELEASE_BARCODE_SUSPEND',
  RELEASE_BARCODE_RELEASE = 'RELEASE_BARCODE_RELEASE',
  REPORT_DOWNLOAD = 'REPORT_DOWNLOAD',
  REPORT_GENERATE = 'REPORT_GENERATE',
  RETURN_DISPUTE_JUDGEMENT = 'RETURN_DISPUTE_JUDGEMENT',
  SET_SELLER_STATUS_ERROR = 'SET_SELLER_STATUS_ERROR',
  SURVEY_FEEDBACK = 'SURVEY_FEEDBACK',
  TAB_SELECT = 'TAB_SELECT',
  ITEM_REVIEW_INTERACTION_TIME = 'ITEM_REVIEW_INTERACTION_TIME',
  PARTNER_RATINGS_AND_REVIEW_DOWNLOAD_REPORT = 'PARTNER_RATINGS_AND_REVIEW_DOWNLOAD_REPORT',
  VIEW_VALUES_BUTTON = 'VIEW_VALUES_BUTTON',
  QUICK_CHAT_BUTTON = 'QUICK_CHAT_BUTTON',
  QUICK_CHAT_QUESTION_SUBMIT = 'QUICK_CHAT_QUESTION_SUBMIT',
  QUICK_CHAT_RESPONSE = 'QUICK_CHAT_RESPONSE',
  QUICK_CHAT_HYPERLINK = 'QUICK_CHAT_HYPERLINK',
  RETURN_INSIGHTS_CATEGORY_SELECTION = 'RETURN_INSIGHTS_CATEGORY_SELECTION',
  RETURN_INSIGHTS_DIVISION_SELECTION = 'RETURN_INSIGHTS_DIVISION_SELECTION',
  RETURN_INSIGHTS_ITEM_TYPE_SELECTION = 'RETURN_INSIGHTS_ITEM_TYPE_SELECTION',
  RETURN_INSIGHTS_ADDITIONAL_COLUMN_SELECTION = 'RETURN_INSIGHTS_ADDITIONAL_COLUMN_SELECTION',
  RETURN_INSIGHTS_HELP_ARTICLES_BUTTON_CLICK = 'RETURN_INSIGHTS_HELP_ARTICLES_BUTTON_CLICK',
  RETURN_INSIGHTS_RETURN_RATE_TIME_AXIS_SELECTION = 'RETURN_INSIGHTS_RETURN_RATE_TIME_AXIS_SELECTION',
  RETURN_INSIGHTS_COMPARE_BUTTON_CLICK = 'RETURN_INSIGHTS_COMPARE_BUTTON_CLICK',
  RETURN_INSIGHTS_COMPARE_APPLY_BUTTON_CLICK = 'RETURN_INSIGHTS_COMPARE_APPLY_BUTTON_CLICK',
  RETURN_INSIGHTS_HELP_ARTICLES_CLICK = 'RETURN_INSIGHTS_HELP_ARTICLES_CLICK',
  RETURN_INSIGHTS_DOWNLOAD_REPORTS_CLICK = 'RETURN_INSIGHTS_DOWNLOAD_REPORTS_CLICK',
  RETURN_INSIGHTS_DOWNLOAD_OVERALL_CLICK = 'RETURN_INSIGHTS_DOWNLOAD_OVERALL_CLICK',
  ORDERS_DASHBOARD_DOWNLOAD_OVERALL_CLICK = 'ORDERS_DASHBOARD_DOWNLOAD_OVERALL_CLICK',
  ORDERS_DASHBOARD_DOWNLOAD_UNSHIPPED_PAST_DUE_CLICK = 'ORDERS_DASHBOARD_DOWNLOAD_UNSHIPPED_PAST_DUE_CLICK',
  ORDERS_DASHBOARD_DOWNLOAD_ORDERS_TO_BE_SHIPPED_CLICK = 'ORDERS_DASHBOARD_DOWNLOAD_ORDERS_TO_BE_SHIPPED_CLICK',
  ORDERS_DASHBOARD_DOWNLOAD_ORDER_SUMMARY_CLICK = 'ORDERS_DASHBOARD_DOWNLOAD_ORDER_SUMMARY_CLICK',
  ORDERS_DASHBOARD_DOWNLOAD_SHIPMENT_HISTORY_CLICK = 'ORDERS_DASHBOARD_DOWNLOAD_SHIPMENT_HISTORY_CLICK',
  ORDERS_DASHBOARD_HELP_ARTICLES_BUTTON_CLICK = 'ORDERS_DASHBOARD_HELP_ARTICLES_BUTTON_CLICK',
  ORDERS_DASHBOARD_STATUS_SELECTION = 'ORDERS_DASHBOARD_STATUS_SELECTION',
  ORDERS_DASHBOARD_ORDERS_AT_RISK_CHECKBOX_SELECTION = 'ORDERS_DASHBOARD_ORDERS_AT_RISK_CHECKBOX_SELECTION',
  ORDERS_DASHBOARD_ORDER_ID_CLICK = 'ORDERS_DASHBOARD_ORDER_ID_CLICK',
}
// Make sure you update https://confluence.target.com/display/TPLUSPROD/Firefly+Events with your new events

export type FireflyEventInfo = {
  type: FireflyEvent
  key: string
  value: string | number
}

export enum FireflyTask {
  RELEASE_BARCODE = 'Release Barcode',
}

export enum FireflyTaskStatus {
  TASK_UNSTARTED = 'TASK_UNSTARTED',
  TASK_STARTED = 'TASK_STARTED',
  TASK_COMPLETE = 'TASK_COMPLETE',
}
