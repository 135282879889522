import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, ButtonProps } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'
import * as dateFns from 'date-fns'

import { currentSellerId, isRoleExternalUserSelector } from 'store/selectors'
import { useOrdersDashboardStore } from 'v2/store'
import {
  endOfDay,
  startOfDay,
  getCurrentDateWithStartAndEndTime,
} from 'v2/utils/date'
import { isArrayEmpty } from 'v2/utils/helper'

import { TimeFrame } from 'v2/types/common'
import { DowloadReportModal } from 'v2/components/common'
import { OrderStatus } from 'v2/constant'
import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'

export enum ReportType {
  ORDER_SUMMARY = 'ORDER_SUMMARY',
  UNSHIPPED_ORDERS_PAST_DUE_COUNT = 'UNSHIPPED_ORDERS_PAST_DUE_COUNT',
  CURRENT_WEEK_SHIPPING_COUNT = 'CURRENT_WEEK_SHIPPING_COUNT',
}
interface ReportDownloadProps {
  fileName: string
  orderDashboardReportType: ReportType[]
  buttonType?: ButtonProps['type']
  buttonTitle?: string
  ariaLabel?: string
  isDisable?: boolean
}

const ReportDownload: FC<ReportDownloadProps> = ({
  fileName,
  orderDashboardReportType,
  buttonType = 'secondary',
  buttonTitle = '',
  ariaLabel,
  isDisable = false,
}) => {
  const sellerId = useSelector(currentSellerId)
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reportPayload, setReportPayload] = useState<any>(null)
  const {
    view,
    orderPlacedDate,
    requestShipDate,
    deliverByDate,
    orderStatuses,
    selectedSellerId,
    orderPlacedDateInterval,
    deliverByDateInterval,
    requestShipDateInterval,
  } = useOrdersDashboardStore()

  const onDownloadClick = () => {
    const payload = preparePayload()
    setReportPayload(payload)
    setIsModalOpen(true)

    if (!isExternalUser) return

    const formattedOrderStatuses = orderStatuses.length
      ? orderStatuses.map((status) => status.value).join(', ')
      : 'null'

    const formattedOrderPlacedDate = formatDateRange(orderPlacedDateInterval)
    const formattedDeliverByDate = formatDateRange(deliverByDateInterval)
    const formattedRequestShipDate = formatDateRange(requestShipDateInterval)

    const eventValue = `Order_Status: ${formattedOrderStatuses} | Order_Placed: ${formattedOrderPlacedDate} | Request_Ship_Date: ${formattedRequestShipDate} | Deliver_By: ${formattedDeliverByDate}`

    const reportEventMap: Record<ReportType, FireflyEvent> = {
      [ReportType.UNSHIPPED_ORDERS_PAST_DUE_COUNT]:
        FireflyEvent.ORDERS_DASHBOARD_DOWNLOAD_UNSHIPPED_PAST_DUE_CLICK,
      [ReportType.CURRENT_WEEK_SHIPPING_COUNT]:
        FireflyEvent.ORDERS_DASHBOARD_DOWNLOAD_ORDERS_TO_BE_SHIPPED_CLICK,
      [ReportType.ORDER_SUMMARY]:
        FireflyEvent.ORDERS_DASHBOARD_DOWNLOAD_ORDER_SUMMARY_CLICK,
    }

    if (
      orderDashboardReportType.length === Object.keys(reportEventMap).length
    ) {
      logFireflyEvent(
        FireflyEvent.ORDERS_DASHBOARD_DOWNLOAD_OVERALL_CLICK,
        'orders-dashboard-download-overall',
        eventValue,
      )
    } else {
      orderDashboardReportType.forEach((reportType) => {
        const event = reportEventMap[reportType]
        if (event) {
          logFireflyEvent(
            event,
            `orders-dashboard-download-${reportType.toLowerCase()}`,
            eventValue,
          )
        }
      })
    }
  }

  const formatDateRange = (interval?: string | null) => {
    if (!interval) return 'null'
    return interval.replace('T18:30:00.000Z', '').replace('T18:29:59.999Z', '')
  }

  const logFireflyEvent = (
    event: FireflyEvent,
    key?: string,
    value?: string,
  ) => {
    trackCustomEvent(event, key ?? event.toString(), value ?? '')
  }

  const preparePayload = () => {
    let payload = {
      type: view === 'INTERNAL' ? 'ORDERS_INTERNAL' : 'ORDERS',
      format: 'EXCEL',
      parameters: {
        report_type: orderDashboardReportType,
        include_metadata: true,
      },
    }
    const currentSelectedSellerId = sellerId ?? selectedSellerId
    const orders = isArrayEmpty(orderStatuses)
      ? Object.keys(OrderStatus).filter(
          (key) => key !== OrderStatus.PENDING && key !== OrderStatus.REFUNDED,
        )
      : orderStatuses.map((value) => value.value)
    payload = {
      ...payload,
      ...validateDates('start_date', 'end_date', orderPlacedDate),
      parameters: {
        ...payload.parameters,
        ...(currentSelectedSellerId && { seller_id: currentSelectedSellerId }),
        ...validateDates(
          'requested_shipment_date_start',
          'requested_shipment_date_end',
          requestShipDate,
        ),
        ...validateDates(
          'requested_delivery_date_start',
          'requested_delivery_date_end',
          deliverByDate,
        ),

        ...(orderStatuses && {
          order_status: orders,
        }),
        ...{
          unshipped_date_start: getCurrentDateWithStartAndEndTime(true),
          unshipped_date_end: getCurrentDateWithStartAndEndTime(false, true),
        },
      },
    }
    return payload
  }

  // Validate and add start and end dates
  const validateDates = (
    startKey: string,
    endKey: string,
    date?: TimeFrame | null,
  ) => {
    if (!orderPlacedDate) return {}

    const start = date?.startDate
    const end = date?.endDate

    if (start && end) {
      if (dateFns.isValid(new Date(start)) && dateFns.isValid(new Date(end))) {
        return {
          [startKey]: startOfDay(new Date(date?.startDate), true),
          [endKey]: endOfDay(new Date(date?.endDate!), true),
        }
      }
    }

    return {}
  }

  return (
    <>
      <Button
        className="hc-pl-xs hc-pr-xs"
        type={buttonType}
        onClick={onDownloadClick}
        aria-label={ariaLabel ?? `Download reports for ${fileName}`}
        iconOnly={buttonTitle ? false : true}
        disabled={isDisable}
      >
        <EnterpriseIcon
          className={buttonTitle ? 'hc-pr-min' : ''}
          size="md"
          icon={DownloadIcon}
        />
        {buttonTitle}
      </Button>

      <DowloadReportModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reportRequestPayload={reportPayload}
        fileName={fileName}
      />
    </>
  )
}

export default ReportDownload
