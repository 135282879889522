import { Grid, Image } from '@enterprise-ui/canvas-ui-react'

import { useTGIDStore } from 'v2/store'
import { ProductType } from 'v2/store/useTGIDStore'

export const ItemInformation = () => {
  const { selectedItem, itemType, itemDetails } = useTGIDStore()
  const { image, tcin, itemTypeName } = selectedItem ?? {}

  const matchingTcin = itemDetails.find(
    (item) => item.productId === selectedItem.productId,
  )

  const isVC = itemType === ProductType.VARIANT_CHILD

  return (
    <Grid.Container>
      <Grid.Item sm={12} lg={7}>
        <Grid.Container spacing="expanded">
          <Grid.Item xs={3}>
            <Image src={image} alt="Product image" />
          </Grid.Item>
          <Grid.Item xs={9}>
            <Grid.Container direction="column" spacing="dense">
              <Grid.Item xs={8}>
                <strong>
                  <Grid.Container spacing="none">
                    <Grid.Item xs={6}>
                      <p className="hc-fs-sm">{isVC ? 'VAP TCIN:' : 'TCIN:'}</p>
                    </Grid.Item>
                    <Grid.Item xs={6}>
                      <p className="hc-fs-sm">
                        {isVC ? matchingTcin?.parentTcin : tcin}
                      </p>
                    </Grid.Item>
                  </Grid.Container>
                </strong>
              </Grid.Item>
              {isVC && (
                <Grid.Item xs={8}>
                  <strong>
                    <Grid.Container spacing="none">
                      <Grid.Item xs={6}>
                        <p className="hc-fs-sm">VC TCIN:</p>
                      </Grid.Item>
                      <Grid.Item xs={6}>
                        <p className="hc-fs-sm">{tcin}</p>
                      </Grid.Item>
                    </Grid.Container>
                  </strong>
                </Grid.Item>
              )}
              <Grid.Item xs={8}>
                <strong>
                  <Grid.Container spacing="none">
                    <Grid.Item xs={6}>
                      <p className="hc-fs-sm">Item Type:</p>
                    </Grid.Item>
                    <Grid.Item xs={6}>
                      <p className="hc-fs-sm">{itemTypeName}</p>
                    </Grid.Item>
                  </Grid.Container>
                </strong>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default ItemInformation
