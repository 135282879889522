import axios from 'axios'
import apiConfig from 'config/apiConfig'

export interface ChannelPartnerType {
  id: string
  partner_org_type: string
}

export const getChannelPartners = async () => {
  try {
    const res = await axios.get(`${apiConfig.sms}/shell_accounts/ACTIVE`)
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}
