import { FC, useEffect } from 'react'
import { Chip, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import EnterpriseIcon, { CheckIcon } from '@enterprise-ui/icons'

import { useTGIDStore } from 'v2/store'
import { TimeRange } from 'v2/store/useTGIDStore'
import { useConsentItemTypeAutoComplete } from 'v2/hooks/autoComplete'
import { StyledClearAllButton, StyledHourChip } from '../../styles'

const Filters: FC = () => {
  const { timeRemaining, updateTimeRemaining, updateSelectedItemTypeId } =
    useTGIDStore()

  const {
    selectedConsentItemTypeOption,
    consentItemTypeOptions,
    onConsentItemTypeUpdate,
  } = useConsentItemTypeAutoComplete()

  useEffect(() => {
    updateSelectedItemTypeId(selectedConsentItemTypeOption?.id ?? null)
  }, [updateSelectedItemTypeId, selectedConsentItemTypeOption?.id])

  const handleTimeRangeClick = (range: TimeRange) => {
    if (timeRemaining === range) {
      updateTimeRemaining(TimeRange.All)
    } else {
      updateTimeRemaining(range)
    }
  }

  const hanldeClearAllClick = () => {
    updateTimeRemaining(TimeRange.All)
    onConsentItemTypeUpdate('', null)
  }

  return (
    <Grid.Container>
      <Grid.Item className="hc-mr-expanded">
        <Heading size={6}>Time Remaining</Heading>
        <Grid.Container>
          <Grid.Item>
            <StyledHourChip
              is24Hours={true}
              clickable
              onClick={() => handleTimeRangeClick(TimeRange.LessThan24Hrs)}
            >
              {timeRemaining === TimeRange.LessThan24Hrs && (
                <EnterpriseIcon icon={CheckIcon} />
              )}
              {TimeRange.LessThan24Hrs}
            </StyledHourChip>
          </Grid.Item>
          <Grid.Item className="hc-ph-min">
            <StyledHourChip
              clickable
              onClick={() => handleTimeRangeClick(TimeRange.Between24And48Hrs)}
            >
              {timeRemaining === TimeRange.Between24And48Hrs && (
                <EnterpriseIcon icon={CheckIcon} />
              )}
              {TimeRange.Between24And48Hrs}
            </StyledHourChip>
          </Grid.Item>
          <Grid.Item className="hc-ph-min">
            <Chip
              clickable
              onClick={() => handleTimeRangeClick(TimeRange.Between48And72Hrs)}
            >
              {timeRemaining === TimeRange.Between48And72Hrs && (
                <EnterpriseIcon icon={CheckIcon} />
              )}
              {TimeRange.Between48And72Hrs}
            </Chip>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={3}>
        <Heading size={6}>Item Type</Heading>
        <Autocomplete
          value={selectedConsentItemTypeOption}
          placeholder="Select Item Type"
          options={consentItemTypeOptions}
          onUpdate={(id, value) => onConsentItemTypeUpdate(id, value)}
        />
      </Grid.Item>
      <Grid.Item xs={2} className="hc-pt-lg">
        <StyledClearAllButton onClick={hanldeClearAllClick} type="ghost">
          Clear All
        </StyledClearAllButton>
      </Grid.Item>
    </Grid.Container>
  )
}

export default Filters
