import { ApiConfig } from 'types/ApiConfig'
import getAuthConfig from './authConfig'

export const ENVIRONMENT_DEVELOPMENT = 'DEVELOPMENT'
export const ENVIRONMENT_STAGE = 'STAGE'
export const ENVIRONMENT_PRODUCTION = 'PRODUCTION'

export const ALL_ENVIRONMENTS = [
  ENVIRONMENT_DEVELOPMENT,
  ENVIRONMENT_STAGE,
  ENVIRONMENT_PRODUCTION,
]

export const INTERNAL_STAGE_HOSTNAME = 'marketplaceportal.dev.target.com'
export const INTERNAL_PROD_HOSTNAME = 'marketplaceportal.prod.target.com'
export const EXTERNAL_STAGE_HOSTNAME = 'plus-portal.perf.target.com'
export const EXTERNAL_PROD_HOSTNAME = 'plus-portal.target.com'

// External users should not need these TAP domains.
const EXTERNAL_STAGE_TAP_HOSTNAME =
  'marketplaceportalexternal.dev.cnf.gcp.target.com'
const EXTERNAL_PROD_TAP_HOSTNAME =
  'marketplaceportalexternal.prod.cnf.gcp.target.com'

const mockServer = process.env.MOCKYEAH === 'on' ? 'http://localhost:4777/' : ''

const externalStageGateway = `${mockServer}https://stage-api.target.com`
const internalStageGateway = `${mockServer}https://stgapi-internal.target.com`
const externalProdGateway = `${mockServer}https://api.target.com`
const internalProdGateway = `${mockServer}https://api-internal.target.com`

const securityGatewayStageBaseUrl = 'https://plus-gateway.perf.target.com'
const securityGatewayProdBaseUrl = 'https://plus-gateway.target.com'

const bulkApiStageBaseUrl = `wss://smsbulkapi.dev.target.com`
const bulkApiProdBaseUrl = `wss://smsbulkapi.prod.target.com`

const sellerShippingStageBaseUrl = 'https://sellershippingapi.dev.target.com'
const sellerShippingProdBaseUrl = 'https://sellershippingapi.prod.target.com'

const sellerShippingManagementStageBaseUrl =
  'https://sellershippingmanagementapi.dev.target.com'
const sellerShippingManagementProdBaseUrl =
  'https://sellershippingmanagementapi.prod.target.com'

const sellerProductsStageBaseUrl = 'https://sellerproductsapi.dev.target.com'
const sellerProductsProdBaseUrl = 'https://sellerproductsapi.prod.target.com'

const sellerReturnsStageBaseUrl = 'https://sellerreturnsapi.dev.target.com'
const sellerReturnsProdBaseUrl = 'https://sellerreturnsapi.prod.target.com'

const chatBotWebSocketStageBaseUrl = 'wss://stage-api.target.com'
const chatBotWebSocketProdBaseUrl = 'wss://api.target.com'

const beetleStageUrl = 'https://beetleplus.dev.target.com'
const beetleProdUrl = 'https://beetleplus.prod.target.com'
const heliosStageTopic = 'fa15818e7bf34924b2ac976b833d1275'
const heliosProdTopic = '81c44bba362e4bda95a3d64fdd633301'
const smsProdTopic = '20d1a77ca8a745fdba3dbd14634dc871'
const smsStageTopic = '878c63a1e8e8418984ac88e57d5a385d'

const stripeAuthUrl = `${mockServer}https://connect.stripe.com/express/oauth/authorize`

const brands = '/targetplus_brands/v1'
const businessPartnerBrands = '/business_partner_brands/v2'
const firefly = '/consumers/v1/ingest/internal/internal_app'
const itemAttributesSearches = '/item_attributes_searches/v1'
const marketplaceProducts = '/marketplace_products/v1'
const marketplaceVendors = '/marketplace_vendors/v1'
const itemOverrides = '/item_overrides/v1'
const nexus = '/item_taxonomies/v2'
const sms = '/sellers/v1'
const sellerUsers = '/seller_user_auths/v1'
const sellerOrders = '/seller_orders/v1'
const sellerShipping = '/seller_shipping/v1'
const biReporting = '/bi_reporting_assets/v3'
const geographicalCodes = '/geographical_codes/v1'
const securityGateway = '/sec_gateway'
const sellerBulkOps = '/sellers_bulk_operations/v1'
const notifications = '/seller_notifications/v1'
const barcodeValiations = '/barcode_validations/v1'
const helios = '/target_plus_items/v1'
const promotions = '/seller_promotions/v1'
const locations = '/locations/v3/public'
const itemGroups = '/item_groups/v1'
const sellerProducts = '/seller_products/v1'
const sellerReturns = '/seller_returns/v1'
const sellerShippingManagement = '/seller_shipping_managements/v1'
const chatBotWebsocket = '/marketplace_bot_chats/v1/messages'
const sellerShippingsLabels = '/seller_shippings/v1/sellers/<SELLER_ID>/labels'
const sellerReports = '/seller_reports/v1/execute_cards'

declare global {
  interface Window {
    REACT_APP_ENV: string | undefined
  }
}

// mo vs portal auth configurations
const hostname: string = window?.location?.hostname ?? ''

export const isExternalUrl: boolean =
  process.env.REACT_APP_NAME === 'external' ||
  [
    EXTERNAL_STAGE_TAP_HOSTNAME,
    EXTERNAL_PROD_TAP_HOSTNAME,
    EXTERNAL_STAGE_HOSTNAME,
    EXTERNAL_PROD_HOSTNAME,
  ].includes(hostname)

const stageGateway = isExternalUrl ? externalStageGateway : internalStageGateway

const prodGateway = isExternalUrl ? externalProdGateway : internalProdGateway

const stageApiGateWay = '99ff70ddf485e4eec5e1bb5fc3c1855d86161c25'
const prodApiGateWay = 'f84694eac26c36659e61b1ae79c5ef52aa424b68'

const authConfig = getAuthConfig(isExternalUrl, mockServer)

const commonConfig = {
  context: { isExternalUrl },
  stripeAuthUrl,
}

const dev: ApiConfig = {
  ...commonConfig,
  externalHostname: `https://${EXTERNAL_STAGE_HOSTNAME}`,
  internalHostname: `https://${INTERNAL_STAGE_HOSTNAME}`,
  environment: ENVIRONMENT_DEVELOPMENT,
  apiGatewayKey: stageApiGateWay,
  auth: authConfig.dev,
  barcodeValidation: `${stageGateway}${barcodeValiations}`,
  sms: `${stageGateway}${sms}`,
  sellerUsers: `${stageGateway}${sellerUsers}`,
  sellerOrders: `${stageGateway}${sellerOrders}`,
  sellerShipping: `${sellerShippingStageBaseUrl}${sellerShipping}`,
  nexus: `${stageGateway}${nexus}`,
  marketplaceVendors: `${stageGateway}${marketplaceVendors}`,
  marketplaceProducts: `${stageGateway}${marketplaceProducts}`,
  brands: `${stageGateway}${brands}`,
  businessPartnerBrands: `${stageGateway}${businessPartnerBrands}`,
  itemAttributesSearches: `${stageGateway}${itemAttributesSearches}`,
  itemOverrides: `${stageGateway}${itemOverrides}`,
  helios: `${stageGateway}${helios}`,
  firefly: `${stageGateway}${firefly}`,
  biReporting: `${stageGateway}${biReporting}`,
  securityGateway: `${securityGatewayStageBaseUrl}${securityGateway}`,
  geographicalCodes: `${stageGateway}${geographicalCodes}`,
  sellerBulkOps: `${bulkApiStageBaseUrl}${sellerBulkOps}`,
  beetle: beetleStageUrl,
  heliosTopic: heliosStageTopic,
  smsTopic: smsStageTopic,
  notifications: `${stageGateway}${notifications}`,
  sellerPromotions: `${stageGateway}${promotions}`,
  locations: `${stageGateway}${locations}`,
  itemGroups: `${stageGateway}${itemGroups}`,
  sellerProducts: `${sellerProductsStageBaseUrl}${sellerProducts}`,
  sellerReturns: `${sellerReturnsStageBaseUrl}${sellerReturns}`,
  sellerShippingManagement: `${sellerShippingManagementStageBaseUrl}${sellerShippingManagement}`,
  chatBotWebSocketUrl: `${chatBotWebSocketStageBaseUrl}${chatBotWebsocket}?key=${stageApiGateWay}`,
  sellerShippingsLabels: `${stageGateway}${sellerShippingsLabels}`,
  sellerReports: `${stageGateway}${sellerReports}`,
}

const stage: ApiConfig = {
  ...dev,
  auth: {
    ...dev.auth,
    ...authConfig.stage,
  },
  environment: ENVIRONMENT_STAGE,
}

const prod: ApiConfig = {
  ...commonConfig,
  environment: ENVIRONMENT_PRODUCTION,
  externalHostname: `https://${EXTERNAL_PROD_HOSTNAME}`,
  internalHostname: `https://${INTERNAL_PROD_HOSTNAME}`,
  apiGatewayKey: prodApiGateWay,
  auth: authConfig.prod,
  barcodeValidation: `${prodGateway}${barcodeValiations}`,
  sms: `${prodGateway}${sms}`,
  sellerUsers: `${prodGateway}${sellerUsers}`,
  sellerOrders: `${prodGateway}${sellerOrders}`,
  sellerShipping: `${sellerShippingProdBaseUrl}${sellerShipping}`,
  nexus: `${prodGateway}${nexus}`,
  marketplaceVendors: `${prodGateway}${marketplaceVendors}`,
  marketplaceProducts: `${prodGateway}${marketplaceProducts}`,
  brands: `${prodGateway}${brands}`,
  businessPartnerBrands: `${prodGateway}${businessPartnerBrands}`,
  itemAttributesSearches: `${prodGateway}${itemAttributesSearches}`,
  itemOverrides: `${prodGateway}${itemOverrides}`,
  helios: `${prodGateway}${helios}`,
  firefly: `${prodGateway}${firefly}`,
  biReporting: `${prodGateway}${biReporting}`,
  securityGateway: `${securityGatewayProdBaseUrl}${securityGateway}`,
  geographicalCodes: `${prodGateway}${geographicalCodes}`,
  sellerBulkOps: `${bulkApiProdBaseUrl}${sellerBulkOps}`,
  beetle: beetleProdUrl,
  heliosTopic: heliosProdTopic,
  smsTopic: smsProdTopic,
  notifications: `${prodGateway}${notifications}`,
  sellerPromotions: `${prodGateway}${promotions}`,
  locations: `${prodGateway}${locations}`,
  itemGroups: `${prodGateway}${itemGroups}`,
  sellerProducts: `${sellerProductsProdBaseUrl}${sellerProducts}`,
  sellerReturns: `${sellerReturnsProdBaseUrl}${sellerReturns}`,
  sellerShippingManagement: `${sellerShippingManagementProdBaseUrl}${sellerShippingManagement}`,
  chatBotWebSocketUrl: `${chatBotWebSocketProdBaseUrl}${chatBotWebsocket}?key=${prodApiGateWay}`,
  sellerShippingsLabels: `${prodGateway}${sellerShippingsLabels}`,
  sellerReports: `${prodGateway}${sellerReports}`,
}

const envConfigs: any = { dev, stage, prod }

// TODO: update the hostnames for the isProd check
const isProd = hostname.match(
  new RegExp(
    `^${EXTERNAL_PROD_HOSTNAME}|${EXTERNAL_PROD_TAP_HOSTNAME}|${INTERNAL_PROD_HOSTNAME}$`,
    'i',
  ),
)

const isStage = !isProd && hostname.match(/(stg|stage|perf|qa|dev)/i)

const appEnv =
  process.env.REACT_APP_ENV ||
  (isStage && 'stage') ||
  (isProd && 'prod') ||
  'dev'

const config: ApiConfig = envConfigs[appEnv]

// Added this for testing purpose
window.REACT_APP_ENV = process.env.REACT_APP_ENV

export default config
