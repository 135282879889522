import { FC } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'

import LandingPageTable from './Table'
import LandingPageHeader from './Header'

const LandingPage: FC = () => {
  return (
    <Grid.Container>
      <Grid.Item xs={12}>
        <LandingPageHeader />
      </Grid.Item>
      <Grid.Item></Grid.Item>
      <Grid.Item xs={12}>
        <LandingPageTable />
      </Grid.Item>
    </Grid.Container>
  )
}

export default LandingPage
