export default interface SellerUser {
  id: string
  first_name: string
  last_name: string
  name_suffix?: string
  email: string
  company_name?: string
  phone_numbers?: PhoneNumber[]
  address1?: string
  address2?: string
  city?: string
  state?: string
  postal_code?: string
  country_code?: string
  vmm_metadata?: VmmMetadata[]
  entitlements?: Entitlement[]
  role_types?: Role[]
  idm_sync_status?: string
  functional_responsibilities?: number[]
  status?: number // axios response status code
  errors?: string[] // axios response errors
}

export interface VmmMetadata {
  seller_id: string
  seller_user_vmm_id?: number
  functional_responsibilities?: number[]
}

export interface PhoneNumber {
  number: string
  extension_number?: string
  country_code?: string
  country_name_code?: string
  type?: 'MOBILE' | 'OFFICE'
}
export enum Role {
  ADMIN = 'ADMIN',
  READ = 'READ',
  NONE = 'NONE',
}

export enum RolesMap {
  ADMIN = '3',
  READ = '2',
  NONE = '1',
}
export interface Entitlement {
  resource_id?: string
  resource_type?: string
  role: Role
  seller_user_id?: string
}
