import { FC, useState, useEffect } from 'react'
import { Button, Grid, Heading, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { format, isAfter } from 'date-fns'
import EnterpriseIcon, {
  ChartBarHorizontalIcon,
  MaximizeIcon,
} from '@enterprise-ui/icons'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { useReturnInsightsStore } from 'v2/store'
import { datePattern } from 'v2/constant/date'
import {
  getDifferenceInDays,
  getDateAddedByDays,
  getDateSubtractedByYears,
} from 'v2/utils/date'
import ReportDownload from '../ReportDownload'
import ReturnRateGraph from './ReturnRateGraph'
import { ReturnInformation } from './ReturnInformation'

import {
  StyledReturnRateCardContainer,
  StyledCompareReturnRateCardGridContainer,
  StyledDivider,
} from '../styles'
import ReturnRateModal from './ReturnRateModal'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'
import { useSelector } from 'react-redux'
import { isRoleExternalUserSelector } from 'store/selectors'
import { ReturnRateViewType } from 'v2/store/useReturnInsightsStore'

type TimeFrame = {
  startDate: string
  endDate: string
}

const ReturnRate: FC = () => {
  const [cardId, setCardId] = useState(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY)
  const [datePickerError, setDatePickerError] = useState('')
  const [timeFrame, updateTimeFrame] = useState<TimeFrame | null>(null)
  const [isMaximized, setIsMaximized] = useState(false)
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  const {
    timeFrame: primaryTimeFrame,
    isCompareViewEnabled,
    compareDatePickerDisableDate,
    returnRateGraphView,
    updateCompareView,
    updateCompareTimeFrame,
    clearCompareInterval,
    updateReturnRateGraphView,
  } = useReturnInsightsStore()

  useEffect(() => {
    const setDefaultCompareTimeFrame = () => {
      const today = new Date()
      const twoYearsFromToday = getDateSubtractedByYears(today, 2)
      const oneYearFromSelectedPrimaryTime = getDateSubtractedByYears(
        new Date(primaryTimeFrame.startDate),
        1,
      )
      if (isAfter(oneYearFromSelectedPrimaryTime, twoYearsFromToday)) {
        const numberOfDays = getDifferenceInDays(
          primaryTimeFrame?.startDate!,
          primaryTimeFrame?.endDate!,
        )
        updateTimeFrame({
          startDate: format(
            oneYearFromSelectedPrimaryTime,
            datePattern.slash.MM_dd_yyyy,
          ),
          endDate: format(
            getDateAddedByDays(
              new Date(oneYearFromSelectedPrimaryTime),
              numberOfDays,
            ),
            datePattern.slash.MM_dd_yyyy,
          ),
        })
      }
    }
    if (isCompareViewEnabled) {
      clearCompareState()
      setDefaultCompareTimeFrame()
    }
  }, [isCompareViewEnabled, primaryTimeFrame])

  useEffect(() => {
    if (returnRateGraphView === ReturnRateViewType.MONTH)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_MONTH)
    else if (returnRateGraphView === ReturnRateViewType.WEEK)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_WEEK)
    else if (returnRateGraphView === ReturnRateViewType.DAY)
      setCardId(GREEN_FIELD_CARD_ID.RETURN_RATE_CARD_DAY)
  }, [returnRateGraphView])

  const onDatePickerUpdate = (_id: string, value: string) => {
    if (value) {
      const numberOfDays = getDifferenceInDays(
        primaryTimeFrame?.startDate!,
        primaryTimeFrame?.endDate!,
      )

      updateTimeFrame({
        startDate: value,
        endDate: format(
          getDateAddedByDays(new Date(value), numberOfDays),
          datePattern.slash.MM_dd_yyyy,
        ),
      })
    } else {
      updateTimeFrame(null)
    }
    setDatePickerError('')

    logFireflyEvent(FireflyEvent.RETURN_INSIGHTS_COMPARE_BUTTON_CLICK)
  }

  const onApplyClick = () => {
    if (
      timeFrame?.startDate === primaryTimeFrame?.startDate &&
      timeFrame?.endDate === primaryTimeFrame?.endDate
    ) {
      setDatePickerError(
        'Comparison period cannot be same as Primary period. Please select another period.',
      )
    } else if (
      timeFrame?.endDate &&
      isAfter(
        new Date(timeFrame.endDate),
        new Date(compareDatePickerDisableDate),
      )
    ) {
      setDatePickerError(
        'The return data cannot be accessed for recent 30 days. Please select another comparison period.',
      )
    } else {
      updateCompareTimeFrame(timeFrame!)
      clearCompareState()
      updateCompareView(false)
    }

    logFireflyEvent(FireflyEvent.RETURN_INSIGHTS_COMPARE_APPLY_BUTTON_CLICK)
  }

  const clearCompareState = () => {
    updateTimeFrame(null)
    setDatePickerError('')
  }

  const onClickClose = () => {
    updateCompareView(!isCompareViewEnabled)
    clearCompareState()
  }
  const logFireflyEvent = (event: FireflyEvent, view?: ReturnRateViewType) => {
    if (isExternalUser) {
      trackCustomEvent(event, event.toString(), view ?? '')
    }
  }

  const handleViewClick = (view: ReturnRateViewType) => {
    updateReturnRateGraphView(view)
    clearCompareInterval()
    logFireflyEvent(
      FireflyEvent.RETURN_INSIGHTS_RETURN_RATE_TIME_AXIS_SELECTION,
      view,
    )
  }

  return (
    <StyledReturnRateCardContainer className="hc-pa-normal">
      <Grid.Container justify="space-between">
        <Grid.Item xs={6}>
          <Heading size={4}>RETURN RATE</Heading>
          <p className="font-color-secondary hc-fs-xs">
            % return sales for the orders that were placed in the selected time
            period
          </p>
        </Grid.Item>
        {!isCompareViewEnabled && (
          <Grid.Item>
            <Grid.Container align="center" justify="flex-end">
              <Grid.Item>
                <Tooltip
                  content="Comparison is enabled on day view only"
                  location={
                    returnRateGraphView === ReturnRateViewType.DAY
                      ? 'none'
                      : 'top'
                  }
                >
                  <Button
                    data-testid="return-rate-compare"
                    type="secondary"
                    size="normal"
                    disabled={returnRateGraphView !== ReturnRateViewType.DAY}
                    onClick={() => updateCompareView(!isCompareViewEnabled)}
                  >
                    <EnterpriseIcon icon={ChartBarHorizontalIcon} size="sm" />
                    Compare
                  </Button>
                </Tooltip>
              </Grid.Item>
              <Grid.Item>
                <Button
                  data-testid="return-rate-compare"
                  type="secondary"
                  size="normal"
                  variant="contained"
                  aria-label="Maximize the return rate graph"
                  onClick={() => setIsMaximized(true)}
                >
                  <EnterpriseIcon icon={MaximizeIcon} size="sm" />
                </Button>
              </Grid.Item>
              <Grid.Item>
                <ReportDownload
                  returnPerformanceReportTypes={[returnRateGraphView]}
                  fileName="return-rate"
                  cardLevel={returnRateGraphView}
                  cardName={'RETURN_RATE'}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        )}
      </Grid.Container>
      <StyledDivider className="hc-mt-xs" />
      {isMaximized && (
        <ReturnRateModal
          isMaximized={isMaximized}
          setIsMaximized={setIsMaximized}
          cardId={cardId}
          view={returnRateGraphView}
          handleViewClick={handleViewClick}
        />
      )}
      {isCompareViewEnabled ? (
        <StyledCompareReturnRateCardGridContainer
          className="hc-pa-normal"
          justify="center"
          align="center"
        >
          <Grid.Item xs={10}>
            <DatePicker
              id="return-insights-compare-date-picker"
              label="Comparison period starts from"
              error={!!datePickerError}
              errorText={datePickerError}
              onUpdate={onDatePickerUpdate}
              value={timeFrame?.startDate}
              hintText="Please select start date of the comparison period from the calendar"
              disableDates={{ after: compareDatePickerDisableDate }}
            />
            <Grid.Container className="hc-mt-normal" justify="flex-end">
              <Grid.Item>
                <Button
                  type="secondary"
                  size="normal"
                  variant="contained"
                  onClick={onClickClose}
                >
                  Close
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="primary"
                  size="normal"
                  variant="contained"
                  onClick={onApplyClick}
                  disabled={timeFrame === null}
                >
                  Apply
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </StyledCompareReturnRateCardGridContainer>
      ) : (
        <Grid.Container className="hc-pa-normal">
          <Grid.Item xs={3}>
            <ReturnInformation />
          </Grid.Item>
          <Grid.Item xs={9}>
            <ReturnRateGraph
              cardId={cardId}
              view={returnRateGraphView}
              handleViewClick={handleViewClick}
            />
          </Grid.Item>
        </Grid.Container>
      )}
    </StyledReturnRateCardContainer>
  )
}

export default ReturnRate
