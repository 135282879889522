import { FC } from 'react'
import EnterpriseIcon, { BellIcon } from '@enterprise-ui/icons'

import { StyledBadge, StyledPopover } from './styles'
import NotificationsContent from './NotificationsContent'

const Notifications: FC = () => {
  return (
    <StyledPopover
      className="hc-mr-normal"
      type="clickOnly"
      content={<NotificationsContent />}
      location="bottom"
    >
      <StyledBadge content="5" aria-label="5 unread notifications">
        <EnterpriseIcon icon={BellIcon} size="lg" />
      </StyledBadge>
    </StyledPopover>
  )
}

export default Notifications
