import { Grid } from '@enterprise-ui/canvas-ui-react'
import { format } from 'date-fns'

import { date } from 'v2/constant'
import { useTGIDStore } from 'v2/store'
import TimeRemaining from '../../TimeRemaining'
import TcinInformation from './ItemInformation'

export const Header = () => {
  const { selectedItem } = useTGIDStore()

  return (
    <Grid.Container className="hc-bg-grey07 hc-ma-none">
      <Grid.Item xs={9}>
        <TcinInformation />
      </Grid.Item>

      <Grid.Item xs={3}>
        <Grid.Container direction="column" align="flex-end">
          <div className="hc-ph-md hc-pt-md">
            {selectedItem?.reviewEndTime && (
              <TimeRemaining timeStamp={selectedItem.reviewEndTime} inDrawer />
            )}
          </div>
          <Grid.Item xs={10}>
            <p className="hc-fs-xs">
              Review Create Date:{' '}
              {selectedItem?.reviewCreateDate
                ? format(
                    new Date(selectedItem?.reviewCreateDate),
                    date.datePattern.verbose.dd_MMM_YYY_time,
                  )
                : 'N/A'}
            </p>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default Header
