import { FlagName, flag } from 'flag'
import SellerUser from 'types/SellerUser'
import { SupportCode } from 'types/SupportCode'

export function getAllResponsibilities(contacts: SellerUser[]): number[] {
  const isIDMIntegration = flag(FlagName.SMS_IDM_INTEGRATION)
  let responsibilites: number[] = []
  contacts?.forEach((contact) => {
    const functionalResponsibilities = isIDMIntegration
      ? contact.functional_responsibilities
      : contact.vmm_metadata?.[0]?.functional_responsibilities
    if (functionalResponsibilities) {
      responsibilites.push(...functionalResponsibilities)
    }
  })

  return [...new Set(responsibilites)]
}

export const allResponsibilities = [
  SupportCode.CUSTOMER_SUPPORT,
  SupportCode.SALES_BUSINESS,
  SupportCode.SHIPPING_AND_FULLFILMENT,
  SupportCode.FINANCE_ACCOUNTING,
  SupportCode.ITEM_DATA_SETUP,
  SupportCode.REVERSE_LOGISTICS,
]

export function hasAllResponsibilities(responsibilities: number[]): boolean {
  return allResponsibilities.every((responsibility) =>
    responsibilities.includes(responsibility),
  )
}

//this character list is from VMM regarding characters they are unable to handle
export function containsInvalidCharacters(name: string): boolean {
  return /[^a-zA-Z0-9\s{}()\\;',@%_+-=#$*.?:&[<>\]]+/g.test(name)
}
