import { FC, type SyntheticEvent } from 'react'
import { Grid, Tabs, Card } from '@enterprise-ui/canvas-ui-react'

import { useTGIDStore } from 'v2/store'
import { ProductType } from 'v2/store/useTGIDStore'
import { StyledTabs } from '../../styles'
import VariationTable from './ItemsTable'
import { BulkSubmission } from './BulkSubmission'

const LandingPageTable: FC = () => {
  const { itemType, updateItemType } = useTGIDStore()

  const onTabSelect = (_event: SyntheticEvent, tab: any) => {
    updateItemType(tab.name)
  }

  return (
    <>
      <Card className="hc-mb-expanded">
        <Grid.Container justify="space-between">
          <StyledTabs className="hc-pb-none">
            <Tabs
              activeTab={itemType}
              onTabSelect={onTabSelect}
              disableAdaptive
              size="expanded"
            >
              <Tabs.Item name={ProductType.STANDALONE}>
                Standalone Items
              </Tabs.Item>
              <Tabs.Item name={ProductType.VARIANT_CHILD}>
                Variation Items
              </Tabs.Item>
            </Tabs>
          </StyledTabs>
          <BulkSubmission />
        </Grid.Container>
      </Card>
      <VariationTable />
    </>
  )
}

export default LandingPageTable
