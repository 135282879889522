export const DIVISION_EMAIL: { [key: string]: string[] } = {
  'INT/HOS/SLEEP': ['poushali.khan@target.com', 'yashaswini.gaur@target.com'],
  'JEWELRY/ACCESS': ['poushali.khan@target.com', 'yashaswini.gaur@target.com'],
  MENS: ['poushali.khan@target.com', 'yashaswini.gaur@target.com'],
  'NIT APPAREL': ['poushali.khan@target.com', 'yashaswini.gaur@target.com'],
  PERFORMANCE: ['poushali.khan@target.com', 'yashaswini.gaur@target.com'],
  'READY-TO-WEAR': ['poushali.khan@target.com', 'yashaswini.gaur@target.com'],
  SHOES: ['poushali.khan@target.com', 'yashaswini.gaur@target.com'],
  SWIM: ['poushali.khan@target.com', 'yashaswini.gaur@target.com'],
  BABY: ['palash.dhage@target.com', 'saloni.jain1@target.com'],
  BEAUTY: ['palash.dhage@target.com', 'saloni.jain1@target.com'],
  HEALTH: ['palash.dhage@target.com', 'saloni.jain1@target.com'],
  'HOME CARE': ['palash.dhage@target.com', 'saloni.jain1@target.com'],
  PETS: ['palash.dhage@target.com', 'saloni.jain1@target.com'],
  'ADULT BEVERAGES': ['neha.joshi@target.com'],
  'DAIRY/FROZEN': ['neha.joshi@target.com'],
  DELI: ['neha.joshi@target.com'],
  'DRY GROCERY': ['neha.joshi@target.com'],
  'FRESH MEAT': ['neha.joshi@target.com'],
  'NA BEVERAGES': ['neha.joshi@target.com'],
  'PROCESSED/FROZEN MEAT': ['neha.joshi@target.com'],
  'SNACKS/CANDY': ['neha.joshi@target.com'],
  'CONSUMER ELECTRONICS': [
    'saloni.jain1@target.com',
    'palash.dhage@target.com',
  ],
  ENTERTAINMENT: ['saloni.jain1@target.com', 'palash.dhage@target.com'],
  'SPORTING GOODS': ['saloni.jain1@target.com', 'palash.dhage@target.com'],
  TOYS: ['saloni.jain1@target.com', 'palash.dhage@target.com'],
  'DECORATIVE HOME': ['chethan.h@target.com', 'akash.pillai@target.com'],
  DOMESTICS: ['chethan.h@target.com', 'akash.pillai@target.com'],
  KITCHEN: ['chethan.h@target.com', 'akash.pillai@target.com'],
  'SEASNL/OUTDR LIVING': ['chethan.h@target.com', 'akash.pillai@target.com'],
  STATIONERY: ['chethan.h@target.com', 'akash.pillai@target.com'],
  'STORAGE/UTILITY': ['chethan.h@target.com', 'akash.pillai@target.com'],
}
