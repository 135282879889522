import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Input, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelCircleIcon } from '@enterprise-ui/icons'
import bulkPromoCreationTemplate from '../../../v2/assets/sheets/BulkPromoCreationTemplate.xlsx'

import { MEGABYTES_AS_BYTES_1 } from 'constants/sizeLimits'
import { showNotification } from 'store/notification/reducer'
import { FileContainer } from './styles'
import { XLXS_CONTENT_TYPE } from 'constants/fileTypes'
import { submitReport } from 'services/promotions'
import { use3pPromotionsStore } from 'v2/store'
import { saveFile } from 'v2/utils/helper'

const Bulk3PPromoUpload = ({
  onRequestClose,
}: {
  onRequestClose: () => void
}) => {
  const { updateFile, uploadedFile, updateIsNewFileUpload } =
    use3pPromotionsStore()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isPending, setIsPending] = useState<boolean>()

  const dispatch = useDispatch()

  const handleFileChange = (event: any) => {
    if (event.target.files[0]?.size <= MEGABYTES_AS_BYTES_1) {
      updateFile(event.target.files[0])
      setErrorMessage('')
    } else {
      setErrorMessage('File size exceeds 1MB. Please reduce and retry.')
    }
  }

  const onClearFileButtonClick = () => {
    updateFile(null)
  }

  const handleFileUpload = async () => {
    if (isPending) {
      return
    }
    setIsPending(true)
    try {
      const savedReport = await submitReport({
        fileInput: uploadedFile,
      })

      setErrorMessage('')
      if (savedReport) {
        dispatch(
          showNotification({
            isShown: true,
            message: 'Upload successful!',
            severity: 'success',
            autoClose: true,
          }),
        )

        updateIsNewFileUpload(true)
        setIsPending(false)
        updateFile(null)
        onRequestClose()
      }
    } catch (error: any) {
      setIsPending(false)
      updateFile(null)
      setErrorMessage('Server error. Please retry.')
      dispatch(
        showNotification({
          isShown: true,
          message: error?.response?.data
            ? error?.response.data?.errors[0]
            : error?.messaage,
          autoClose: true,
        }),
      )
    }
  }

  const onTemplateDownload = () => {
    saveFile(bulkPromoCreationTemplate, 'BulkPromoCreationTemplate.xlsx')
  }

  return (
    <>
      <div className="hc-mt-normal hc-bg-grey07 hc-pa-normal">
        <Grid.Container justify="center">
          <Grid.Item xs={12}>
            <Button
              type="primary"
              className="hc-ph-expanded"
              onClick={onTemplateDownload}
            >
              Download Template
            </Button>
          </Grid.Item>
        </Grid.Container>
        {uploadedFile ? (
          <FileContainer className="hc-bg-contrast-weak hc-mt-normal">
            <p className="hc-fs-sm hc-pl-md hc-pt-min">{uploadedFile.name}</p>
            <Button onClick={onClearFileButtonClick} type="ghost">
              <EnterpriseIcon icon={CancelCircleIcon} />
            </Button>
          </FileContainer>
        ) : (
          <div className="hc-mt-normal">
            <Input.DropArea
              accept={`.csv, ${XLXS_CONTENT_TYPE}`}
              fullwidth={true}
              onUpdate={handleFileChange}
              clickOnly
              dropText="Upload excel sheet"
              instructionText="Please upload the excel sheet containing promotion details"
            />
          </div>
        )}

        {errorMessage && (
          <div className="hc-fs-sm hc-mt-min hc-clr-error hc-bg-grey07">
            {errorMessage}
          </div>
        )}
      </div>
      <Grid.Container className="hc-mt-normal color/background/info/base-active ">
        <Grid.Item>
          <Button
            className="hc-ph-xl"
            type="secondary"
            onClick={onRequestClose}
          >
            Cancel
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            className="hc-ph-2x"
            type="primary"
            disabled={!uploadedFile}
            onClick={handleFileUpload}
            isLoading={isPending}
          >
            Save
          </Button>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default Bulk3PPromoUpload
