import { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  Placeholder,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { StyledSubmitButton } from '../styles'
import { useQuery } from '@tanstack/react-query'
import { useTGIDStore } from 'v2/store'
import { getProductEnhancements } from 'v2/services/getProductEnhancements'
import { ReviewStatus } from '../LandingPage/Table/BulkSubmission'
import { updateBulkProduct } from 'v2/services/updateBulkProduct'

interface Props {
  handleClose: () => void
}

export const BulkChildSubmission = ({ handleClose }: Props) => {
  const { selectedItem, updateSelectedVCTcins } = useTGIDStore()
  const { sellerId, parentProductId } = selectedItem
  const [isSubmittingOriginal, setIsSubmittingOriginal] =
    useState<boolean>(false)
  const [isSubmittingRecommended, setIsSubmittingRecommended] =
    useState<boolean>(false)

  const makeToast = useToaster()

  const { data: itemsData, isFetching } = useQuery({
    queryKey: ['PRODUCT_ENHANCEMENTS_BY_PRODUCT_ID', sellerId, parentProductId],
    queryFn: () => {
      if (sellerId) {
        return getProductEnhancements(sellerId, {
          perPage: 350,
          parentProductId,
        })
      }
      return null
    },
  })

  useEffect(() => {
    if (itemsData) {
      const selectedTcins = itemsData?.data?.map((item: any) => item.tcin)
      updateSelectedVCTcins(selectedTcins)
    }
  }, [itemsData, updateSelectedVCTcins])

  if (isFetching) {
    return (
      <Placeholder>
        <Grid.Container justify="flex-end" className="hc-mv-xs">
          <Grid.Item xs={2}>
            <Placeholder.Rect emphasized height="30px" />
          </Grid.Item>
          <Grid.Item xs={2}>
            <Placeholder.Rect emphasized height="30px" />
          </Grid.Item>
        </Grid.Container>
      </Placeholder>
    )
  }

  const onSubmitClick = async (reviewStatus: string) => {
    try {
      const isRecommended = reviewStatus === ReviewStatus.APPROVED
      isRecommended
        ? setIsSubmittingRecommended(true)
        : setIsSubmittingOriginal(true)
      const productIds = itemsData?.data?.map((item: any) => item.id)

      const response = await updateBulkProduct({
        sellerId,
        reviewStatus,
        productIds,
      })

      if (response)
        isRecommended
          ? setIsSubmittingRecommended(false)
          : setIsSubmittingOriginal(false)

      const typeOfSubmit = isRecommended ? 'Recommended' : 'Original'

      if (response.successful_update_count === productIds.length) {
        makeToast({
          type: 'success',
          heading: 'Submitted Successfully',
          message: `${typeOfSubmit} values for ${productIds.length} tcin(s) submitted successfully.`,
        })
      } else {
        makeToast({
          type: 'error',
          heading: 'Error',
          message: `${typeOfSubmit} values for ${response.successful_update_count} tcin(s) submitted successfully.`,
        })
      }
    } catch (error) {
      makeToast({
        type: 'error',
        heading: 'Error',
        message: 'Error in submission, please try again.',
      })
    } finally {
      handleClose()
    }
  }

  return (
    <Grid.Container justify="flex-end" className="hc-mv-xs">
      <Grid.Item>
        <Button
          type="secondary"
          onClick={() => onSubmitClick(ReviewStatus.REJECTED)}
          isLoading={isSubmittingOriginal}
        >
          Submit original values for all VCs ({itemsData?.total})
        </Button>
      </Grid.Item>
      <Grid.Item>
        <StyledSubmitButton
          type="secondary"
          isLoading={isSubmittingRecommended}
          onClick={() => onSubmitClick(ReviewStatus.APPROVED)}
        >
          Submit recommended values for all VCs ({itemsData?.total})
        </StyledSubmitButton>
      </Grid.Item>
    </Grid.Container>
  )
}
