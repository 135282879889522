import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'

interface Params {
  productId: string
}

export const getProductsByProductId = async (params: Params) => {
  try {
    const queryparams = buildURLQueryParams({
      product_id: params.productId,
    })

    const response = await axios.get(
      `${apiConfig.marketplaceProducts}/products?${queryparams}`,
    )

    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
