import { useState, useEffect, useRef } from 'react'
import { Button, Modal, Grid, Alert } from '@enterprise-ui/canvas-ui-react'
import { useSelector } from 'react-redux'
import { ProgressBar } from '@enterprise-ui/canvas-ui-react'

import { currentSellerId } from 'store/selectors'
import { createReport, getPollReport, getDownloadReport } from 'v2/services'

interface DowloadReportModalProps {
  isModalOpen: boolean
  setIsModalOpen: (value: boolean) => void
  reportRequestPayload: any
  fileName: string
}

const DowloadReportModal = ({
  isModalOpen,
  reportRequestPayload,
  setIsModalOpen,
  fileName,
}: DowloadReportModalProps) => {
  const poll = useRef<ReturnType<typeof setInterval> | null>(null)
  const sellerId = useSelector(currentSellerId) ?? ''
  const [downloadUrl, setDownloadUrl] = useState('')
  const [reportId, setReportId] = useState('')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchReportData = async () => {
      if (reportRequestPayload) {
        try {
          const reportData = await createReport({
            sellerId,
            payload: reportRequestPayload,
          })

          if (reportData?.id) {
            setReportId(reportData.id)
          } else {
            setIsError(true)
          }
        } catch (error) {
          setIsError(true)
        }
      }
    }

    fetchReportData()
  }, [reportRequestPayload, sellerId])

  useEffect(() => {
    if (reportId) {
      poll.current = setInterval(async () => {
        try {
          const response = await getPollReport({ sellerId, reportId })

          if (response?.download_url && response?.status === 'COMPLETE') {
            setDownloadUrl(response.download_url)
            clearInterval(poll.current!)
          }

          if (response?.status === 'ERROR') {
            clearInterval(poll.current!)
            setIsError(true)
          }
        } catch (error) {
          clearInterval(poll.current!)
          setIsError(true)
        }
      }, 5000)
    }

    return () => clearInterval(poll.current!)
  }, [sellerId, reportId])

  const onRefuseTrigger = () => {
    clearInterval(poll.current!)
    setIsModalOpen(false)
    setReportId('')
    setIsError(false)
    setDownloadUrl('')
  }

  const onDownloadClick = async () => {
    await getDownloadReport({
      downloadUrl,
      fileName,
    })
  }

  return (
    <Modal
      isVisible={isModalOpen}
      headingText={`${downloadUrl ? 'Download Report' : 'Generating Report'}`}
      onRefuse={onRefuseTrigger}
    >
      <div className="hc-pa-normal">
        {isError ? (
          <Grid.Container>
            <Grid.Item xs>
              <Alert type="error" heading="Download Failed">
                Please try again later.
              </Alert>
            </Grid.Item>
          </Grid.Container>
        ) : (
          <>
            <Grid.Container className="hc-mb-normal">
              <Grid.Item xs>
                {downloadUrl ? (
                  <ProgressBar percentComplete={100} />
                ) : (
                  <ProgressBar indeterminate />
                )}
              </Grid.Item>
            </Grid.Container>
            <Grid.Container direction="row-reverse" spacing="dense">
              {downloadUrl && (
                <Grid.Item>
                  <Button
                    aria-label={`Download report for ${fileName}, in excel format`}
                    onClick={onDownloadClick}
                    type="primary"
                    tabIndex={1}
                  >
                    Download
                  </Button>
                </Grid.Item>
              )}
              <Grid.Item>
                <Button tabIndex={0} onClick={onRefuseTrigger} type="secondary">
                  Cancel
                </Button>
              </Grid.Item>
            </Grid.Container>
          </>
        )}
      </div>
    </Modal>
  )
}

export default DowloadReportModal
