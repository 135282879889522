import { SvgIconProps } from '@mui/material'

import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import BusinessIcon from '@mui/icons-material/Business'
import BugReportIcon from '@mui/icons-material/BugReport'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import ExtensionIcon from '@mui/icons-material/Extension'
import FeedbackIcon from '@mui/icons-material/Feedback'
import FindReplaceIcon from '@mui/icons-material/FindReplace'
import FlagIcon from '@mui/icons-material/Flag'
import FlightIcon from '@mui/icons-material/Flight'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import GavelIcon from '@mui/icons-material/Gavel'
import GetAppIcon from '@mui/icons-material/GetApp'
import GroupIcon from '@mui/icons-material/Group'
import HomeIcon from '@mui/icons-material/Home'
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LockIcon from '@mui/icons-material/Lock'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import NotesIcon from '@mui/icons-material/Notes'
import NotificationsIcon from '@mui/icons-material/Notifications'
import QuestionMark from '@mui/icons-material/QuestionMark'
import RocketIcon from '@mui/icons-material/Rocket'
import PaletteIcon from '@mui/icons-material/Palette'
import PhoneSetupIcon from '@mui/icons-material/PhonelinkSetup'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import ScheduleIcon from '@mui/icons-material/Schedule'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import StarIcon from '@mui/icons-material/Star'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import UndoIcon from '@mui/icons-material/Undo'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import WarningIcon from '@mui/icons-material/Warning'
import WidgetsIcon from '@mui/icons-material/Widgets'
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor'
import ListAltIcon from '@mui/icons-material/ListAlt'
import InsightsIcon from '@mui/icons-material/Insights'
import InsertChartIcon from '@mui/icons-material/InsertChart'

import ChecklistIcon from '@mui/icons-material/Checklist'
import Bullseye from 'components/common/Icons/Bullseye'

import { isOneOfUserRoles } from 'services/authorization'
import {
  defaultOrderFilterQuery,
  NavGroup,
  RoutePath,
} from 'services/NavigationHelper'
import {
  hasAlertManagementPage,
  hasBarcodeReleasePage,
  hasErrorConfigPage,
  hasFeedbackPage,
  hasHolidayClosuresPage,
  hasHomePage,
  hasItemProcessingPage,
  hasItemUploadPage,
  hasItemVersionPage,
  hasKafkaTopicsSearchPage,
  hasPhoneEditPage,
  hasPromotions,
  hasReturnDisputeCasesPage,
  hasReturnShippingRatesNav,
  hasSalesMetricsPage,
  hasTimeline,
  hasUtilitiesNav,
  hasShippingMarkupPage,
  hasReturnsResearchPage,
  hasUnlistItemsPageAccess,
  hasReturnDashboardInternalAccess,
  hasReturnDashboardExternalAccess,
  hasTgidConsentPageAccess,
  hasNotificationSettingsPageAccess,
  hasTgidReviewPageAccess,
  hasPriceInsightsDashboardAccess,
} from 'services/pageAccess'
import { USER_ROLE_ADMIN } from 'services/roles'

import { SellerStatus } from 'types/Seller'

import { DialogEnum } from '../Dialog'
import { WorkOff } from '@mui/icons-material'
import { trackCustomEvent } from 'services/fireflyInsights'
import { FireflyEvent } from 'types/FireflyInsights'

export interface NavConfig {
  to?: RoutePath
  text: string
  iconComponent?: React.ComponentType<SvgIconProps>
  iconRotateLeft?: boolean
  show?: boolean
  subRoutes?: NavConfig[]
  navGroup?: NavGroup
  unsetSellerId?: boolean
  queryParams?: Dictionary<any>
}

export type ExternalNavParams = {
  memberOf: string[]
  sellerSource: string | undefined
  sellerStatus: SellerStatus | undefined
  openDialog: any
  sellerId: string | undefined
}

const openFeedbackDialog = (openDialog: any) => () => {
  openDialog({ dialogEnum: DialogEnum.FEEDBACK_DIALOG })
}

const trackHelpClick = () => {
  trackCustomEvent(FireflyEvent.HELP_BUTTON, 'event', 'click')
}

export const getExternalNav = ({
  memberOf,
  sellerSource,
  sellerStatus,
  openDialog,
  sellerId,
}: ExternalNavParams) => {
  return [
    {
      to: RoutePath.HOME,
      text: 'Home',
      iconComponent: Bullseye,
      show: hasHomePage(memberOf),
      unsetSellerId: true,
    },
    {
      to: RoutePath.DASHBOARD,
      text: 'Dashboard',
      iconComponent: HomeIcon,
    },
    {
      to: RoutePath.ITEM_UPLOAD,
      text: 'Item Upload',
      iconComponent: CloudUploadIcon,
      show: hasItemUploadPage(memberOf, sellerSource, sellerStatus),
    },
    {
      to: RoutePath.ORDERS,
      queryParams: defaultOrderFilterQuery,
      text: 'Orders',
      iconComponent: ShoppingCartIcon,
    },
    {
      to: RoutePath.RETURNED_ORDERS,
      text: 'Returns',
      iconComponent: UndoIcon,
    },
    {
      to: RoutePath.REPORTS,
      text: 'Reports',
      iconComponent: GetAppIcon,
    },
    {
      to: RoutePath.PAYOUTS_AND_ACCOUNT,
      text: 'Payouts & Account',
      iconComponent: MonetizationOnIcon,
    },
    {
      to: RoutePath.PARTNER_SERVICES,
      text: 'Services',
      iconComponent: ImportantDevicesIcon,
    },
    {
      navGroup: NavGroup.ITEMS,
      text: 'Items',
      subRoutes: [
        {
          to: RoutePath.ALL_ITEMS,
          text: 'All Items',
          iconComponent: WidgetsIcon,
        },
        {
          to: RoutePath.ATTRIBUTES_AND_VALUES,
          text: 'Attributes & Values',
          iconComponent: PaletteIcon,
        },
        {
          to: RoutePath.TGID,
          text: 'TGID Review',
          iconComponent: ChecklistIcon,
          show: hasTgidReviewPageAccess(memberOf),
        },
      ],
    },
    {
      navGroup: NavGroup.EXTERNAL_PLANNING,
      text: 'Planning',
      subRoutes: [
        {
          to: RoutePath.EXTERNAL_PROMOTIONS,
          text: 'Promotions',
          iconComponent: LoyaltyIcon,
          show: hasPromotions(),
        },
        {
          to: RoutePath.EXTERNAL_CALENDAR,
          text: 'Calendar',
          iconComponent: CalendarMonthIcon,
        },
      ],
    },
    {
      navGroup: NavGroup.PERFORMANCE,
      text: 'Performance',
      subRoutes: [
        {
          to: RoutePath.PERFORMANCE,
          text: 'Order Defect Rate',
          iconComponent: WarningIcon,
        },
        {
          to: RoutePath.SALES_METRICS,
          text: 'Sales Metrics',
          iconComponent: TrendingUpIcon,
          show: hasSalesMetricsPage(memberOf),
        },
        {
          to: RoutePath.RATINGS_AND_REVIEWS,
          text: 'Ratings & Reviews',
          iconComponent: StarIcon,
          show: isOneOfUserRoles(memberOf, [USER_ROLE_ADMIN]),
        },
        {
          to: RoutePath.ITEM_INSIGHTS,
          text: 'Item Insights',
          iconComponent: ImageSearchIcon,
        },
        {
          to: RoutePath.RETURN_INSIGHTS,
          text: 'Return Insights',
          iconComponent: InsightsIcon,
          show: hasReturnDashboardExternalAccess(),
        },
        {
          to: RoutePath.PRICE_INSIGHTS,
          text: 'Price Insights',
          iconComponent: InsertChartIcon,
          show: hasPriceInsightsDashboardAccess(),
        },
      ],
    },
    {
      navGroup: NavGroup.BUSINESS,
      text: 'Business Info',
      subRoutes: [
        {
          to: RoutePath.BASIC_INFO,
          text: 'Basic Info',
          iconComponent: NotesIcon,
        },
        {
          to: RoutePath.TGID_CONSENT,
          text: 'TGID Consent',
          iconComponent: ImportantDevicesIcon,
          show: hasTgidConsentPageAccess(memberOf, sellerId ?? ''),
        },

        {
          to: RoutePath.SHIPPING_INFO,
          text: 'Shipping Details',
          iconComponent: FlightIcon,
        },
        {
          to: RoutePath.RETURN_POLICIES,
          text: 'Return Policies',
          iconComponent: AccountBalanceIcon,
        },
        {
          to: RoutePath.FORMS_AND_AGREEMENTS,
          text: 'Forms & Agreements',
          iconComponent: AssignmentIcon,
        },
        {
          to: RoutePath.REFERRAL_FEES,
          text: 'Referral Fees',
          iconComponent: CardMembershipIcon,
        },
      ],
    },
    {
      navGroup: NavGroup.PARTNER,
      text: 'Partner Settings',
      subRoutes: [
        {
          to: RoutePath.USER_ACCESS,
          text: 'Users & Contacts',
          iconComponent: HowToRegIcon,
        },
        {
          to: RoutePath.API_TOKENS,
          text: 'API Tokens',
          iconComponent: ExtensionIcon,
        },
        {
          to: RoutePath.ITEM_LISTING_SETTINGS,
          text: 'Item Listings Settings',
          iconComponent: VerifiedUserIcon,
        },
        {
          to: RoutePath.ERROR_CONFIGURATION,
          text: 'Error Configuration',
          iconComponent: BugReportIcon,
          show: hasErrorConfigPage(memberOf),
        },
        {
          to: RoutePath.NOTIFICATION_SETTINGS,
          text: 'Notification Settings',
          iconComponent: NotificationsIcon,
          show: hasNotificationSettingsPageAccess(memberOf),
        },
      ],
    },
    {
      navGroup: NavGroup.SUPPORT,
      text: 'Support',
      subRoutes: [
        {
          to: RoutePath.HELP,
          text: 'Help Center',
          iconComponent: QuestionMark,
          onClick: () => trackHelpClick(),
          unsetSellerId: true,
        },
        {
          to: RoutePath.OPEN_CASES,
          text: 'Open Cases',
          iconComponent: BusinessCenterIcon,
        },
        {
          to: RoutePath.RETURN_DISPUTE_CASES,
          text: 'Return Disputes',
          iconComponent: FlagIcon,
        },
      ],
    },
    {
      text: 'Feedback',
      iconComponent: FeedbackIcon,
      show: hasFeedbackPage(memberOf),
      onClick: openFeedbackDialog(openDialog),
      isLink: false,
    },
  ] as NavConfig[]
}

export const getInternalNav = (memberOf: string[]) =>
  [
    {
      to: RoutePath.HOME,
      text: 'Home',
      iconComponent: Bullseye,
      unsetSellerId: true,
    },
    {
      to: RoutePath.SELLERS,
      text: 'Partners',
      iconComponent: GroupIcon,
    },
    {
      to: RoutePath.ONBOARDING,
      text: 'Onboarding',
      iconComponent: RocketIcon,
      show: false,
    },
    {
      to: RoutePath.SOURCES,
      text: 'Integrations',
      iconComponent: SettingsEthernetIcon,
    },
    {
      to: RoutePath.ALL_ORDERS,
      queryParams: defaultOrderFilterQuery,
      text: 'All Orders',
      iconComponent: ShoppingCartIcon,
    },
    {
      to: RoutePath.INTERNAL_REPORTS,
      text: 'Reports',
      iconComponent: GetAppIcon,
    },
    {
      to: RoutePath.REVIEW_QUEUE,
      text: 'Review Queue',
      iconComponent: AssignmentTurnedInIcon,
    },
    {
      navGroup: NavGroup.ITEM_REVIEW,
      text: 'Item Review Tools',
      subRoutes: [
        {
          to: RoutePath.DATA_MAPPING,
          text: 'Data Mapping',
          iconComponent: FindReplaceIcon,
        },
        {
          to: RoutePath.ITEMS_PROCESSING,
          text: 'Items Processing',
          iconComponent: ScheduleIcon,
          show: hasItemProcessingPage(memberOf),
        },
        {
          to: RoutePath.ERROR_CODES,
          text: 'Error Codes',
          iconComponent: BugReportIcon,
        },
      ],
    },
    {
      navGroup: NavGroup.RETURNS,
      text: 'Returns',
      subRoutes: [
        {
          to: RoutePath.ALL_RETURNS,
          text: 'All Returns',
          iconComponent: UndoIcon,
        },
        {
          to: RoutePath.RETURNS_RESEARCH,
          text: 'Returns Research',
          iconComponent: YoutubeSearchedForIcon,
          show: hasReturnsResearchPage(memberOf),
        },
        {
          to: RoutePath.INTERNAL_RETURN_DISPUTE_CASES,
          text: 'Return Disputes',
          iconComponent: BusinessCenterIcon,
          show: hasReturnDisputeCasesPage(memberOf),
        },
        {
          to: RoutePath.ALL_RETURN_INSIGHTS,
          text: 'Return Insights',
          iconComponent: InsightsIcon,
          show: hasReturnDashboardInternalAccess(),
        },
      ],
    },
    {
      navGroup: NavGroup.PLANNING,
      text: 'Planning',
      subRoutes: [
        {
          to: RoutePath.PROMOTIONS,
          text: 'Promotions',
          iconComponent: LoyaltyIcon,
          show: hasPromotions(),
        },
        {
          to: RoutePath.CALENDAR,
          text: 'Calendar',
          iconComponent: CalendarMonthIcon,
        },
      ],
    },
    {
      navGroup: NavGroup.GLOBAL_TERMS,
      text: 'Global Terms',
      subRoutes: [
        {
          to: RoutePath.SHIPPING_METHODS,
          text: 'Shipping Methods',
          iconComponent: FlightIcon,
        },
        {
          to: RoutePath.SHIPPING_MARKUP,
          text: 'Buy Shipping Markup',
          iconComponent: MarkunreadMailboxIcon,
          show: hasShippingMarkupPage(memberOf),
        },
        {
          to: RoutePath.RETURN_SHIPPING_RATES,
          text: 'Return Service Fees',
          iconComponent: LocalShippingIcon,
          show: hasReturnShippingRatesNav(memberOf),
        },
        {
          to: RoutePath.BASE_REFERRAL_FEES,
          text: 'Base Referral Fees',
          iconComponent: CardMembershipIcon,
        },
        {
          to: RoutePath.PROTECTED_BRANDS,
          text: 'Protected Brands',
          iconComponent: LockIcon,
        },
        {
          to: RoutePath.GLOBAL_QUOTAS,
          text: 'Global Quotas',
          iconComponent: GavelIcon,
        },
        {
          to: RoutePath.INFORM_ACT_VERIFICATION,
          text: 'Inform Act Verification',
          iconComponent: BusinessIcon,
        },
        {
          to: RoutePath.HOLIDAY_CLOSURES,
          text: 'Holiday Closures',
          iconComponent: WorkOff,
          show: hasHolidayClosuresPage(memberOf),
        },
      ],
    },
    {
      navGroup: NavGroup.UTILITIES,
      text: 'Utilities',
      show: hasUtilitiesNav(memberOf),
      subRoutes: [
        {
          to: RoutePath.ITEM_VERSIONS,
          text: 'Item Versions',
          iconComponent: DynamicFeedIcon,
          show: hasItemVersionPage(memberOf),
        },
        {
          to: RoutePath.BARCODE_RELEASE,
          text: 'Barcode Release',
          iconComponent: HorizontalSplitIcon,
          iconRotateLeft: true,
          show: hasBarcodeReleasePage(memberOf),
        },
        {
          to: RoutePath.UNLIST_ITEMS,
          text: 'Unlist Items',
          iconComponent: ListAltIcon,
          show: hasUnlistItemsPageAccess(memberOf),
        },
        {
          to: RoutePath.PHONE_EDIT,
          text: 'User Phone Edit',
          iconComponent: PhoneSetupIcon,
          show: hasPhoneEditPage(memberOf),
        },
        {
          to: RoutePath.TIMELINE_UTIL,
          text: 'Timeline',
          iconComponent: FormatListNumberedIcon,
          show: hasTimeline(memberOf),
        },
        {
          to: RoutePath.ALERT_MANAGEMENT,
          text: 'Alert Management',
          iconComponent: NotificationsIcon,
          show: hasAlertManagementPage(memberOf),
        },
        {
          to: RoutePath.SEARCH_TOPICS,
          text: 'Search Topics',
          iconComponent: QueryStatsIcon,
          show: hasKafkaTopicsSearchPage(memberOf),
        },
      ],
    },
  ] as NavConfig[]
