import axios from 'axios'
import apiConfig from 'config/apiConfig'

export const getConsents = async (sellerId: string) => {
  try {
    const response = await axios.get(
      `${apiConfig.sms}/sellers/${sellerId}/consents`,
    )

    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
