import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { StyledCardContainer } from '../styles'
import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { useQuery } from '@tanstack/react-query'
import { sellerExecuteCardQuery } from 'v2/query'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'
import { useReturnInsightsStore } from 'v2/store'
import { roundToTwoDecimals } from 'v2/utils/helper'

const CategoryReturnRateGraph = () => {
  const { divisionId, categoryId, selectedVmmId, interval } =
    useReturnInsightsStore()

  const {
    data: graphData,
    isFetching,
    isError,
  } = useQuery(
    ['CATORY_RETURN_RATE', { selectedVmmId, categoryId, divisionId, interval }],
    () =>
      sellerExecuteCardQuery({
        vmmId: selectedVmmId,
        divisionId,
        categoryId,
        sellerId: '',
        interval,
        cardId: GREEN_FIELD_CARD_ID.CATEGORY_RETURN_RATE_CARD,
      }),
  )

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container direction="column" justify="space-between">
          {Array.from({ length: 5 }).map((_, index) => (
            <Grid.Item key={index} xs={12}>
              <Placeholder.Rect height="30px" />
            </Grid.Item>
          ))}
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <>
      <ResponsiveContainer className="hc-pb-2x" height={250}>
        <BarChart
          data={graphData}
          margin={{ top: 11, right: 24 }}
          barSize={20}
          barGap={75}
          tabIndex={-1}
        >
          <CartesianGrid strokeDasharray="3, 3" />
          <XAxis
            dataKey="category_name"
            dy={7}
            tick={{ 'aria-hidden': true }}
            fontSize={12}
            tickFormatter={(value: string) =>
              `${value?.length > 10 ? value?.slice(0, 10) + '...' : value}`
            }
          />
          <YAxis tickFormatter={(value) => `${value}%`} fontSize={12} />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            formatter={(value: any) => `${roundToTwoDecimals(value)}%`}
          />
          <Bar
            name="Return Rate"
            dataKey="return_rate"
            fill="var(--canvas-font-color--interactive-contrast)"
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>

      <Grid.Container className="hc-mt-normal" align="center">
        <StyledCardContainer className="hc-ml-4x hc-pa-dense" />
        <Grid.Item>
          <p className="hc-txt-capitalize">Return Rate</p>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default CategoryReturnRateGraph
