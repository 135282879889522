import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FormikProps, withFormik } from 'formik'

import { PHONE_TYPE_MOBILE, PHONE_TYPE_OFFICE } from 'constants/phones'

import { allResponsibilities } from 'services/usersHelper'

import {
  createSellerContact,
  CreateSellerContactAction,
  editSellerContact,
  EditSellerContactAction,
} from 'store/seller/actionCreators'

import { CountryNameCode } from 'types/Country'
import SellerUser, {
  Entitlement,
  PhoneNumber,
  Role,
  RolesMap,
  VmmMetadata,
} from 'types/SellerUser'
import StoreState from 'types/state'

import CreateSellerContactForm, {
  FormValues,
  validationSchema as formValidation,
} from './CreateSellerContactForm'
import { getTelephoneCodeByCountry } from 'services/geographicalCodes'
import { flag, FlagName } from 'flag'

interface ComponentProps {
  isOpen: boolean
  isPending: boolean
  handleSubmit: any
  sellerId: string
  displayName: string
  contact?: SellerUser
  contactMobilePhone?: PhoneNumber
  contactOfficePhone?: PhoneNumber
  sellerVmmData?: VmmMetadata
  sellerEntitlement?: Entitlement
  createSellerContact: ({
    sellerId,
    contact,
    role,
  }: {
    sellerId: string
    contact: SellerUser
    role: Role
    responsibility: number[] | undefined
    primaryRole?: string
    selectedChannelPartner?: string
  }) => CreateSellerContactAction
  editSellerContact: ({
    contact,
    userId,
    role,
    responsibility,
    primaryRole,
    selectedChannelPartner,
  }: {
    contact: SellerUser | undefined
    userId: string
    role: Role | undefined
    responsibility: number[] | undefined
    primaryRole?: string
    selectedChannelPartner?: string
  }) => EditSellerContactAction
}

export type Props = ComponentProps & FormikProps<FormValues>

const isIDMIntegration = flag(FlagName.SMS_IDM_INTEGRATION)

export const CreateSellerContact = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: ({
    contact,
    contactMobilePhone,
    contactOfficePhone,
    sellerVmmData,
    sellerEntitlement,
  }) => ({
    entitlement: sellerEntitlement?.role ?? undefined,
    ...allResponsibilities.reduce((cur, responsibility) => {
      return {
        ...cur,
        [`responsibility-${responsibility}`]:
          (isIDMIntegration
            ? contact?.functional_responsibilities
            : sellerVmmData?.functional_responsibilities
          )?.includes(responsibility) ?? false,
      }
    }, {}),
    firstName: contact?.first_name ?? '',
    lastName: contact?.last_name ?? '',
    email: contact?.email ?? '',
    officeCountry:
      contactOfficePhone?.country_name_code ?? CountryNameCode.UNITED_STATES,
    mobileCountry:
      contactMobilePhone?.country_name_code ?? CountryNameCode.UNITED_STATES,
    officePhoneNumber: contactOfficePhone?.number ?? '',
    mobilePhoneNumber: contactMobilePhone?.number ?? '',
    primaryRole: '',
    selectedChannelPartner: '',
  }),
  handleSubmit: async (values, { props }) => {
    const {
      entitlement,
      firstName,
      lastName,
      email,
      officeCountry,
      mobileCountry,
      officePhoneNumber,
      mobilePhoneNumber,
      primaryRole,
      selectedChannelPartner,
      ...responsibilityCodes
    } = values

    const { sellerId } = props

    if (!entitlement) {
      return
    }

    const officeCountryCode = getTelephoneCodeByCountry(officeCountry)
    const mobileCountryCode = getTelephoneCodeByCountry(mobileCountry)

    const phoneNumbers = [
      ...(mobilePhoneNumber
        ? [
            {
              country_code: mobileCountryCode,
              country_name_code: mobileCountry,
              number: mobilePhoneNumber,
              type: PHONE_TYPE_MOBILE,
            },
          ]
        : []),
      ...(officePhoneNumber
        ? [
            {
              country_code: officeCountryCode,
              country_name_code: officeCountry,
              number: officePhoneNumber,
              type: PHONE_TYPE_OFFICE,
            },
          ]
        : []),
    ]

    const contact = {
      ...props.contact,
      email,
      first_name: firstName,
      last_name: lastName,
      phone_numbers: phoneNumbers,
    } as SellerUser

    const responsibilities: number[] = []
    Object.keys(responsibilityCodes).forEach((fieldName: string) => {
      const code = parseInt(fieldName.split('-')[1])
      if ((responsibilityCodes as Dictionary<boolean>)[fieldName]) {
        responsibilities.push(code)
      }
    })

    const responsibility = responsibilities.length
      ? responsibilities
      : undefined

    let shouldUpdateContact = false

    if (
      contact?.id &&
      entitlement !== (isIDMIntegration ? RolesMap.NONE : Role.NONE)
    ) {
      const originalRole = isIDMIntegration
        ? contact.role_types?.[0]
        : contact.entitlements?.find(
            (entitlement) => entitlement.resource_id === sellerId,
          )?.role

      const isPreviousRoleNone = originalRole === Role.NONE

      const hasMobileNumber = !!contact.phone_numbers?.some(
        (num) => num.type === 'MOBILE',
      )

      shouldUpdateContact = isPreviousRoleNone && hasMobileNumber
    }

    contact.id
      ? props.editSellerContact({
          contact:
            entitlement === (isIDMIntegration ? RolesMap.NONE : Role.NONE) ||
            shouldUpdateContact
              ? contact
              : undefined,
          userId: contact.id,
          role: entitlement,
          responsibility,
          primaryRole,
          selectedChannelPartner,
        })
      : props.createSellerContact({
          contact,
          role: entitlement,
          sellerId: props.sellerId,
          responsibility,
          primaryRole,
          selectedChannelPartner,
        })
  },
  enableReinitialize: true,
  validationSchema: formValidation,
})(CreateSellerContactForm)

const mapStateToProps = (state: StoreState) => {
  const { seller } = state
  const { currentSeller, isSellersPending: isPending } = seller

  return {
    isPending,
    sellerId: currentSeller!.id,
    displayName: currentSeller!.display_name ?? '',
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ createSellerContact, editSellerContact }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CreateSellerContact)
