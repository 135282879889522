import { useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'

import { OrderStatus } from 'types/Orders'
import { useOrdersDashboardStore } from 'v2/store'
import { getCurrentDateWithStartAndEndTime, startOfDay } from 'v2/utils/date'
import { currentSellerId, isRoleExternalUserSelector } from 'store/selectors'
import { getOrderStatusCounts } from 'v2/services/getOrderStatusCount'
import { getUnshippedOrdersAge } from 'v2/services/getUnshippedOrdersAge'

import CtaButtons from '../Header/ctaButtons'
import SummaryCards from './SummaryCards'

const BreakdownSummary = () => {
  const sellerId = useSelector(currentSellerId)
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const {
    view,
    selectedSellerId,
    ordersAtRiskCount,
    fillRate,
    updateOrdersAtRiskCount,
  } = useOrdersDashboardStore()

  // orders at risk tool tip count api call
  useEffect(() => {
    const getOrdersAtRisk = async () => {
      const response = await getUnshippedOrdersAge({
        params: {
          date: getCurrentDateWithStartAndEndTime(),
          sellerId: selectedSellerId,
        },
        sellerId: view === 'INTERNAL' ? null : sellerId,
      })
      updateOrdersAtRiskCount(response?.ordersAtRisk)
    }

    getOrdersAtRisk()
  }, [view, selectedSellerId, sellerId, updateOrdersAtRiskCount])

  const getBreakdownSummaryData = async () => {
    const resp = await getOrderStatusCounts({
      params: {
        orderStatus: [
          OrderStatus.PARTIALLY_SHIPPED,
          OrderStatus.ACKNOWLEDGED_BY_SELLER,
          OrderStatus.RELEASED_FOR_SHIPMENT,
        ],
        requestedShipmentDate: `/${startOfDay(new Date(), true)}`,
        sellerId: selectedSellerId,
      },
      sellerId: view === 'INTERNAL' ? null : sellerId,
    })
    return resp
  }

  // api call
  const { data, isFetching, isError } = useQuery({
    queryKey: ['BREAKDOWN_SUMMARY', selectedSellerId],
    queryFn: getBreakdownSummaryData,
  })

  const getFormattedData = useMemo(() => {
    if (!data) return []

    const myToolTipData = {
      UNSHIPPED: {
        count: 0,
        tooltip: 'Count of order IDs that contain atleast one unshipped item',
      },
      UNSHIPPED_PAST_DUE: {
        tooltip: `Orders at risk: ${ordersAtRiskCount}`,
        count: 0,
      },
      RELEASED_FOR_SHIPMENT: {
        tooltip: '',
        count: 0,
      },
      PARTIALLY_SHIPPED: {
        tooltip: '',
        count: 0,
      },
    }

    data.forEach((value: any) => {
      const status = value.order_status as keyof typeof myToolTipData
      if (myToolTipData[status]) {
        myToolTipData[status].count = value.count ?? 0
      }
    })

    const orderedData = Object.keys(myToolTipData).map((key: any) => {
      const ele = myToolTipData[key as keyof typeof myToolTipData]

      return { order_status: key, count: ele?.count, tooltip: ele.tooltip }
    })
    if (!isExternalUser)
      orderedData.push({
        order_status: 'FILL_RATE',
        count: fillRate ?? 0,
        tooltip: '',
      })
    return orderedData
  }, [data, ordersAtRiskCount, fillRate, isExternalUser])

  const getLoadingState = () => {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="flex-start">
          {Array.from({ length: isExternalUser ? 4 : 5 }).map((_, index) => (
            <Grid.Item key={index} xs={2} className="hc-pl-none">
              <Placeholder.Rect height="60px" emphasized />
            </Grid.Item>
          ))}
        </Grid.Container>
      </Placeholder>
    )
  }

  return (
    <Grid.Container direction="column" justify="space-between">
      <Grid.Item className="hc-pb-lg">
        <Grid.Container direction="row" justify="space-between" align="center">
          <Grid.Item>
            <p className="hc-fs-xl">Breakdown Summary</p>
          </Grid.Item>
          {view === 'EXTERNAL' && (
            <Grid.Item>
              <CtaButtons />
            </Grid.Item>
          )}
        </Grid.Container>
      </Grid.Item>

      <Grid.Item>
        {isError ? (
          <p className="hc-pv-dense hc-ta-left hc-clr-error">
            There was an error fetching the data
          </p>
        ) : isFetching ? (
          getLoadingState()
        ) : (
          <SummaryCards data={getFormattedData} />
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default BreakdownSummary
