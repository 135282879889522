import axios from 'axios'

import apiConfig from 'config/apiConfig'

interface SellerProductsType {
  sellerId: string
  productId: string
  payload: any
}

export const putSellerProduct = async ({
  sellerId,
  productId,
  payload,
}: SellerProductsType) => {
  try {
    const response = await axios.put(
      `${apiConfig.sms}/sellers/${sellerId}/products/${productId}`,
      payload,
    )

    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
