import { StyledChip } from '../styles'

interface StatusChipProps {
  backgroundColor: string
  textColor: string
  label: string
}

const StatusChip = ({ backgroundColor, textColor, label }: StatusChipProps) => {
  return (
    <StyledChip
      size="dense"
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      {label}
    </StyledChip>
  )
}

export default StatusChip
