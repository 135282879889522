import { Form } from 'formik'
import React, { useState, useEffect } from 'react'
import * as yup from 'yup'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import styled from '@emotion/styled'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import CheckboxField from 'components/common/form/CheckboxField'
import InputField from 'components/common/form/InputField'
import PhoneField, {
  tollFreeErrorMsg,
  tollFreeRegex,
} from 'components/common/form/PhoneField'
import RadioGroupField from 'components/common/form/RadioGroupField'
import Text from 'components/common/Text'

import {
  LABEL_FIRST_NAME,
  LABEL_LAST_NAME,
  LABEL_EMAIL,
  LABEL_PHONE_NUMBER_OFFICE,
  LABEL_PHONE_NUMBER_MOBILE,
  LABEL_ERROR_PHONE_NUMBER_MOBILE,
  LABEL_ERROR_PHONE_NUMBER_OFFICE,
  LABEL_MOBILE_COUNTRY_CODE,
  LABEL_OFFICE_COUNTRY_CODE,
} from 'constants/labels'

import { getResponsibilityLabel } from 'services/functionalResponsibilities'
import { allResponsibilities } from 'services/usersHelper'
import { ValidationSchema } from 'services/validation'

import { CountryNameCode } from 'types/Country'
import SellerUser, { Role, RolesMap } from 'types/SellerUser'

import { Props } from './CreateSellerContact'
import {
  ChannelPartnerType,
  getChannelPartners,
} from 'v2/services/getChannelPartners'
import SelectField from 'components/common/form/SelectField'
import { flag, FlagName } from 'flag'
import {
  getPortalAccessLevelOptions,
  PortalAccessLevelType,
} from 'v2/services/getPortalAccessLevelOptions'

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export interface FormValues {
  entitlement: Role | undefined
  firstName: string
  lastName: string
  email: string
  officeCountry: string
  mobileCountry: string
  officePhoneNumber: string
  mobilePhoneNumber: string
  primaryRole: string
  selectedChannelPartner: string
}

export const validationSchema: ValidationSchema = yup.object().shape({
  entitlement: yup.string().required(),
  firstName: yup.string().label(LABEL_FIRST_NAME).required(),
  lastName: yup.string().label(LABEL_LAST_NAME).required(),
  email: yup.string().label(LABEL_EMAIL).email().required(),
  primaryRole: yup.string().when(['entitlement'], {
    is: (entitlement: any) =>
      flag(FlagName.SMS_IDM_INTEGRATION) &&
      entitlement !== RolesMap.NONE &&
      entitlement === '',
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
  selectedChannelPartner: yup.string().when(['primaryRole', 'entitlement'], {
    is: (primaryRole: string, entitlement: any) =>
      primaryRole === 'CP' &&
      flag(FlagName.SMS_IDM_INTEGRATION) &&
      entitlement !== RolesMap.NONE,
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
  officeCountry: yup.string().label(LABEL_OFFICE_COUNTRY_CODE),
  officePhoneNumber: yup.string().when(['officeCountry'], {
    is: (officeCountry: string) =>
      officeCountry !== CountryNameCode.UNITED_STATES,
    then: yup
      .string()
      .required()
      .label(LABEL_PHONE_NUMBER_OFFICE)
      .min(8)
      .max(20),
    otherwise: yup
      .string()
      .required()
      .label(LABEL_PHONE_NUMBER_OFFICE)
      .length(14, LABEL_ERROR_PHONE_NUMBER_OFFICE),
  }),
  mobileCountry: yup.string().label(LABEL_MOBILE_COUNTRY_CODE),
  mobilePhoneNumber: yup.string().when(['entitlement'], {
    is: (entitlement: any) =>
      entitlement ===
      (flag(FlagName.SMS_IDM_INTEGRATION) ? RolesMap.NONE : Role.NONE),
    then: yup.string(),
    otherwise: yup.string().when(['mobileCountry'], {
      is: (mobileCountry: string) =>
        mobileCountry === CountryNameCode.UNITED_STATES,
      then: yup
        .string()
        .label(LABEL_PHONE_NUMBER_MOBILE)
        .test({
          name: 'is-not-toll-freetoll',
          message: tollFreeErrorMsg,
          test(this: yup.TestContext, value: any) {
            if (!value) {
              return true
            }

            return !tollFreeRegex.test(value)
          },
        })
        .length(14, LABEL_ERROR_PHONE_NUMBER_MOBILE)
        .required(),
      otherwise: yup
        .string()
        .label(LABEL_PHONE_NUMBER_MOBILE)
        .test({
          name: 'is-not-toll-freetoll',
          message: tollFreeErrorMsg,
          test(this: yup.TestContext, value: any) {
            if (!value) {
              return true
            }
            return !tollFreeRegex.test(value)
          },
        })
        .min(8)
        .max(20)
        .required(),
    }),
  }),
})

const disableMobile = (contact?: SellerUser) => {
  if (!contact) {
    return false
  }

  const mobilePhone = contact.phone_numbers?.find((phone) => {
    return phone.type === 'MOBILE'
  })

  return !!mobilePhone
}

const portalAccessLevelOptions = [
  {
    label: 'Administrator',
    description: 'Can view, edit, upload, & download.',
    value: Role.ADMIN,
  },
  {
    label: 'Read-Only',
    description: 'Can view & download content.',
    value: Role.READ,
  },
  {
    label: 'None (Contact)',
    description:
      'Cannot access portal but can be contacted for support issues.',
    value: Role.NONE,
  },
]

const CreateSellerContactForm = ({
  isOpen,
  isPending,
  isValid,
  dirty,
  handleSubmit,
  contact,
  sellerId,
  displayName,
  sellerEntitlement,
  values,
  errors,
  touched,
}: Props) => {
  const isIDMIntegration = flag(FlagName.SMS_IDM_INTEGRATION)
  const isIDMRoleNone = isIDMIntegration ? RolesMap.NONE : Role.NONE
  const [officeCountry, setOfficeCountry] = React.useState<string>(
    CountryNameCode.UNITED_STATES,
  )
  const [mobileCountry, setMobileCountry] = React.useState<string>(
    CountryNameCode.UNITED_STATES,
  )
  const [officePhoneError, setOfficePhoneError] = React.useState('')
  const [mobilePhoneError, setMobilePhoneError] = React.useState('')
  const [displayMobile, setDisplayMobile] = React.useState(
    sellerEntitlement ? sellerEntitlement.role !== isIDMRoleNone : false,
  )
  const [userEditable, setUserEditable] = React.useState(
    sellerEntitlement ? sellerEntitlement.role === isIDMRoleNone : true,
  )

  const isMobileDisabled = disableMobile(contact)

  const [listOfChannelPartners, setListOfChannelPartners] =
    useState<ChannelPartnerType[]>()
  const [externalAgency, setExternalAgency] = useState<ChannelPartnerType>()
  const [selectedChannelPartner, setSelectedChannelPartner] = useState('')
  const [userPrimaryRole, setUserPrimaryRole] = useState('')

  const [portalAccessLevelOptionsAPI, setPortalAccessLevelOptionsAPI] =
    useState<PortalAccessLevelType[]>()

  useEffect(() => {
    if (isIDMIntegration) {
      getChannelPartners()
        .then((res) => {
          setListOfChannelPartners(res)
        })
        .catch((err) => {
          // need to handle error case
          return err
        })

      getPortalAccessLevelOptions()
        .then((res) => {
          const sortedResponse = res.sort(
            (a: PortalAccessLevelType, b: PortalAccessLevelType) =>
              Number(b.id) - Number(a.id),
          )
          setPortalAccessLevelOptionsAPI(sortedResponse)
        })
        .catch((err) => {
          // need to handle error case
          return err
        })
    }
  }, [isIDMIntegration])

  useEffect(() => {
    const externalPartner =
      listOfChannelPartners?.filter(
        (partner) => partner.partner_org_type === 'EXTERNAL_AGENT',
      ) ?? []
    setExternalAgency(externalPartner[0])
  }, [listOfChannelPartners])

  const primaryRoles = [
    {
      label: 'Channel Partner',
      description: 'Choose from list of Channel Partners',
      value: 'CP',
    },
    {
      label: 'External Agent',
      description: externalAgency?.id,
      value: externalAgency?.id,
    },
    {
      label: 'Seller Internal Team',
      description: displayName,
      value: sellerId,
    },
  ]

  const onUserPrimaryRoleChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setUserPrimaryRole(value)
  }

  const onChannelPartnerChange = (value: string) => {
    setSelectedChannelPartner(value)
  }

  const onPortalAccessLevelChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setDisplayMobile(value !== isIDMRoleNone)
    setUserEditable(value === isIDMRoleNone)
  }

  const title = contact ? `Manage Details` : 'Add New'

  const hasMobileNumber = contact?.phone_numbers?.find(
    (number) => number.type === 'MOBILE',
  )

  React.useEffect(() => {
    setOfficeCountry(values.officeCountry)

    const error = errors?.officePhoneNumber

    if (touched['officePhoneNumber'] && error) {
      setOfficePhoneError(error)
    } else {
      setOfficePhoneError('')
    }
  }, [values.officeCountry, errors, touched])

  React.useEffect(() => {
    setMobileCountry(values.mobileCountry)

    const error = errors?.mobilePhoneNumber

    if (touched['mobilePhoneNumber'] && error) {
      setMobilePhoneError(error)
    } else {
      setMobilePhoneError('')
    }
  }, [values.mobileCountry, errors, touched])

  const isSaveDisabled = () => {
    if (isPending) {
      return true
    }
    if (contact?.id) {
      return !isValid || !dirty
    } else {
      return !isValid || !dirty
    }
  }

  return (
    <DialogContainer
      title={title}
      maxWidth="md"
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
      isSubmitDisabled={isSaveDisabled()}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <StyledPaper elevation={0}>
              <Text>Portal Access Level*</Text>
              <RadioGroupField
                name="entitlement"
                options={
                  (isIDMIntegration
                    ? portalAccessLevelOptionsAPI
                    : portalAccessLevelOptions) ?? []
                }
                disabled={isPending}
                onChange={onPortalAccessLevelChange}
                required
              />
            </StyledPaper>
            <StyledPaper elevation={0}>
              <Text>Responsible for handling issues related to</Text>
              <Typography variant="caption">
                (Optional: Select 1 or more)
              </Typography>
              {allResponsibilities.map((responsibility) => (
                <div key={responsibility}>
                  <CheckboxField
                    name={`responsibility-${responsibility}`}
                    label={getResponsibilityLabel(responsibility)}
                    disabled={isPending}
                  />
                </div>
              ))}
            </StyledPaper>
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputField
                  required
                  name="firstName"
                  label={LABEL_FIRST_NAME}
                  disabled={isPending || (!!contact && !userEditable)}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  required
                  name="lastName"
                  label={LABEL_LAST_NAME}
                  disabled={isPending || (!!contact && !userEditable)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  required
                  name="email"
                  label={LABEL_EMAIL}
                  disabled={isPending || !!contact}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneField
                  required
                  selectName="officeCountry"
                  name="officePhoneNumber"
                  id="office"
                  label={LABEL_PHONE_NUMBER_OFFICE}
                  disabled={isPending || (!!contact && !userEditable)}
                  country={officeCountry}
                  error={officePhoneError}
                />
              </Grid>
              {displayMobile && (
                <Grid item xs={12}>
                  <PhoneField
                    required
                    selectName="mobileCountry"
                    name="mobilePhoneNumber"
                    id="mobile"
                    label={LABEL_PHONE_NUMBER_MOBILE}
                    disabled={
                      isPending ||
                      (!!contact && !userEditable && isMobileDisabled)
                    }
                    country={mobileCountry}
                    error={mobilePhoneError}
                  />
                </Grid>
              )}
            </Grid>
            {isIDMIntegration && displayMobile && !hasMobileNumber && (
              <>
                <Grid item xs={12} className="hc-pt-md">
                  <StyledPaper elevation={0}>
                    <Text>User Primarily Belongs To *</Text>
                    <RadioGroupField
                      name="primaryRole"
                      options={primaryRoles}
                      disabled={
                        isPending ||
                        (!!contact && !userEditable && isMobileDisabled)
                      }
                      onChange={onUserPrimaryRoleChange}
                      required
                    />
                  </StyledPaper>
                </Grid>
                {userPrimaryRole === 'CP' && (
                  <Grid item xs={12}>
                    <StyledPaper elevation={0}>
                      <Text>Select Channel Partner Name *</Text>
                      <SelectField
                        id="selected-channel-partner"
                        name="selectedChannelPartner"
                        valueName={selectedChannelPartner}
                        options={
                          listOfChannelPartners?.map((partner) => partner.id) ??
                          []
                        }
                        onChange={() => onChannelPartnerChange}
                      />
                    </StyledPaper>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Form>
    </DialogContainer>
  )
}

export default CreateSellerContactForm
