import {
  add,
  sub,
  isBefore,
  differenceInDays,
  parseISO,
  isWithinInterval,
  startOfDay as startOfDayDateFns,
  endOfDay as endOfDayDateFns,
} from 'date-fns'

/**
 * Adds the specified number of days to the given date.
 *
 * @param date - The original date to which days will be added.
 * @param days - The number of days to add to the given date.
 * @returns A new Date object representing the date after adding the specified number of days.
 */
export function getDateAddedByDays(date: Date, days: number): Date {
  return new Date(add(date, { days }))
}

/**
 * Subtracts a specified number of days from a given date.
 *
 * @param date - The original date from which days will be subtracted.
 * @param days - The number of days to subtract from the date.
 * @returns A new Date object representing the date after subtracting the specified number of days.
 */
export function getDateSubtractedByDays(date: Date, days: number): Date {
  return new Date(sub(date, { days }))
}

/**
 * Adds the specified number of years to the given date.
 *
 * @param date - The original date to which years will be added.
 * @param years - The number of years to add to the given date.
 * @returns A new Date object representing the date after adding the specified number of years.
 */
export function getDateAddedByYears(date: Date, years: number): Date {
  return new Date(add(date, { years }))
}

/**
 * Subtracts the specified number of years from the given date.
 *
 * @param date - The original date from which years will be subtracted.
 * @param years - The number of years to subtract from the given date.
 * @returns A new Date object representing the date after subtracting the specified number of years.
 */
export function getDateSubtractedByYears(date: Date, years: number): Date {
  return new Date(sub(date, { years }))
}
/**
 *
 * Subtracts the specified number of months from the given date.
 *
 * @param {Date} date
 * @param {number} months
 * @return {*}  {Date}
 */
export function getDateSubtractedByMonths(date: Date, months: number): Date {
  return new Date(sub(date, { months }))
}

/**
 * Gets the fiscal year for a given date.
 * The fiscal year starts on February 1st. If the given date is before February 1st,
 * the function returns the previous year as the fiscal year.
 *
 * Target's fiscal year starts on February 1st and ends on January 31st of the following year.
 *
 * @param date - The date for which to determine the fiscal year.
 * @returns The fiscal year as a number.
 */
export const getTargetFiscalYear = (date: Date): number => {
  const year = date.getFullYear()

  const fiscalYearStart = new Date(year, 1, 1) // February 1 (month is 0-indexed)

  // If the current date is before February 1st, return the previous year as the fiscal year
  if (isBefore(date, fiscalYearStart)) {
    return year - 1
  }
  return year
}

/**
 * Calculates the difference in days between two dates.
 *
 * @param date1 - The first date, either as a Date object or a string.
 * @param date2 - The second date, either as a Date object or a string.
 * @returns The number of days between the two dates.
 */
export const getDifferenceInDays = (
  date1: Date | string,
  date2: Date | string,
): number => {
  const dateOne = new Date(date1)
  const dateTwo = new Date(date2)

  return differenceInDays(dateTwo, dateOne)
}

/**
 * Gives the date after parsing through a ISO Date.
 *
 * @param date - The ISO date that needs to be parsed.
 * @returns The parsed date in the local time zone
 */
export const parseISODate = (date: string): Date => {
  return parseISO(date)
}

/**
 * getCSTDateAndTime() method is used to convert time to CST.
 *
 * Date string needs to be ISO.
 *
 * @param date
 * @returns
 *
 */
export const getCSTDateAndTime = (
  date: Date | number,
  dateStyle?: 'full' | 'long' | 'medium' | 'short' | undefined,
) => {
  if (!date) {
    throw new Error(
      'Invalid argument: A valid date or timestamp must be provided.',
    )
  }

  const cstTime = new Date(date).toLocaleString('en-US', {
    timeZone: 'America/Chicago',
    dateStyle,
  })

  return dateStyle ? cstTime : cstTime.toLocaleString()
}

/**
 * getPSTDateAndTime() method is used to convert time to PST.
 *
 * Date string needs to be ISO.
 *
 * @param date
 * @returns
 *
 */
export const getPSTDateAndTime = (date: Date | string) => {
  if (!date) {
    throw new Error(
      'Invalid argument: A valid date or timestamp must be provided.',
    )
  }

  const pstTime = new Date(date).toLocaleString('en-US', {
    timeZone: 'Etc/GMT+8', // This forces UTC-8 (PST) permanently, so it does not switch to PDT after the DST change.
  })

  return new Date(pstTime)
}

/**
 * getCurrentCSTDateAndTime method return current CST date and time
 * @returns
 */
export const getCurrentCSTDateAndTime = () =>
  new Date(getCSTDateAndTime(Date.now()))

/**
 * Checks if a provided date is within a specified date range.
 *
 * @param providedDate - The date to check, in ISO string format.
 * @param startDate - The start date of the range, in ISO string format.
 * @param endDate - The end date of the range, in ISO string format.
 * @returns A boolean indicating whether the provided date is within the range.
 */
export const isDateWithinRange = (
  date: string,
  startDate: string,
  endDate: string,
) => {
  const inputDate = parseISO(date)
  const start = parseISO(startDate)
  const end = parseISO(endDate)

  return isWithinInterval(inputDate, {
    start,
    end,
  })
}

/**
 * Returns current date's start and time with date
 * @param {boolean} [isStart=false]
 * @param {boolean} [isEnd=false]
 * @return {*}  {string}
 */
export const getCurrentDateWithStartAndEndTime = (
  isStart = false,
  isEnd = false,
): string => {
  const startDate = new Date()
  startDate.setHours(0, 0, 0, 0)
  if (isStart) return startDate.toISOString()

  const endDate = new Date()
  endDate.setHours(23, 59, 59, 999)
  if (isEnd) return endDate.toISOString()

  const formattedDate = `${startDate.toISOString()}/${endDate.toISOString()}`
  return formattedDate
}
/**
 * returns the start of the date
 *
 * @param {Date} date
 * @param {boolean} [isISOString=false]
 * @return {*}  {(string | Date)}
 */
export function startOfDay(date: Date, isISOString = false): string | Date {
  if (date === undefined) return new Date()
  if (isISOString) return startOfDayDateFns(new Date(date)).toISOString()

  return startOfDayDateFns(new Date(date))
}
/**
 * returns the end of the data
 *
 * @param {Date} date
 * @param {boolean} [isISOString=false]
 * @return {*}  {(string | Date)}
 */
export function endOfDay(date: Date, isISOString = false): string | Date {
  if (date === undefined) return new Date()
  if (isISOString) return endOfDayDateFns(new Date(date)).toISOString()

  return endOfDayDateFns(new Date(date))
}
