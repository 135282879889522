import { ErrorResponse } from 'types/HttpError'
import axios from 'axios'

import apiConfig from 'config/apiConfig'
import { EditUserInfoParams } from 'store/user/actionCreators'

import updateObjectValues from './updateObjectValues'

import SellerUser, {
  Entitlement,
  PhoneNumber,
  Role,
  VmmMetadata,
} from 'types/SellerUser'
import { PHONE_TYPE_MOBILE, PHONE_TYPE_OFFICE } from 'constants/phones'
import { FlagName, flag } from 'flag'

export async function getSellerUser(
  sellerUserId: string,
): Promise<SellerUser | undefined> {
  try {
    const response = await axios.get(
      `${apiConfig.sms}/seller_users/${sellerUserId}`,
    )

    return response.data
  } catch (e) {
    console.error(`get seller user with ID ${sellerUserId} error: ${e}`)
  }
}

export function searchSellerUser(email: string): Promise<SellerUser> {
  const config = {
    params: {
      email,
    },
  }

  return axios.get(`${apiConfig.sms}/seller_users`, config).then((res) => {
    return res.data[0]
  })
}

export function getSellerUsers(sellerId: string): Promise<SellerUser[]> {
  const isIDMIntegration = flag(FlagName.SMS_IDM_INTEGRATION)
  const config = {
    params: {
      per_page: 200,
      ...(isIDMIntegration ? {} : { seller_id: sellerId }),
    },
  }

  const response = isIDMIntegration
    ? axios
        .get(
          `${apiConfig.sellerUsers}/sellers/${sellerId}/seller_users`,
          config,
        )
        .then((res) => res.data.items)
    : axios.get(`${apiConfig.sms}/seller_users`, config).then((res) => res.data)

  return response
}

export function getSellerEntitlements(
  sellerId: string,
): Promise<Entitlement[]> {
  return axios
    .get(`${apiConfig.sms}/sellers/${sellerId}/entitlements`)
    .then((res) => res.data)
}

export function getUserEntitlements(): Promise<Entitlement[]> {
  return axios.get(`${apiConfig.sms}/entitlements`).then((res) => res.data)
}

export function mapUsersToEntitlements(
  users: SellerUser[],
  entitlements: Entitlement[],
): SellerUser[] {
  return users.map((user) => {
    const entitlement = entitlements.find((e) => e.seller_user_id === user.id)

    if (!entitlement) return user

    return {
      ...user,
      entitlements: [entitlement],
    }
  })
}

export async function fetchUserEntitlements(
  userId: string,
  sellerIds: string[],
): Promise<Entitlement[]> {
  const entitlements = await getUserEntitlements()

  const mappedEntitlements = sellerIds.map((id) => {
    const entitlement = entitlements.find((e) => e.resource_id === id)

    let userEntitlement: Entitlement = {
      seller_user_id: userId,
      resource_id: id,
      role: Role.READ,
    }

    if (entitlement) {
      userEntitlement = {
        ...entitlement,
        seller_user_id: userId,
      }
    }

    return userEntitlement
  })

  return mappedEntitlements
}

export async function updateSellerUser(
  contact: SellerUser,
): Promise<SellerUser> {
  const data = updateObjectValues(contact, '', null)
  const response = contact.id
    ? await axios.put(`${apiConfig.sms}/seller_users/${contact.id}`, data)
    : await axios.post(`${apiConfig.sms}/seller_users`, data, {
        ignoredStatuses: [
          {
            status: 409,
            unless: (errResponse: ErrorResponse) => {
              if (
                errResponse.response.data.errors?.[0] ===
                'Email already used within downstream system'
              ) {
                return 'This email is already in use in Partners Online (POL). Please enter a unique email address.'
              } else if (
                errResponse.response.data.errors?.[0] ===
                `Object of type SellerUser with login id '${contact.email}' exists already`
              ) {
                window.alert(
                  `This user is already setup in Target Plus, the original phone numbers, names and password attached to the account will apply. If the user would like to update their details, they must sign into the portal and navigate to "My Profile"`,
                )
              }
            },
          },
        ],
      } as any)

  return response.data
}

export async function updateSellerUserEntitlement({
  sellerId,
  userId,
  role,
}: {
  sellerId: string
  userId: string
  role: Role
}): Promise<Entitlement[]> {
  const data = [{ role }]

  const response = await axios.put(
    `${apiConfig.sms}/sellers/${sellerId}/entitlements/${userId}`,
    data,
  )

  return response.data
}

export async function updateEntitlementByEmail({
  sellerId,
  email,
  role,
}: {
  sellerId: string
  email: string
  role: Role
}): Promise<Entitlement[]> {
  const data = {
    email,
    roles: [role],
  }

  const response = await axios.post(
    `${apiConfig.sms}/sellers/${sellerId}/email_entitlements`,
    data,
    {
      ignoredStatuses: [
        {
          status: 400,
          unless: (errResponse: ErrorResponse) => {
            if (
              errResponse.response.data.errors?.[0] ===
              'Missing parameters in request: phone_numbers must contain at least one mobile phone number'
            ) {
              return 'User cannot be updated. Please open a case to contact support.'
            }
          },
        },
      ],
    } as any,
  )

  return response.data.roles
}

export async function deleteSellerUserEntitlement({
  sellerId,
  userId,
}: {
  sellerId: string
  userId: string
}) {
  try {
    flag(FlagName.SMS_IDM_INTEGRATION)
      ? await axios.delete(
          `${apiConfig.sellerUsers}/sellers/${sellerId}/seller_users/${userId}`,
        )
      : await axios.delete(
          `${apiConfig.sms}/sellers/${sellerId}/entitlements/${userId}`,
        )
  } catch (e: any) {
    throw new Error(
      `Failed to delete the partner ${sellerId} entitlement for user ${userId}: ${e.message}`,
    )
  }
}

export async function updateSellerUserFunctionalResponsibility({
  sellerId,
  userId,
  data,
}: {
  sellerId: string
  userId: string
  data: number[]
}) {
  const response = await axios.put(
    `${apiConfig.sms}/sellers/` +
      `${sellerId}/functional_responsibilities/${userId}`,
    data,
  )

  return response.data
}

export function updateUserInfo({
  userId,
  firstName,
  lastName,
  email,
  officeCountry,
  officePhoneCode,
  officePhoneNumber,
  mobileCountry,
  mobilePhoneCode,
  mobilePhoneNumber,
}: EditUserInfoParams) {
  const phoneNumbers: PhoneNumber[] = [
    {
      number: mobilePhoneNumber,
      country_code: mobilePhoneCode,
      country_name_code: mobileCountry,
      type: PHONE_TYPE_MOBILE,
    },
  ]

  if (officePhoneNumber) {
    phoneNumbers.push({
      number: officePhoneNumber,
      country_code: officePhoneCode,
      country_name_code: officeCountry,
      type: PHONE_TYPE_OFFICE,
    })
  }

  const userData = {
    first_name: firstName,
    last_name: lastName,
    email,
    phone_numbers: phoneNumbers,
  }

  return axios
    .put(`${apiConfig.sms}/seller_users/${userId}`, userData)
    .then((res: { data: SellerUser }): SellerUser => {
      return res.data
    })
}

export function editContactPermissions({
  sellerId,
  userId,
  contacts,
  entitlement,
  responsibility,
}: {
  sellerId: string
  userId: string
  contacts: SellerUser[]
  entitlement: Entitlement[] | undefined
  responsibility: number[] | undefined
}): SellerUser[] {
  if (!entitlement && !responsibility) {
    return contacts
  }

  return contacts.map((contact) => {
    const newContact = { ...contact }
    if (newContact.id === userId) {
      if (entitlement) {
        newContact.entitlements = editEntitlementForContact(
          contact,
          entitlement[0],
        )
      }

      if (responsibility) {
        newContact.vmm_metadata = editResponsibilityForContact(
          contact,
          sellerId,
          responsibility,
        )
      }
    }

    return newContact
  })
}

export function editEntitlementForContact(
  contact: SellerUser,
  entitlement: Entitlement,
): Entitlement[] {
  if (!contact.entitlements || !contact.entitlements.length) {
    return [entitlement]
  }

  return contact.entitlements.map((e) => {
    if (e.resource_id === entitlement.resource_id) {
      return {
        ...e,
        ...entitlement,
      }
    }

    return e
  })
}

export function editResponsibilityForContact(
  contact: SellerUser,
  sellerId: string,
  responsibility: number[],
): VmmMetadata[] {
  if (!contact.vmm_metadata || !contact.vmm_metadata.length) {
    // The /seller_users api has a bug that returns all the vmm metadata associated
    // with a user instead of just the vmm metadata scoped to the seller.
    // We add seller_id for lookups in components to match user to seller
    return [
      {
        seller_id: sellerId,
        functional_responsibilities: responsibility,
      },
    ]
  }

  return contact.vmm_metadata.map((item) => {
    if (item.seller_id === sellerId) {
      return {
        ...item,
        functional_responsibilities: responsibility,
      }
    }

    return item
  })
}
