import axios from 'axios'

import { url } from 'v2/utils'
import apiConfig from 'config/apiConfig'

interface Params {
  expand?: string
}
interface SellerProductsByIdType {
  sellerId: string
  productId: string
  params?: Params
}

export const getSellerProductsById = async ({
  sellerId,
  productId,
  params = {},
}: SellerProductsByIdType) => {
  try {
    const queryParams = url.buildURLQueryParams({
      expand: params.expand,
    })

    const response = await axios.get(
      `${apiConfig.sms}/sellers/${sellerId}/products/${productId}?${queryParams}`,
    )

    return response.data
  } catch (err) {
    return Promise.reject(err)
  }
}
