import axios from 'axios'
import apiConfig from 'config/apiConfig'

export interface PortalAccessLevelType {
  id: string
  label: string
  description: string
  role_type: string
  value: string
}

export const getPortalAccessLevelOptions = async () => {
  try {
    const res = await axios.get(`${apiConfig.sellerUsers}/roles`)
    const transformRes = res.data.map((role: PortalAccessLevelType) => {
      return { ...role, value: role.id }
    })
    return transformRes
  } catch (err) {
    return Promise.reject(err)
  }
}
