import { create } from 'zustand'
import { UpdatedPromotion } from 'types/Promotion'

interface PromotionsState {
  selectedPromotion: UpdatedPromotion | null
  uploadedFile: File | null
  isNewFileUpload: boolean
  updateSelectedPromotion: (selectedPromotion: UpdatedPromotion | null) => void
  updateFile: (uploadedFile: File | null) => void
  updateIsNewFileUpload: (isNewFileUpload: boolean) => void
}

const use3pPromotionsStore = create<PromotionsState>((set) => ({
  selectedPromotion: null,
  uploadedFile: null,
  isNewFileUpload: false,
  updateSelectedPromotion: (selectedPromotion: UpdatedPromotion | null) =>
    set({ selectedPromotion }),
  updateFile: (uploadedFile: File | null) => set({ uploadedFile }),
  updateIsNewFileUpload: (isNewFileUpload: boolean) => set({ isNewFileUpload }),
}))

export default use3pPromotionsStore
