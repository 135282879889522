import axios from 'axios'

import { url } from 'v2/utils'
import apiConfig from 'config/apiConfig'

interface Params {
  perPage?: number
  itemTypeId?: number
  target?: 'EXTERNAL' | 'INTERNAL'
}

export const getTaxonomiesAssignments = async (params: Params) => {
  try {
    const queryParams = url.buildURLQueryParams({
      target: params.target,
      taxonomy_id: params.itemTypeId,
      per_page: params.perPage,
    })

    const { data } = await axios.get(
      `${apiConfig.nexus}/assignments?${queryParams}`,
    )

    return data
  } catch (err) {
    return Promise.reject(err)
  }
}
