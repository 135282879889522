import { useEffect, useMemo, useState, useCallback } from 'react'
import { Button, Input, Placeholder } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  FilesIcon,
  ErrorFilledIcon,
} from '@enterprise-ui/icons'

import { useTGIDStore } from 'v2/store'
import { useAsyncAttributeValueSelect } from 'v2/hooks/autoComplete'
import { getSellerProductsById } from 'v2/services/getSellerProductsById'
import { getTaxonomiesAssignments } from 'v2/services/getTaxonomiesAssignments'
import { StyledTable } from '../styles'

interface Props {
  setIsRecommendedDisabled: (disable: boolean) => void
}

export const ItemReviewTable = ({ setIsRecommendedDisabled }: Props) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const { renderComponent } = useAsyncAttributeValueSelect()
  const {
    selectedItem,
    updateDetailsView,
    detailsView,
    updateRecommendedValue,
  } = useTGIDStore()
  const { productId, sellerId, itemTypeId } = selectedItem ?? {}

  useEffect(() => {
    if (productId && itemTypeId) {
      setIsFetching(true)
      Promise.all([
        getSellerProductsById({
          productId,
          sellerId,
          params: { expand: 'fields' },
        }),
        getTaxonomiesAssignments({
          itemTypeId,
          perPage: 200,
          target: 'EXTERNAL',
        }),
      ])
        .then(([productData, taxonomiesData]) => {
          const productAttributesData = productData?.fields
          const taxonomiesAttributesData = taxonomiesData?.map(
            (product: any) => ({
              ...product.attribute,
              required: product.required,
            }),
          )
          updateDetailsView(taxonomiesAttributesData, productAttributesData)
          setIsFetching(false)
        })
        .finally(() => setIsFetching(false))
    }
  }, [itemTypeId, productId, selectedItem, sellerId, updateDetailsView])

  const columnDefs = useMemo(
    () => [
      {
        field: 'attributeName',
        headerName: 'Attribute Name',
        width: 120,
        isRowHeader: true,
      },
      {
        field: 'originalValue',
        headerName: 'Original Submitted Value',
      },
      {
        field: 'copy',
        headerName: 'Action',
        width: 10,
        align: 'center',
      },
      {
        field: 'recommendedValue',
        headerName: 'Recommended Value to Publish',
      },
    ],
    [],
  )

  const handleRecommendedValueChange = useCallback(
    (index: number) => (newValue: any) => {
      const value = newValue?.value ?? null
      setIsRecommendedDisabled(false)
      updateRecommendedValue(value, index)
    },
    [updateRecommendedValue, setIsRecommendedDisabled],
  )

  const handleCopyText = useCallback(
    (value: string, index: number) => {
      setIsRecommendedDisabled(false)
      updateRecommendedValue(value, index)
    },
    [updateRecommendedValue, setIsRecommendedDisabled],
  )

  const rowData = useMemo(() => {
    const errorMessage = 'Mandatory attribute cannot be empty.'

    return detailsView.map((ele, index: number) => {
      const { attribute, original, recommended } = ele
      return {
        attributeName: {
          cellDisplay: (
            <>
              <span className="hc-txt-capitalize hc-fs-sm">
                {attribute.name}
              </span>{' '}
              {attribute.required ? (
                <span className="hc-clr-error hc-fs-lg">*</span>
              ) : (
                ''
              )}
            </>
          ),
        },
        originalValue: {
          cellDisplay: (
            <span className="hc-fs-sm">
              {original.value || (
                <em className="hc-clr-grey01">No Value Present</em>
              )}
            </span>
          ),
        },

        copy: {
          cellDisplay: (
            <>
              <Button
                aria-label="copy"
                type="ghost"
                iconOnly
                disabled={!original.value}
                onClick={() => handleCopyText(original.value, index)}
              >
                <EnterpriseIcon
                  color={original.value ? 'darkBlue' : 'grey'}
                  icon={FilesIcon}
                />
              </Button>
            </>
          ),
        },
        recommendedValue: {
          cellDisplay: (
            <>
              {attribute.type === 'VALUE' ? (
                renderComponent({
                  attributeId: attribute.id,
                  handleChange: handleRecommendedValueChange(index),
                  key: `typeahead-${index}`,
                  selectedOption: recommended.value
                    ? { value: recommended.value, label: recommended.value }
                    : null,
                  erroMessage:
                    attribute.required && !recommended.value
                      ? errorMessage
                      : '',
                })
              ) : (
                <>
                  <Input.Textarea
                    size="dense"
                    value={recommended.value ?? ''}
                    onChange={(e: any) => handleCopyText(e.target.value, index)}
                  />
                  {attribute.required && !recommended.value && (
                    <p className="hc-fs-xs hc-clr-error">
                      <EnterpriseIcon size="inline" icon={ErrorFilledIcon} />{' '}
                      {errorMessage}
                    </p>
                  )}
                </>
              )}
            </>
          ),
          cellValue: recommended.value,
        },
      }
    })
  }, [
    detailsView,
    handleRecommendedValueChange,
    handleCopyText,
    renderComponent,
  ])

  if (isFetching) {
    const placeholder = <Placeholder.Rect emphasized height="45px" />

    return (
      <StyledTable
        data={{
          columnDefs,
          rowData: Array(5).fill({
            attributeName: { cellDisplay: placeholder },
            originalValue: { cellDisplay: placeholder },
            copy: { cellDisplay: placeholder },
            recommendedValue: { cellDisplay: placeholder },
          }),
        }}
      ></StyledTable>
    )
  }

  return (
    <StyledTable
      data={{
        columnDefs,
        rowData,
      }}
      roundCorner
    ></StyledTable>
  )
}

export default ItemReviewTable
