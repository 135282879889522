import { Divider, Grid, Heading, Modal } from '@enterprise-ui/canvas-ui-react'
import { ReturnRateViewType } from 'v2/store/useReturnInsightsStore'

import { ReturnInformation } from './ReturnInformation'
import ReturnRateGraph from './ReturnRateGraph'

interface Props {
  isMaximized: boolean
  cardId: number
  view: ReturnRateViewType
  handleViewClick: (view: ReturnRateViewType) => void
  setIsMaximized: (isMaximized: boolean) => void
}

export const ReturnRateModal = ({
  isMaximized,
  setIsMaximized,
  view,
  handleViewClick,
  cardId,
}: Props) => {
  const HeadingText = (
    <Heading size={4} className="hc-pt-md hc-ph-md">
      Return Rate
    </Heading>
  )
  return (
    <Modal
      headingText={HeadingText}
      isVisible={isMaximized}
      onRefuse={() => setIsMaximized(false)}
    >
      <p className="hc-clr-grey02 hc-fs-xs hc-ph-xl">
        % return sales for the orders that were placed in the selected time
        period
      </p>
      <Divider />
      <Grid.Container className="hc-pa-normal">
        <Grid.Item xs={3}>
          <ReturnInformation />
        </Grid.Item>
        <Grid.Item xs={9}>
          <ReturnRateGraph
            cardId={cardId}
            view={view}
            handleViewClick={handleViewClick}
          />
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}

export default ReturnRateModal
