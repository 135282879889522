import { FC } from 'react'
import { format } from 'date-fns'
import { Button, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  ErrorIcon,
  InfoIcon,
  GearIcon,
} from '@enterprise-ui/icons'

import { StyledUnreadNotification } from './styles'
import { datePattern } from 'v2/constant/date'

const NotificationsContent: FC = () => {
  return (
    <Grid.Container>
      <Grid.Item xs={12}>
        <Grid.Container justify="space-between">
          <Grid.Item>
            <Heading size={4}>Notifications</Heading>
          </Grid.Item>
          <Grid.Item>
            <EnterpriseIcon icon={GearIcon} />
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={12}>
        <Grid.Container className="hc-pr-normal">
          <Grid.Item xs={1}>
            <StyledUnreadNotification />
          </Grid.Item>
          <Grid.Item className="hc-pt-none" xs={10}>
            <div>10 Unshipped past due date orders</div>
            <div className="hc-clr-grey02">
              {format(new Date(), datePattern.slash.MM_dd_yyyy_time)}
            </div>
          </Grid.Item>
          <Grid.Item xs={1}>
            <EnterpriseIcon className="hc-clr-error " icon={ErrorIcon} />
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={12}>
        <Grid.Container className="hc-pr-normal">
          <Grid.Item className="hc-pt-none" xs={11}>
            <div>Order ID: 8930298 Cancelled</div>
            <div className="hc-clr-grey02">
              {format(new Date(), datePattern.slash.MM_dd_yyyy_time)}
            </div>
          </Grid.Item>
          <Grid.Item xs={1}>
            <EnterpriseIcon icon={InfoIcon} />
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item className="hc-pt-none" xs={12}>
        <Button className="hc-fs-md" type="ghost">
          <strong>Mark all as read</strong>
        </Button>
      </Grid.Item>
    </Grid.Container>
  )
}

export default NotificationsContent
