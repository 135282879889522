import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { format, isBefore } from 'date-fns'
import { Grid, Placeholder, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { CautionFilledIcon } from '@enterprise-ui/icons'

import { RoutePath } from 'services/NavigationHelper'
import { currentSellerId } from 'store/selectors'
import { getDateSubtractedByDays } from 'v2/utils/date'
import { OrderStatus, orderStatusLabels } from 'v2/constant'
import { useOrdersDashboardStore } from 'v2/store'

import StatusChip from './StatusChip'
import { datePattern } from 'v2/constant/date'
import { getOrdersSearch } from 'v2/services/getOrdersSearch'
import { chipMap, StyledTable } from '../styles'

import EnterpriseIcon from '@enterprise-ui/icons'
import CustomPagination, {
  PaginationData,
} from 'v2/components/common/CustomPagination'
import { getSellerOrders } from 'v2/services/getSellerOrders'
import { OrderDetailsSortMethod } from 'v2/store/useOrdersDashboardStore'

import { FireflyEvent } from 'types/FireflyInsights'
import { trackCustomEvent } from 'services/fireflyInsights'

const LOADING_OBJECT = {
  cellDisplay: <Placeholder.Rect emphasized height="32px" />,
}

const LOADING_ROW = {
  id: LOADING_OBJECT,
  order_date: LOADING_OBJECT,
  requested_shipment_date: LOADING_OBJECT,
  requested_delivery_date: LOADING_OBJECT,
  order_status: LOADING_OBJECT,
  partner: LOADING_OBJECT,
}

const initialPagination = {
  total: 0,
  page: 1,
  perPage: 10,
}

const OrderDetailsTable = () => {
  const sellerId = useSelector(currentSellerId)

  const [pagination, setPagination] =
    useState<PaginationData>(initialPagination)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [rowData, setRowData] = useState<any[]>([])
  const [toggleFetch, setToggleFetch] = useState<boolean>(false)

  const {
    view,
    sort,
    selectedSellerId,
    orderStatuses,
    totalOrderCount,
    orderPlacedDateInterval,
    requestShipDateInterval,
    deliverByDateInterval,
    updateTotalOrderCount,
    updateSort,
  } = useOrdersDashboardStore()

  useEffect(() => {
    setCurrentPage(1)
    setToggleFetch((prev) => !prev)
  }, [
    orderPlacedDateInterval,
    requestShipDateInterval,
    deliverByDateInterval,
    selectedSellerId,
    orderStatuses,
    sort,
  ])

  useEffect(() => {
    setToggleFetch((prev) => !prev)
  }, [currentPage])

  const getData = async () => {
    let response
    let payload = {
      orderStatus: [...orderStatuses.map((ele) => ele.value)],
      orderDate: orderPlacedDateInterval,
      requestedDeliveryDate: deliverByDateInterval,
      requestedShipmentDate: requestShipDateInterval,
      page: currentPage,
      perPage: pagination.perPage,
      ...(sort && { sort }),
      ...(selectedSellerId && { sellerId: selectedSellerId }),
    }

    // if view is external
    if (view === 'EXTERNAL') {
      response = await getSellerOrders(payload, sellerId!)
    } else {
      response = await getOrdersSearch(payload)
    }
    return response
  }
  const { data, isFetching, isError } = useQuery({
    queryKey: ['ORDER_DETAILS', toggleFetch],
    queryFn: getData,
  })

  useEffect(() => {
    if (isFetching) {
      setRowData(Array(10).fill(LOADING_ROW))
      return
    }

    if (!data) return

    // set total count
    updateTotalOrderCount(Number(data?.total))

    // set pagination data
    setPagination((val) => ({
      ...val,
      total: Number(data?.total),
    }))

    const today = new Date()
    const mappedData = data?.data?.map((item) => {
      const pastSlaDate = getDateSubtractedByDays(today, 8)
      const isWarningIconVisible =
        (item.order_status === OrderStatus.ACKNOWLEDGED_BY_SELLER ||
          item.order_status === OrderStatus.PARTIALLY_SHIPPED) &&
        isBefore(new Date(item.requested_shipment_date), pastSlaDate)

      return {
        ...item,
        id: {
          cellDisplay: (
            <Link
              to={{
                pathname: `/${item?.seller_id}/orders/${item?.id}`,
              }}
              state={{
                previousPage: sellerId
                  ? `/${item?.seller_id}${RoutePath.ORDERS}`
                  : RoutePath.ALL_ORDERS,
              }}
              onClick={() =>
                logFireflyEvent(
                  FireflyEvent.ORDERS_DASHBOARD_ORDER_ID_CLICK,
                  'order-id-click',
                  item?.id,
                )
              }
            >
              {item?.id}
            </Link>
          ),
        },
        order_date: format(
          new Date(item?.order_date),
          datePattern.verbose.MMM_do_yyyy_time,
        ),
        requested_shipment_date: format(
          new Date(item?.requested_shipment_date),
          datePattern.verbose.MMM_do_yyyy_time,
        ),
        requested_delivery_date: format(
          new Date(item?.requested_delivery_date),
          datePattern.verbose.MMM_do_yyyy,
        ),
        order_status: {
          cellDisplay: (
            <>
              <StatusChip
                backgroundColor={chipMap[item.order_status]?.backgroundColor}
                textColor={chipMap[item.order_status]?.textColor}
                label={
                  orderStatusLabels[
                    item.order_status as keyof typeof OrderStatus
                  ]
                }
              />
              {isWarningIconVisible && (
                <Tooltip
                  location={'top'}
                  content="This order has crossed the SLA for 8 or more days"
                >
                  <EnterpriseIcon
                    className="hc-clr-error"
                    icon={CautionFilledIcon}
                  />
                </Tooltip>
              )}
            </>
          ),
        },
        partner: item.seller_name,
      }
    })
    setRowData(mappedData ?? [])
  }, [data, sellerId, isFetching, totalOrderCount, updateTotalOrderCount])

  const columnDefs = useMemo(() => {
    const onSort = (
      e: 'asc' | 'dsc' | null,
      asc_key: OrderDetailsSortMethod,
      dsc_key: OrderDetailsSortMethod,
    ) => {
      switch (e) {
        case 'asc': {
          if (sort !== asc_key) updateSort(asc_key)
          break
        }
        case 'dsc': {
          if (sort !== dsc_key) updateSort(dsc_key)
          break
        }
        case null:
        default: {
          if (sort !== null) updateSort(null)
          break
        }
      }
    }

    return [
      {
        field: 'id',
        headerName: 'Order ID',
        sortable: true,
        onSort: (e: any) =>
          onSort(
            e,
            OrderDetailsSortMethod.ORDER_ID_ASC,
            OrderDetailsSortMethod.ORDER_ID_DSC,
          ),
      },
      view === 'INTERNAL' ? { field: 'partner', headerName: 'Partner' } : null,
      {
        field: 'order_date',
        headerName: 'Order Placed On',
        sortable: true,
        onSort: (e: any) =>
          onSort(
            e,
            OrderDetailsSortMethod.ORDER_DATE_ASC,
            OrderDetailsSortMethod.ORDER_DATE_DSC,
          ),
      },
      {
        field: 'requested_shipment_date',
        headerName: 'Request Ship Date (System Time)',
        sortable: true,
        onSort: (e: any) =>
          onSort(
            e,
            OrderDetailsSortMethod.RSD_ASC,
            OrderDetailsSortMethod.RSD_DSC,
          ),
        defaultSort: 'dsc',
      },
      {
        field: 'requested_delivery_date',
        headerName: 'Deliver By',
        sortable: true,
        onSort: (e: any) =>
          onSort(
            e,
            OrderDetailsSortMethod.RDD_ASC,
            OrderDetailsSortMethod.RDD_DSC,
          ),
      },
      {
        field: 'order_status',
        headerName: 'Status',
        sortable: true,
        onSort: (e: any) =>
          onSort(
            e,
            OrderDetailsSortMethod.ORDER_STATUS_ASC,
            OrderDetailsSortMethod.ORDER_STATUS_DSC,
          ),
      },
    ].filter(Boolean)
  }, [sort, view, updateSort])

  const logFireflyEvent = (
    event: FireflyEvent,
    key?: string,
    value?: string,
  ) => {
    trackCustomEvent(event, key ?? event.toString(), value ?? '')
  }
  if (isError) {
    return (
      <div className="hc-mv-expanded">
        <span className="hc-clr-error ">
          <EnterpriseIcon icon={CautionFilledIcon} />
        </span>
        <span className="hc-pa-xs hc-ta-left hc-clr-error ">
          There was an error fetching the data.
        </span>
      </div>
    )
  }

  return (
    <Grid.Container justify="flex-end">
      {/* Table */}
      <Grid.Item xs={12}>
        <StyledTable
          className="hc-fs-sm"
          cellPadding="dense"
          id="order-table"
          alternateRowColor
          height={585}
          fixedHeader
          data={{
            columnDefs,
            rowData: rowData,
          }}
        />
      </Grid.Item>

      {/* Pagination */}
      <Grid.Item className="hc-pt-none">
        <CustomPagination
          showDescription
          total={pagination.total}
          perPage={pagination.perPage}
          currentPage={currentPage}
          onPageChange={(newPageNumber) => setCurrentPage(newPageNumber)}
        />
      </Grid.Item>
    </Grid.Container>
  )
}

export default OrderDetailsTable
